import React, { useState, useEffect } from "react";
import {
    ACTION,
    ADD,
    ADDRESS,
    ADD_DOCTOR,
    BACK,
    BIRTH_DATE,
    CHOOSE_DOCTOR_TYPE,
    CITY,
    CONFIRM_PASSWORD,
    DOCTOR_INFORMATION,
    DOCTOR_NAME,
    DOCTOR_SPECIALITY,
    DOCTOR_STATUS,
    EMAIL,
    FEE,
    FEMALE,
    FINISH,
    FIRST_NAME,
    GENDER,
    GENERAL_INFORMATION,
    HOME,
    LAST_NAME,
    MALE,
    NEXT,
    NPI1,
    NPI2,
    OFFICE_INFORMATION,
    OFFICE_NAME,
    OFFICE_STATUS,
    OR,
    PASSWORD,
    PHONE_NO,
    ROLES,
    SSN,
    STATE,
    SUBMIT,
    TAXID,
    ZIP,
    PHONE_LENGTH,
    SELECT_GENDER,
    SELECT_SPECIALITY,
    SELECT_OFFICE,
    SELECT,
    SELECT_PAYER,
    DEFAULT_PAYER,
    LICENSE_NUMBER,
    PTAN_NUMBER,
} from "../../Helper/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
    ADMIN_DOCTOR_REGISTER,
    CITY_LIST_WITHOUT_PAGINATION,
    DOCTOR_CREATE_ADD,
    DOCTOR_OFFICE_ADD,
    DOCTOR_SELECT_ADD,
    FINAL_STEP,
    OFFICE_ANOTHER_LIST,
    OFFICE_TABLE_LIST,
    PRACTICE_LIST_API,
    PRACTICE_SHOW_LIST,
    STATE_LIST_WITHOUT_PAGINATION,
    REGISTER_OFFICE_DELETE,
    REGISTER_DOCTOR_DELETE,
    GET_URL,
    PAYER_LIST_WITHOUT_MIDDLEWARE_API,
} from "../../Helper/ApiPath";

import { NavLink, useNavigate } from "react-router-dom";
import { baseURL } from "../../Config";
import { Button } from "reactstrap";
import {
    AGE_MORE_THAN_18,
    AT_LEAST_ONE_OFFICE,
    CHOOSE_OFFICE,
    DELETE_DOCTOR_MESSAGE,
    DELETE_OFFICE_MESSAGE,
    ENTER_ADDRESS,
    ENTER_CITY,
    ENTER_CONFIRMPASSWORD,
    ENTER_DOCTOR_DETAIL,
    ENTER_EMAIL,
    ENTER_FEE_LESS_THAN_10,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI1,
    ENTER_NPI_LESS_THAN_10,
    ENTER_OFFICE_NAME,
    ENTER_PASSWORD,
    ENTER_PHONE,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALITY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_STATE,
    ENTER_TAX_ID_LESS_THAN_10,
    ENTER_ZIP,
    MAX_LENGTH,
    PASSWORD_DOES_NOT_MATCH,
    PASSWORD_ERROR_TEXT,
    PHONE_NUMBER,
} from "../../Helper/Messages";
import { dateAPIFormate, minYear } from "../../Helper/Helper";
import Swal from "sweetalert2";
import Select from "react-select";
import NoDataFound from "../CommonComponent/NoDataFound";
import Cookies from "universal-cookie";

const One = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [payer, setPayer] = useState([]);

    const [city, setCity] = useState({
        officestate: "",
        officecity: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, officecity: e.value });
    };

    const navigate = useNavigate();
    const [error, setError] = useState("");

    const MaxOffice = 30;

    const [emailErr, setEmailErr] = useState(false);
    const [pwdError1, setPwdError1] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const LocalStep = localStorage.getItem("DoctorRegisterStep");
    const adminToken = JSON.parse(localStorage.getItem("DoctorToken"));
    let doctorToken = JSON.parse(sessionStorage.getItem("Token"));

    const rn = (Number(LocalStep) + 1).toString();
    const [step, setStep] = useState(rn);

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [info1, setInfo1] = useState({
        email: "",
        password: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        profilePic: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        fee: "",
        gender: "",
        speciality: "",
        ptanNumber: "",
        payer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });

    const onInputChanged = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setInfo1({ ...info1, [name]: onlyNumber });
        } else {
            setInfo1({ ...info1, [name]: value });
        }
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setInfo1({ ...info1, payerLabel: label, payer: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const [userId, setUserId] = useState("");
    const fetchUserId = async () => {
        axios
            .get(`${baseURL}/${GET_URL}`, {
                headers: { authorization: adminToken },
            })
            .then((res) => {
                setUserId(res.data.data.create_by);
            });
    };
    const validate1 = async () => {
        if (!info1.email) {
            toast.error(ENTER_EMAIL);
        } else if (!info1.password) {
            toast.error(ENTER_PASSWORD);
        } else if (!info1.confirmpassword) {
            toast.error(ENTER_CONFIRMPASSWORD);
        } else if (!info1.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!info1.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!info1.npi1) {
            toast.error(ENTER_NPI1);
        } else if (info1.npi1 && info1.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (info1.npi2 && info1.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (info1.ssn && info1.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (info1.taxId && info1.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (info1.ptanNumber && info1.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (info1.fee && info1.fee.length > MAX_LENGTH) {
            toast.error(ENTER_FEE_LESS_THAN_10);
        } else if (!info1.speciality) {
            toast.error(ENTER_SPECIALITY);
        } else {
            setError("");
            const validEmail = new RegExp("^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$");
            const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

            if (!validEmail.test(info1.email)) {
                setEmailErr(true);
            } else if (!validPassword.test(info1.password)) {
                setPwdError1(true);
            } else if (info1.phoneNo1 && info1.phoneNo1.length > PHONE_LENGTH) {
                toast.error(PHONE_NUMBER);
            } else if (info1.password === info1.confirmpassword) {
                setIsLoading(true);

                await axios
                    .post(`${baseURL}/${ADMIN_DOCTOR_REGISTER}`, {
                        headers: { authorization: adminToken },
                        email: info1.email,
                        password: info1.password,
                        firstName: info1.firstName,
                        middleName: info1.middleName,
                        lastName: info1.lastName,
                        phoneNo1: info1.phoneNo1,
                        dob: dateAPIFormate(info1.dob),
                        profilePic: "",
                        npi1: info1.npi1,
                        npi2: info1.npi2,
                        ssn: info1.ssn,
                        taxId: info1.taxId,
                        licenseNumber: info1.licenseNumber,
                        fee: info1.fee,
                        gender: info1.gender,
                        speciality: info1.speciality,
                        ptanNumber: info1.ptanNumber,
                        statusId: DOCTOR_STATUS.Active,
                        payerId: info1.payer,
                    })
                    .then((res) => {
                        setIsLoading(false);

                        if (res.status === 200) {
                            setInfo1("");
                            toast.success(res.data.message);
                            const token = res.data.data.token;
                            const step = res.data.data.step;
                            doctorToken = token;
                            sessionStorage.setItem("Token", JSON.stringify(token));
                            localStorage.setItem("DoctorRegisterStep", JSON.stringify(step));
                            setStep("2");
                            setPracticeShowList("");
                            officeListSel();
                            fetchData();
                            fetchUserId();
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            } else {
                toast.error(PASSWORD_DOES_NOT_MATCH);
            }
        }
    };

    const backClick = async () => {
        fetchData();
        practiceOfficeApi();
        officeListSel();
        setStep("2");
    };

    const [allOfficeList, setAllOfficeList] = useState([]);

    const officeListSel = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_ANOTHER_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setAllOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        allOfficeList &&
        allOfficeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const [info2, setInfo2] = useState({
        officename: "",
        officephoneNo1: "",
        officeaddress: "",
        officezip: "",
    });

    const onInputChanged2 = (e) => {
        const { name, value } = e.target;
        setInfo2({ ...info2, [name]: value });
    };

    const validate2 = async () => {
        if (!info2.officename) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!info2.officephoneNo1) {
            toast.error(ENTER_PHONE);
        } else if (!info2.officeaddress) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.officestate) {
            toast.error(ENTER_STATE);
        } else if (!city.officecity) {
            toast.error(ENTER_CITY);
        } else if (!info2.officezip) {
            toast.error(ENTER_ZIP);
        } else if (info2.officephoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${DOCTOR_OFFICE_ADD}`, {
                    headers: { authorization: doctorToken },
                    name: info2.officename,
                    phoneNo1: info2.officephoneNo1,
                    address: info2.officeaddress,
                    state: city.officestate,
                    city: city.officecity,
                    zip: info2.officezip,
                    statusId: OFFICE_STATUS.Active,
                    create_by: userId,
                    step: true,
                })
                .then((res) => {
                    setIsLoading(false);

                    if (res.data.success == true) {
                        toast.success(res.data.message);
                        setAllOfficeList("");
                        setInfo2("");
                        setCity("");
                        setCityList("");
                        officeListSel();
                        fetchData();
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const validate3 = async () => {
        if (officeList == "" || officeList == null || officeList == undefined) {
            toast.error(AT_LEAST_ONE_OFFICE);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${FINAL_STEP}`, {
                    headers: { authorization: doctorToken },
                    step: 2,
                })
                .then((res) => {
                    setIsLoading(false);

                    fetchData();
                    setStep("3");
                    localStorage.setItem("DoctorRegisterStep", 2);
                    practiceOfficeApi();
                    practiceListApi();
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [officeId, setOfficeId] = useState("");
    const onOfficeChanged2 = async (e) => {
        setOfficeId(e.value);
        if (e.value == 0) {
            setOfficeId("");
            document.getElementById("officeRow").style.display = "block";
        } else {
            document.getElementById("officeRow").style.display = "none";
        }
    };
    const addOffice = async (e) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/${DOCTOR_OFFICE_ADD}`, {
                headers: { authorization: doctorToken },
                officeId: officeId,
                statusId: OFFICE_STATUS.Active,
                create_by: userId,
                step: true,
            })
            .then((res) => {
                setIsLoading(false);

                if (res.status == 200) {
                    setAllOfficeList("");
                    setOfficeId("");
                    fetchData();
                    officeListSel();
                    document.getElementById("officeRow").style.display = "block";
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const btnBackOfficeStep = () => {
        setOfficeId(0);
        document.getElementById("officeRow").style.display = "block";
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [conPasswordShown, setConPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConPassword = () => {
        setConPasswordShown(!conPasswordShown);
    };

    ////////////////////////////////////////////////////////////// doctor  information ////////////////////

    const [practiceOffice, setPracticeOffice] = useState([]);
    const [officeLi, setOfficeLi] = useState("");
    const [doctorLi, setDoctorLi] = useState("");
    const [practiceShowList, setPracticeShowList] = useState([]);
    const [practiceList, setPracticeList] = useState([]);
    const [practiceTableList, setPracticeTableList] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        fee: "",
        gender: "",
        speciality: "",
        ptanNumber: "",
        officeId: "",
        doctorPayer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });

    const doctorPayerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const tableList = async () => {
        await axios
            .post(`${baseURL}/${PRACTICE_SHOW_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeShowList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const onOfficeChanged3 = (e) => {
        setOfficeLi(e.value);
    };
    const onDoctorChanged4 = async (e) => {
        setDoctorLi(e.value);
    };

    const onParacticeChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setPracticeTableList({ ...practiceTableList, [name]: onlyNumber });
        } else {
            setPracticeTableList({ ...practiceTableList, [name]: value });
        }
    };

    const handleDoctorAdd = async (e) => {
        if (officeLi == 0 || officeLi == null || officeLi == "0" || officeLi == "") {
            toast.error(CHOOSE_OFFICE);
            return;
        }

        const dob = practiceTableList.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }

        if (!doctorLi && !practiceTableList.firstName && !practiceTableList.lastName && !practiceTableList.phoneNo1 && !practiceTableList.npi1 && !practiceTableList.speciality) {
            toast.error(ENTER_DOCTOR_DETAIL);
        } else if (doctorLi == 0 || doctorLi == null || doctorLi == "0") {
            if (!practiceTableList.firstName) {
                toast.error(ENTER_FNAME);
            } else if (!practiceTableList.lastName) {
                toast.error(ENTER_LNAME);
            } else if (!practiceTableList.npi1) {
                toast.error(ENTER_NPI1);
            } else if (practiceTableList.npi1 && practiceTableList.npi1.length > MAX_LENGTH) {
                toast.error(ENTER_NPI_LESS_THAN_10);
            } else if (practiceTableList.npi2 && practiceTableList.npi2.length > MAX_LENGTH) {
                toast.error(ENTER_NPI_LESS_THAN_10);
            } else if (practiceTableList.ssn && practiceTableList.ssn.length > MAX_LENGTH) {
                toast.error(ENTER_SSN_LESS_THAN_10);
            } else if (practiceTableList.taxId && practiceTableList.taxId.length > MAX_LENGTH) {
                toast.error(ENTER_TAX_ID_LESS_THAN_10);
            } else if (practiceTableList.ptanNumber && practiceTableList.ptanNumber.length > MAX_LENGTH) {
                toast.error(ENTER_PTAN_LESS_THAN_10);
            } else if (practiceTableList.fee && practiceTableList.fee.length > MAX_LENGTH) {
                toast.error(ENTER_FEE_LESS_THAN_10);
            } else if (!practiceTableList.speciality) {
                toast.error(ENTER_SPECIALITY);
            } else if (practiceTableList.dob && age_now < 18) {
                toast.error(AGE_MORE_THAN_18);
            } else if (practiceTableList && practiceTableList.phoneNo1.length > PHONE_LENGTH) {
                toast.error(PHONE_NUMBER);
            } else {
                setIsLoading(true);
                await axios
                    .post(`${baseURL}/${DOCTOR_CREATE_ADD}`, {
                        headers: { authorization: doctorToken },
                        firstName: practiceTableList.firstName,
                        middleName: practiceTableList.middleName,
                        lastName: practiceTableList.lastName,
                        phoneNo1: practiceTableList.phoneNo1,
                        dob: dateAPIFormate(practiceTableList.dob),
                        profilePic: "",
                        npi1: practiceTableList.npi1,
                        npi2: practiceTableList.npi2,
                        ssn: practiceTableList.ssn,
                        taxId: practiceTableList.taxId,
                        licenseNumber: practiceTableList.licenseNumber,
                        fee: practiceTableList.fee,
                        gender: practiceTableList.gender,
                        speciality: practiceTableList.speciality,
                        ptanNumber: practiceTableList.ptanNumber,
                        officeId: officeLi,
                        statusId: DOCTOR_STATUS.Active,
                        create_by: userId,
                        payerId: practiceTableList.doctorPayer,
                    })
                    .then((res) => {
                        setIsLoading(false);

                        if (res.data.success == true) {
                            toast.success(res.data.message);
                            setPracticeTableList("");
                            tableList();
                            setOfficeLi("");
                            setPracticeOffice("");
                            setPracticeList("");
                            practiceOfficeApi();
                            practiceListApi();
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${DOCTOR_SELECT_ADD}`, {
                    headers: { authorization: doctorToken },
                    officeId: officeLi,
                    doctorId: doctorLi,
                    create_by: userId,
                    statusId: DOCTOR_STATUS.Active,
                })
                .then((res) => {
                    setIsLoading(false);
                    if (res.data.success == true) {
                        toast.success(res.data.message);
                        tableList();
                        setOfficeLi("");
                        setDoctorLi("");
                        setPracticeOffice("");
                        setPracticeList("");
                        practiceOfficeApi();
                        practiceListApi();
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    let paracticeSelect = [
        {
            id: "",
            firstName: SELECT,
            lastName: "",
        },
    ];

    const practiceListApi = async () => {
        axios
            .post(`${baseURL}/${PRACTICE_LIST_API}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeList(res.data.data ? paracticeSelect.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const practiceListOptions =
        practiceList &&
        practiceList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
            };
        });

    const handleDoctorPayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setPracticeTableList({ ...practiceTableList, payerLabel: label, doctorPayer: value });
    };
    const practiceOfficeApi = async () => {
        axios
            .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeOffice(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const practiceOfficeOptions =
        practiceOffice &&
        practiceOffice.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const finalRegistersubmit = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/${FINAL_STEP}`, {
                headers: { authorization: doctorToken },
                step: 3,
            })
            .then((res) => {
                setIsLoading(false);
                localStorage.setItem("DoctorRegisterStep", 0);
                sessionStorage.removeItem("Token");
                navigate("/manageDoctor");
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    useEffect(() => {
        if (rn == 2) {
            practiceOfficeApi();
            if (role == ROLES.DOCTOR) {
                fetchData();
                practiceOfficeApi();
                officeListSel();
            }
            fetchData();
            officeListSel();
        } else if (rn == 3) {
            practiceOfficeApi();

            if (role == ROLES.DOCTOR) {
                fetchData();
                practiceOfficeApi();
                officeListSel();
            }
            fetchData();
            tableList();
            practiceListApi();
        }
        fetchUserId();
        payerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState("");

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);

                axios
                    .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                        stateId: stateId,
                        search: "",
                    })
                    .then((res) => {
                        setCityList(res.data.data);
                    });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleStateChange = (e) => {
        setCity({ ...city, officestate: e.value, officecity: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const officestateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const officecityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    const Officedelete = async (officeid) => {
        Swal.fire({
            title: DELETE_OFFICE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${REGISTER_OFFICE_DELETE}`, {
                            headers: { authorization: adminToken },
                            officedoctorid: officeid,
                        })
                        .then((res) => {
                            if (res.status == 200) {
                                Swal.fire(res.data.message, " ", "success");
                                setAllOfficeList("");
                                setOfficeId("");
                                fetchData();
                                officeListSel();
                                document.getElementById("officeRow").style.display = "block";
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const Doctordelete = async (officedoctorid) => {
        Swal.fire({
            title: DELETE_DOCTOR_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${REGISTER_DOCTOR_DELETE}`, {
                            headers: { authorization: adminToken },
                            officedoctorid: officedoctorid,
                        })
                        .then((res) => {
                            if (res.status == 200) {
                                Swal.fire(res.data.message, " ", "success");
                                tableList();
                                setOfficeLi("");
                                setDoctorLi("");
                                setPracticeOffice("");
                                setPracticeList("");
                                practiceOfficeApi();
                                practiceListApi();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    return (
        <div className="page-content-wrapper">
            <div className="page-content">
                <div className="page-bar">
                    <div className="page-title-breadcrumb">
                        <div className=" pull-left">
                            <div className="page-title">{ADD_DOCTOR}</div>
                        </div>
                        <ol className="breadcrumb page-breadcrumb pull-right">
                            <li>
                                <i className="fa fa-home"></i>&nbsp;
                                <NavLink className="parent-item" to="/dashboard">
                                    {HOME}
                                </NavLink>
                                &nbsp;<i className="fa fa-angle-right"></i>
                            </li>
                            <li className="active">{ADD_DOCTOR}</li>
                        </ol>
                    </div>
                </div>

                <div>
                    {step === "1" && (
                        <>
                            {isLoading ? (
                                <div className="row">
                                    <div className="offset-5 col-1">
                                        <i
                                            className="fa fa-spinner fa-spin fa-2x"
                                            style={{
                                                position: "relative",
                                                top: "10rem",
                                                fontSize: "55px",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card card-box">
                                            <div className="card-head">
                                                <header>{GENERAL_INFORMATION}</header>
                                            </div>
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {EMAIL}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <div className="input-group">
                                                                    <input
                                                                        type="email"
                                                                        className="form-control input-height"
                                                                        name="email"
                                                                        value={info1 && info1.email}
                                                                        onChange={onInputChanged}
                                                                        placeholder="Email Email Address"
                                                                    />{" "}
                                                                </div>
                                                                {emailErr && <p className="txtError">Your Email is like example@gmail.com</p>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PASSWORD}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <div style={{ display: "flex" }}>
                                                                    <input
                                                                        type={passwordShown ? "text" : "password"}
                                                                        className="form-control input-height"
                                                                        id="exampleInputPassword1"
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        onChange={onInputChanged}
                                                                    />
                                                                    <i
                                                                        className={passwordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                        id="basic-addon1"
                                                                        onClick={togglePassword}
                                                                    ></i>
                                                                </div>
                                                                <p>{PASSWORD_ERROR_TEXT}</p>
                                                                {pwdError1 && <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {CONFIRM_PASSWORD}
                                                                <span className="required"> * </span>
                                                            </label>

                                                            <div style={{ display: "flex" }} className="col-md-5">
                                                                <input
                                                                    type={conPasswordShown ? "text" : "password"}
                                                                    className="form-control input-height"
                                                                    id="exampleInputPassword2"
                                                                    name="confirmpassword"
                                                                    placeholder="Confirm Password"
                                                                    onChange={onInputChanged}
                                                                />
                                                                <i
                                                                    className={conPasswordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                    id="basic-addon1"
                                                                    onClick={toggleConPassword}
                                                                ></i>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {FIRST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={info1 && info1.firstName}
                                                                    onChange={onInputChanged}
                                                                    data-required="1"
                                                                    placeholder="Enter first name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {LAST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={info1 && info1.lastName}
                                                                    onChange={onInputChanged}
                                                                    data-required="1"
                                                                    placeholder="Enter last name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{GENDER}</label>
                                                            <div className="col-md-5">
                                                                <select className="form-control input-height" name="gender" value={info1 && info1.gender} onChange={onInputChanged}>
                                                                    <option value=""> {SELECT_GENDER}</option>
                                                                    <option value="male">{MALE}</option>
                                                                    <option value="female">{FEMALE}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                            <div className="form-group col-5">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={info1.dob}
                                                                    maxDate={new Date(minYear)}
                                                                    placeholderText={"Please Select Date"}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    onChange={(date) => setInfo1({ ...info1, dob: date })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{PHONE_NO}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="phoneNo1"
                                                                    type="number"
                                                                    value={info1 && info1.phoneNo1}
                                                                    minLength={10}
                                                                    maxLength={10}
                                                                    onChange={onInputChanged}
                                                                    placeholder="mobile number"
                                                                    className="form-control input-height"
                                                                />{" "}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {NPI1}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="npi1"
                                                                    type="text"
                                                                    onChange={onInputChanged}
                                                                    value={info1 && info1.npi1}
                                                                    placeholder="Enter NPI 1"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{NPI2}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-height"
                                                                    name="npi2"
                                                                    value={info1 && info1.npi2}
                                                                    placeholder="Enter NPI 2"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{SSN}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-height"
                                                                    name="ssn"
                                                                    value={info1 && info1.ssn}
                                                                    placeholder="Enter SSN"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{TAXID}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-height"
                                                                    name="taxId"
                                                                    value={info1 && info1.taxId}
                                                                    placeholder="Enter Tax ID"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-height"
                                                                    name="licenseNumber"
                                                                    value={info1 && info1.licenseNumber}
                                                                    placeholder="Enter License Number"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-height"
                                                                    name="ptanNumber"
                                                                    value={info1 && info1.ptanNumber}
                                                                    placeholder="Enter PTAN Number"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{FEE}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="number"
                                                                    className="form-control input-height"
                                                                    name="fee"
                                                                    value={info1 && info1.fee}
                                                                    placeholder="Enter fee"
                                                                    onChange={onInputChanged}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {DOCTOR_SPECIALITY}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <select className="form-control input-height" name="speciality" value={info1 && info1.speciality} onChange={onInputChanged}>
                                                                    <option value=""> {SELECT_SPECIALITY}</option>
                                                                    {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {DEFAULT_PAYER}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    className="text-dark"
                                                                    name="payer"
                                                                    defaultValue={{ label: "CA Medicare South" }}
                                                                    options={payerOptions}
                                                                    onChange={handlePayerChange}
                                                                    placeholder={SELECT_PAYER}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div>
                                                                <div style={{ float: "right" }}>
                                                                    <Button className="btn btn-success btn-lg " onClick={validate1}>
                                                                        {SUBMIT}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {step === "2" && (
                        <>
                            {isLoading ? (
                                <div className="row">
                                    <div className="offset-5 col-1">
                                        <i
                                            className="fa fa-spinner fa-spin fa-2x"
                                            style={{
                                                position: "relative",
                                                top: "10rem",
                                                fontSize: "55px",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card card-box">
                                            <div className="card-head">
                                                <header>{OFFICE_INFORMATION}</header>
                                            </div>
                                            {officeList && officeList.length >= MaxOffice ? (
                                                <>
                                                    <div id="officeRow">
                                                        <div className="row">
                                                            <div className="card-body" id="bar-parent">
                                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                                    <div className="form-body showHeader">You can add only {MaxOffice} offices. So you can go to next step.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Button className="btn btn-success btn-lg " onClick={validate3}>
                                                                    Next
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="card-body" id="bar-parent">
                                                        <div action="#" id="form_sample_1" className="form-horizontal">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-3">
                                                                    Select Office
                                                                    <span className="required"> * </span>
                                                                </label>
                                                                <div className="col-md-5">
                                                                    <Select
                                                                        name="officeSelect"
                                                                        value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeId)}
                                                                        options={officeSelectOptions}
                                                                        onChange={onOfficeChanged2}
                                                                        placeholder={SELECT_OFFICE}
                                                                    ></Select>

                                                                    {officeId == 0 ? (
                                                                        ""
                                                                    ) : (
                                                                        <>
                                                                            <div className="form-group mt-5">
                                                                                <div style={{ textAlign: "center" }}>
                                                                                    <Button
                                                                                        className="btn btn-info btn-lg m-r-1"
                                                                                        style={{
                                                                                            marginRight: "15px",
                                                                                        }}
                                                                                        onClick={addOffice}
                                                                                    >
                                                                                        {ADD}
                                                                                    </Button>

                                                                                    <Button className="btn btn-lg mx-2" onClick={btnBackOfficeStep}>
                                                                                        {BACK}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*  */}
                                                    <div id="officeRow">
                                                        <div className="card-body" id="bar-parent">
                                                            <div action="#" id="form_sample_1" className="form-horizontal">
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3"></label>
                                                                    <div className="col-md-5">
                                                                        <div className="text-center">{OR}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div id="form_sample_1" className="form-horizontal">
                                                                <div className="form-body">
                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {OFFICE_NAME}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                type="text"
                                                                                name="officename"
                                                                                placeholder="Enter Office Name"
                                                                                onChange={onInputChanged2}
                                                                                value={info2 && info2.officename}
                                                                                className="form-control input-height"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {PHONE_NO}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                type="number"
                                                                                name="officephoneNo1"
                                                                                placeholder="Enter Office Phone"
                                                                                onChange={onInputChanged2}
                                                                                value={info2 && info2.officephoneNo1}
                                                                                className="form-control input-height"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {ADDRESS}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                type="text"
                                                                                name="officeaddress"
                                                                                placeholder="Enter Address"
                                                                                onChange={onInputChanged2}
                                                                                value={info2 && info2.officeaddress}
                                                                                className="form-control input-height"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {STATE}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <Select name="officestate" options={officestateOptions} onChange={handleStateChange} placeholder={SELECT} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {CITY}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                value={officecityOptions && officecityOptions.filter(({ value }) => value == city.officecity)}
                                                                                name="officecity"
                                                                                options={officecityOptions}
                                                                                onChange={handleCityChange}
                                                                                placeholder={SELECT}
                                                                            ></Select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            {ZIP}
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                name="officezip"
                                                                                onChange={onInputChanged2}
                                                                                type="number"
                                                                                placeholder="zip"
                                                                                value={info2 && info2.officezip}
                                                                                className="form-control input-height"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3"></label>
                                                                        <div className="col-md-5" style={{ textAlign: "center" }}>
                                                                            <Button className="btn btn-info btn-lg m-r-1" onClick={validate2}>
                                                                                {ADD}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Office List  */}

                                                    <div className="form-group row">
                                                        <label className="control-label col-md-2"></label>
                                                        <div className="col-md-8 p-0">
                                                            <div className="table-scrollable">
                                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{OFFICE_NAME}</th>
                                                                            <th>{ADDRESS}</th>
                                                                            <th>{CITY}</th>
                                                                            <th>{ACTION}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {officeList && officeList.length > 0 ? (
                                                                            officeList.map((item, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{item.name}</td>
                                                                                        <td>{item.address}</td>
                                                                                        <td>{item.city}</td>
                                                                                        <td>
                                                                                            <a className="btn btn-danger btn-xs" onClick={() => Officedelete(item.OfficeDoctorId)}>
                                                                                                <i className="fa fa-trash"></i>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <NoDataFound />
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Button className="btn btn-success btn-lg " onClick={validate3}>
                                                                    {NEXT}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Office List  */}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {step === "3" && (
                        <>
                            {isLoading ? (
                                <div className="row">
                                    <div className="offset-5 col-1">
                                        <i
                                            className="fa fa-spinner fa-spin fa-2x"
                                            style={{
                                                position: "relative",
                                                top: "10rem",
                                                fontSize: "55px",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card card-box">
                                            <div className="card-head">
                                                <header>{DOCTOR_INFORMATION}</header>
                                            </div>
                                            {practiceShowList && practiceShowList.length >= MaxOffice ? (
                                                <>
                                                    <div id="officeRow">
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="card-body" id="bar-parent">
                                                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                                                        <div className="form-body showHeader">You can add only {MaxOffice} practices. So you can go to next step.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Button className="btn btn-success btn-lg" onClick={finalRegistersubmit}>
                                                                    {SUBMIT}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="card-body" id="bar-parent">
                                                            <div action="#" id="form_sample_1" className="form-horizontal">
                                                                <div className="form-body">
                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            Select Office
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                name="officeSelect"
                                                                                value={practiceOfficeOptions && practiceOfficeOptions.filter(({ value }) => value == officeLi)}
                                                                                options={practiceOfficeOptions}
                                                                                onChange={onOfficeChanged3}
                                                                                placeholder={SELECT}
                                                                            ></Select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="control-label col-md-3">
                                                                            Select Doctor
                                                                            <span className="required"> * </span>
                                                                        </label>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                name="officeSelect"
                                                                                value={practiceListOptions && practiceListOptions.filter(({ value }) => value == doctorLi)}
                                                                                options={practiceListOptions}
                                                                                onChange={onDoctorChanged4}
                                                                                placeholder={SELECT}
                                                                            ></Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="control-label col-md-3"></label>
                                                            <div className="col-md-5">
                                                                <div className="text-center">{OR}</div>
                                                            </div>
                                                        </div>

                                                        <div action="#" id="form_sample_1" className="form-horizontal">
                                                            <span style={{ color: "red" }}>{error}</span>
                                                            <div className="form-body">
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">
                                                                        {FIRST_NAME}
                                                                        <span className="required"> * </span>
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            type="text"
                                                                            name="firstName"
                                                                            value={practiceTableList && practiceTableList.firstName}
                                                                            onChange={onParacticeChange}
                                                                            data-required="1"
                                                                            placeholder="Enter first name"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">
                                                                        {LAST_NAME}
                                                                        <span className="required"> * </span>
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            type="text"
                                                                            name="lastName"
                                                                            value={practiceTableList && practiceTableList.lastName}
                                                                            onChange={onParacticeChange}
                                                                            data-required="1"
                                                                            placeholder="Enter last name"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{GENDER}</label>
                                                                    <div className="col-md-5">
                                                                        <select
                                                                            className="form-control input-height"
                                                                            name="gender"
                                                                            value={practiceTableList && practiceTableList.gender}
                                                                            onChange={onParacticeChange}
                                                                        >
                                                                            <option value=""> {SELECT_GENDER}</option>
                                                                            <option value="male">{MALE}</option>
                                                                            <option value="female">{FEMALE}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                                    <div className="form-group col-5">
                                                                        <DatePicker
                                                                            className="form-control input-height mr-sm-2"
                                                                            selected={practiceTableList.dob}
                                                                            maxDate={new Date(minYear)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            placeholderText={"Please select a date"}
                                                                            onChange={(date) => setPracticeTableList({ ...practiceTableList, dob: date })}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{PHONE_NO}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="phoneNo1"
                                                                            type="number"
                                                                            value={practiceTableList && practiceTableList.phoneNo1}
                                                                            minLength={10}
                                                                            maxLength={10}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="mobile number"
                                                                            className="form-control input-height"
                                                                        />{" "}
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">
                                                                        {NPI1}
                                                                        <span className="required"> * </span>
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="npi1"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.npi1}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter NPI 1"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{NPI2}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="npi2"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.npi2}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter NPI 2"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{SSN}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="ssn"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.ssn}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter SSN"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{TAXID}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="taxId"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.taxId}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter TaxId"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="licenseNumber"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.licenseNumber}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter License Number"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{FEE}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="fee"
                                                                            type="number"
                                                                            value={practiceTableList && practiceTableList.fee}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter Fee"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">
                                                                        {DOCTOR_SPECIALITY}
                                                                        <span className="required"> * </span>
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <select
                                                                            className="form-control input-height"
                                                                            name="speciality"
                                                                            value={practiceTableList && practiceTableList.speciality}
                                                                            onChange={onParacticeChange}
                                                                        >
                                                                            <option value=""> {SELECT_SPECIALITY}</option>
                                                                            {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name="ptanNumber"
                                                                            type="text"
                                                                            value={practiceTableList && practiceTableList.ptanNumber}
                                                                            onChange={onParacticeChange}
                                                                            placeholder="Enter PTAN Number"
                                                                            className="form-control input-height"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3">
                                                                        {DEFAULT_PAYER}
                                                                        <span className="required"> * </span>
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <Select
                                                                            menuPlacement="top"
                                                                            className="text-dark"
                                                                            name="doctorPayer"
                                                                            defaultValue={{ label: "CA Medicare South" }}
                                                                            options={doctorPayerOptions}
                                                                            onChange={handleDoctorPayerChange}
                                                                            placeholder={SELECT_PAYER}
                                                                        ></Select>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-3"></label>
                                                                    <div className="col-md-5 d-flex justify-content-center">
                                                                        <Button className="btn btn-info btn-lg m-r-1" style={{ marginRight: "15px" }} onClick={handleDoctorAdd}>
                                                                            {ADD}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Doctor List */}
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-2"></label>
                                                            <div className="col-md-8">
                                                                <div className="table-scrollable">
                                                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{DOCTOR_NAME}</th>
                                                                                <th>{OFFICE_NAME}</th>
                                                                                <th>{ACTION}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {practiceShowList && practiceShowList.length > 0 ? (
                                                                                practiceShowList.map((item, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>
                                                                                                {item.firstName} {item.lastName}
                                                                                            </td>
                                                                                            <td>{item.name}</td>
                                                                                            <td>
                                                                                                <a className="btn btn-danger btn-xs" onClick={() => Doctordelete(item.OfficeDoctorId)}>
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <NoDataFound />
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <Button className="btn btn-lg mx-5" onClick={backClick}>
                                                                        {BACK}
                                                                    </Button>
                                                                    <Button className="btn btn-success btn-lg" onClick={finalRegistersubmit}>
                                                                        {FINISH}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

function AddDoctor() {
    const DoctorFormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <form onSubmit={DoctorFormSubmit}>
                <span className="login100-form-title">ADD_DOCTOR</span>
                <div>
                    <One />
                </div>
            </form>
        </>
    );
}

export default AddDoctor;
