import React, { useEffect, useState } from "react";
import {
    HOME,
    ADD,
    EMAIL,
    DOCTOR,
    PHONE_NO,
    DOCTOR_SPECIALITY,
    CREATED_DATE,
    ACTION,
    STATUS,
    CHOOSE_DOCTOR_TYPE,
    DASH,
    ACTIVE,
    DEACTIVE,
    ROLES,
    IMPERSONATING,
    ROWS_PER_PAGE,
    TRIAL_LIST,
    TRIAL,
    NAME,
    LICENSE_NUMBER,
} from "../../Helper/Constants";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { DELETE_TRIAL_API, OFFICE_PRACTICE_LIST_API, PAGE_DETAIL_API } from "../../Helper/ApiPath";
import { baseURL } from "../../Config";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import { DELETE_TRIAL_MESSAGE } from "../../Helper/Messages";
import Swal from "sweetalert2";

function ManageTrial() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(1);
    let navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    let specialityArray = [];
    var checkboxes = document.getElementsByClassName("trial-speciality");

    for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            specialityArray.push(checkboxes[i].value);
        }
    }

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const [filterValues, setFilterValues] = useState({
        Dentist: "",
        Chiro: "",
        Accu: "",
        status: "",
        officeSearch: "",
        doctorSearch: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        navigate("/addTrial");
    };

    const [trialList, setTrialList] = useState([]);

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_PRACTICE_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortBy: "",
                sortDirection: "",
                specialitySearch: specialityArray,
                status: filterValues.status,
                doctorSearch: filterValues.doctorSearch,
                officeSearch: filterValues.officeSearch,
                isTrial: true,
            })
            .then((res) => {
                setIsLoading(false);
                setTrialList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = async (doctorId, officeId) => {
        Swal.fire({
            title: DELETE_TRIAL_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${DELETE_TRIAL_API}`, {
                            headers: { authorization: token.token },
                            doctorId: doctorId,
                            officeId: officeId,
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                fetchData();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleEdit = (doctorId, officeId) => {
        localStorage.setItem("officeId", officeId);
        localStorage.setItem("doctorId", doctorId);
        navigate("/editTrial");
    };

    const handleImpersonate = async (practiceId, doctorId, officeId, payerId, payerName, payerValue, isTrial) => {
        localStorage.setItem("adminRole", ROLES.DOCTOR);
        localStorage.setItem("officeId", officeId);
        localStorage.setItem("doctorId", doctorId);
        localStorage.setItem("doctorPracticeId", practiceId);
        localStorage.setItem("payerId", payerId);
        localStorage.setItem("payerName", payerName);
        localStorage.setItem("payerValue", payerValue);
        cookies.set("isTrial", { isTrial: isTrial, practiceId: practiceId });
        let pageAccess = [];
        await axios
            .post(`${baseURL}/${PAGE_DETAIL_API}`, {
                headers: { authorization: token.token },
                roleId: ROLES.PRACTICE,
                privateKey: 1,
            })
            .then((res) => {
                res.data.data &&
                    res.data.data.map((item) => {
                        if (item.isAuthority == 1) {
                            pageAccess.push(item.pageId);
                        }
                    });
                cookies.set("doctorPageAccess", pageAccess);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
        navigate("/managePatient");
        window.location.reload();
    };

    useEffect(() => {
        fetchData();
    }, [filterValues, offset]);
    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{TRIAL_LIST}</div>
                                {/* <ToastContainer /> */}
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{TRIAL_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="d-flex justify-content-between mb-4">
                                                                <div className="col-md-10 col-sm-6 col-xs-6 ">
                                                                    <div className="btn-group">
                                                                        <label>{DOCTOR_SPECIALITY}</label>
                                                                    </div>

                                                                    {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                        return (
                                                                            <div className="btn-group mx-4" key={i}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="mt-2 mx-2 trial-speciality"
                                                                                    value={CHOOSE_DOCTOR_TYPE[item]}
                                                                                    onChange={handleFilterValues}
                                                                                ></input>
                                                                                <label>{item}</label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    {role == ROLES.EMPLOYEE ? (
                                                                        <div className="btn-group"></div>
                                                                    ) : (
                                                                        <div className="btn-group">
                                                                            <button id="addRow" className="btn btn-info" onClick={handleAdd}>
                                                                                {ADD}
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    <div>
                                                                        <div className="btn-group mx-1">
                                                                            <label className="mt-2 col-md-3">{NAME}</label>
                                                                            <input
                                                                                className="form-control mr-sm-2"
                                                                                name="doctorSearch"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                value={filterValues.doctorSearch}
                                                                                onChange={handleFilterValues}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{DOCTOR}</th>
                                                                        <th>{EMAIL}</th>
                                                                        <th>{PHONE_NO}</th>
                                                                        <th>{DOCTOR_SPECIALITY}</th>
                                                                        <th>{LICENSE_NUMBER}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                        <th>{IMPERSONATING}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!trialList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                trialList &&
                                                                                trialList.map((item, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>
                                                                                                {item.doctor.firstName} {item.doctor.lastName}
                                                                                            </td>
                                                                                            <td>{item.user.email ? item.user.email : DASH}</td>
                                                                                            <td>{item.doctor.phoneNo1 ? item.doctor.phoneNo1 : DASH}</td>
                                                                                            {/* <td>{item.office.state ? item.office.state : DASH}</td> */}
                                                                                            <td>
                                                                                                {item.doctor.speciality
                                                                                                    ? Object.keys(CHOOSE_DOCTOR_TYPE).find((key) => CHOOSE_DOCTOR_TYPE[key] == item.doctor.speciality)
                                                                                                    : DASH}
                                                                                            </td>
                                                                                            <td>{item.doctor.licenseNumber ? item.doctor.licenseNumber : DASH}</td>
                                                                                            <td>{item.created_by ? dateFormateMonthDateYear(item.created_by) : DASH}</td>

                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.status == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.status == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {item.status == 1 ? ACTIVE : DEACTIVE}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary btn-xs"
                                                                                                    onClick={() => handleEdit(item.doctor.uniqueId, item.office.uniqueId)}
                                                                                                >
                                                                                                    <i className="fa fa-pencil "></i>
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger btn-xs"
                                                                                                    onClick={() => handleDelete(item.doctor.uniqueId, item.office.uniqueId)}
                                                                                                >
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.status === 1 ? (
                                                                                                    <button
                                                                                                        className="btn btn-primary"
                                                                                                        onClick={() =>
                                                                                                            handleImpersonate(
                                                                                                                item.id,
                                                                                                                item.doctor.uniqueId,
                                                                                                                item.office.uniqueId,
                                                                                                                item.payerId,
                                                                                                                item.payerName,
                                                                                                                item.payerValue,
                                                                                                                item.isTrial
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {IMPERSONATING}
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>
                                                            Total {trialList == null ? "0" : rowCount} {TRIAL}
                                                        </div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={trialList == null ? "0" : pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageTrial;
