import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { baseURL, frontURL } from "../../../Config";
import {
    $FEE,
    ACTION,
    CLAIM_INFORMATION,
    CODE,
    CPT,
    DASH,
    DESCRIPTION,
    DETAIL_CLAIM,
    DIAGNOSES,
    FILE_NAME,
    HOME,
    ID,
    INSURANCE_BACK,
    INSURANCE_FRONT,
    OTHERS,
    NO,
    NOTE,
    PATIENT_DOCUMENTS,
    PRE_AUTHORIZATION,
    PROCEDURE,
    PROCEDURE_DOCTOR_TYPE,
    PROCEDURE_TYPE_WITH_VALUE,
    PROVIDER_INFORMATION,
    SERVICE_LOCATION_TYPE,
    SUBSCRIBER_INFORMATION,
    THUMBNAIL,
    UNIT,
    CLAIM_LIST,
    XRAY,
    PATIENT_DOCUMENT_TYPE,
    CLAIM_,
    PAYER,
    PATIENT,
    DATE_OF_SERVICE,
    SERVICE_LOCATION_TYPE_TITLE,
    RENDERRING_PROVIDER,
    ACCELERATE_WAITING_PERIOD_TITLE,
    CANCEL,
    CLAIM_OP_NOTES_LIST,
    PATIENT_DETAIL,
    CREATED_DATE,
    REFERRING_PHYSICIAN_NAME,
} from "../../../Helper/Constants";
import { CLAIM_DETAIL, DIAGNOSES_API, PATIENT_DOCUMENT_LIST, PROCEDURE_LIST_API } from "../../../Helper/ApiPath";
import { toast } from "react-toastify";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import _ from "lodash";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { MAX_PRIORITY } from "../../../Helper/Messages";
import Cookies from "universal-cookie";

function DetailClaim() {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(true);
    const [ClaimDetail, setClaimDetail] = useState([]);
    let claimId = localStorage.getItem("claimId");
    let claimPath = localStorage.getItem("claimPath");
    let token = JSON.parse(localStorage.getItem("logToken"));

    let pageAuthorityRoleId = cookies.get("role");
    if (pageAuthorityRoleId) {
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    const [insuranceF, setInsuranceF] = useState([]);
    const [insuranceB, setInsuranceB] = useState([]);
    const [imageX, setImageX] = useState([]);
    const [imageN, setImageN] = useState([]);
    const [imageId, setImageId] = useState([]);
    const [imageOt, setImageOt] = useState([]);
    const fetchThumbnailData = async (type, patientId) => {
        axios
            .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
                headers: { authorization: token.token },
                type: type,
                patientId: patientId,
            })
            .then((res) => {
                if (type == 1) {
                    setInsuranceF(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
                    setInsuranceB(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.X_RAY) {
                    setImageX(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.NOTE) {
                    setImageN(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.ID) {
                    setImageId(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.OTHERS) {
                    setImageOt(res.data.data);
                }
            });
    };
    const handleDownload = (image, fileName, fixName) => {
        axios({
            url: `${frontURL}${image}`, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName ? fileName : fixName + ".png"}`); //or any other extension
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };
    let checkedList = [];
    const navigate = useNavigate();
    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${CLAIM_DETAIL}/${claimId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);

                if (res.data.data.diagnosesId != null) {
                    let diagnosesId = res.data.data.diagnosesId;
                    checkedList = diagnosesId.split("~");
                }
                setClaimDetail(res.data.data);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT, res.data.data.patient.uniqueId);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_BACK, res.data.data.patient.uniqueId);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.X_RAY, res.data.data.patient.uniqueId);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.NOTE, res.data.data.patient.uniqueId);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.ID, res.data.data.patient.uniqueId);
                fetchThumbnailData(PATIENT_DOCUMENT_TYPE.OTHERS, res.data.data.patient.uniqueId);
                axios
                    .post(`${baseURL}/${PROCEDURE_LIST_API}`, {
                        headers: { authorization: token.token },
                        doctorId: res.data.data.doctor.uniqueId,
                        statusCpt: "all",
                    })
                    .then((res1) => {
                        setIsLoading(false);
                        let oldprocedure = res1.data.data;
                        for (var j = 0; j < res.data.data.procedures.length; j++) {
                            var procedureId = res.data.data.procedures[j].procedureId;
                            var unit = res.data.data.procedures[j].unit;
                            var filterddata = _.find(oldprocedure, { id: procedureId });
                            var filterddata_index = oldprocedure.findIndex((p) => p.id == procedureId);
                            var newobj = {
                                id: filterddata.id,
                                code: filterddata.code,
                                type: filterddata.type,
                                description: filterddata.description,
                                fee: filterddata.fee,
                                priorityList: filterddata.priorityList,
                                value: parseFloat(unit),
                            };
                            oldprocedure[filterddata_index] = newobj;
                        }
                        const groupbydata = _.chain(oldprocedure)
                            .groupBy("type")
                            .map((value, key) => ({ type: key, data: value }))
                            .value();
                        setProcedure(groupbydata);
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [diagnoses, setDiagnoses] = useState([]);

    const diagnosesList = () => {
        axios
            .post(`${baseURL}/${DIAGNOSES_API}`, {
                headers: { authorization: token.token },
                isNote: "",
            })
            .then((res) => {
                setIsLoading(false);
                var diagno = [];

                checkedList.map((item) => {
                    var diagnosis = res.data.data;
                    var filterddata = _.find(diagnosis, { id: parseInt(item) });
                    var newobj = {
                        code: filterddata.code,
                        name: filterddata.name,
                        isNote: filterddata.isNote,
                    };
                    diagno.push(newobj);
                });
                setDiagnoses(diagno);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [procedure, setProcedure] = useState([]);

    useEffect(async () => {
        await fetchdata();
        diagnosesList();
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                {/* <ToastContainer /> */}
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{DETAIL_CLAIM}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-ambulance" aria-hidden="true"></i>&nbsp;
                                    {claimPath === "opNote" ? (
                                        <NavLink className="parent-item" to="/manageOpNotes">
                                            {CLAIM_OP_NOTES_LIST}
                                        </NavLink>
                                    ) : claimPath === "patientDetail" ? (
                                        <NavLink className="parent-item" to="/patientProfile">
                                            {PATIENT_DETAIL}
                                        </NavLink>
                                    ) : (
                                        <NavLink className="parent-item" to="/manageClaim">
                                            {CLAIM_LIST}
                                        </NavLink>
                                    )}
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{DETAIL_CLAIM}</li>
                            </ol>
                        </div>
                    </div>

                    {isLoading ? (
                        <i
                            className="fa fa-spinner fa-spin fa-2x"
                            style={{
                                position: "relative",
                                top: "13rem",
                                left: "50rem",
                                fontSize: "55px",
                                zIndex: 2,
                            }}
                        ></i>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-xl-12 col-md-12 col-lg-12 col-sm-12">
                                    {/* <h3>{GENERAL_INFORMATION}</h3> */}
                                    <div className="card">
                                        <div className="card-head">
                                            <header>{CLAIM_INFORMATION}</header>
                                        </div>

                                        <div className="card-body" style={{ paddingRight: "250px", paddingLeft: " 35px" }}>
                                            <div className="form-horizontal">
                                                <div className="form-body">
                                                    <div className="pull-left">
                                                        <div>
                                                            <b>{CLAIM_}</b> : <span>{ClaimDetail.code ? ClaimDetail.code : DASH}</span>
                                                        </div>
                                                        <div>
                                                            <b>{PAYER} </b> : <span>{ClaimDetail.payer ? ClaimDetail.payer && ClaimDetail.payer.name : DASH}</span>
                                                        </div>
                                                        <div>
                                                            <b>{PATIENT} </b> :{" "}
                                                            <span>
                                                                {ClaimDetail.patient && ClaimDetail.patient.firstName} {ClaimDetail.patient && ClaimDetail.patient.lastName}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <b>{DATE_OF_SERVICE} </b> :{" "}
                                                            <span>{ClaimDetail.dateOfService ? ClaimDetail && dateFormateMonthDateYear(ClaimDetail.dateOfService) : DASH}</span>
                                                        </div>
                                                        <div>
                                                            <b>{PRE_AUTHORIZATION}</b> : <span>{ClaimDetail.preAuthorization ? ClaimDetail && ClaimDetail.preAuthorization : DASH}</span>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right">
                                                        <div>
                                                            <b>{SERVICE_LOCATION_TYPE_TITLE} </b> :{" "}
                                                            <span>
                                                                {ClaimDetail.serviceLocationTypeId
                                                                    ? Object.keys(SERVICE_LOCATION_TYPE).find((key) => SERVICE_LOCATION_TYPE[key] === ClaimDetail.serviceLocationTypeId)
                                                                    : DASH}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <b>{REFERRING_PHYSICIAN_NAME}</b> :{" "}
                                                            <span>{ClaimDetail.referringPhysicianName ? ClaimDetail && ClaimDetail.referringPhysicianName : DASH}</span>
                                                        </div>
                                                        <div>
                                                            <b>{RENDERRING_PROVIDER} </b> :{" "}
                                                            <span>
                                                                {" "}
                                                                {ClaimDetail.doctor && ClaimDetail.doctor.firstName} {ClaimDetail.doctor && ClaimDetail.doctor.lastName}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <b>{ACCELERATE_WAITING_PERIOD_TITLE} </b> :{" "}
                                                            <span>
                                                                {ClaimDetail.accelerateWaitingPeriodId && ClaimDetail.accelerateWaitingPeriodId === 1
                                                                    ? "Established Patient Level 1"
                                                                    : ClaimDetail.accelerateWaitingPeriodId === 2
                                                                    ? "Established Patient Level 2"
                                                                    : ClaimDetail.accelerateWaitingPeriodId === 3
                                                                    ? "New Patient Level 1"
                                                                    : ClaimDetail.accelerateWaitingPeriodId === 4
                                                                    ? "New Patient Level 2"
                                                                    : DASH}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-6"> */}
                                <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-head">
                                            <header>{SUBSCRIBER_INFORMATION}</header>
                                        </div>
                                        <div className="card-body no-padding height-9">
                                            <ul className="performance-list">
                                                <table className="table table-inbox table-hover">
                                                    <tbody>
                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Medicare Number</td>
                                                            <td className="view-message ">
                                                                {/* <a> {ClaimDetail.patient && ClaimDetail.patient.ssnId ? ClaimDetail.patient.ssnId : DASH}</a> */}
                                                                <a> {ClaimDetail.patient && ClaimDetail.patient.MedicarePatientId ? ClaimDetail.patient.MedicarePatientId : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        {/* <tr className="unread panel-heading-gray">
                                                    <td></td>
                                                    <td className="view-message  dont-show">Subscriber Payment Responsibility</td>
                                                    <td className="view-message ">
                                                        <a>P</a>
                                                    </td>
                                                </tr> */}

                                                        <tr className="unread  panel-heading-gray">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber First Name</td>
                                                            <td className="view-message ">
                                                                <a> {ClaimDetail.patient && ClaimDetail.patient.firstName ? ClaimDetail.patient.firstName : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber Last Name</td>
                                                            <td className="view-message ">
                                                                <a> {ClaimDetail.patient && ClaimDetail.patient.lastName ? ClaimDetail.patient.lastName : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread  panel-heading-gray">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber Gender</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.gender ? ClaimDetail.patient.gender : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber Date Of Birth</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.dob ? dateFormateMonthDateYear(ClaimDetail.patient.dob) : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread panel-heading-gray">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber Address</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.address1 ? ClaimDetail.patient.address1 : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber City</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.city ? ClaimDetail.patient.city : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread panel-heading-gray">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber State</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.state ? ClaimDetail.patient.state : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Subscriber Postal Code</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.patient.zip ? ClaimDetail.patient.zip : DASH}</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-6"> */}
                                <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-head">
                                            <header>{PROVIDER_INFORMATION}</header>
                                        </div>
                                        <div className="card-body no-padding height-9">
                                            <ul className="performance-list">
                                                <table className="table table-inbox table-hover">
                                                    <tbody>
                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Providers NPI</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.doctor.npi1 ? ClaimDetail.doctor.npi1 : DASH}</a>
                                                            </td>
                                                        </tr>

                                                        <tr className="unread">
                                                            <td>
                                                                <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                            </td>
                                                            <td className="view-message  dont-show">Providers Contact Phone</td>
                                                            <td className="view-message ">
                                                                <a>{ClaimDetail.patient && ClaimDetail.doctor.phoneNo1 ? ClaimDetail.doctor.phoneNo1 : DASH}</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Image Section */}

                            <div className="card">
                                <div className="card-head">
                                    <header>{PATIENT_DOCUMENTS}</header>
                                </div>
                                <div className="card-body no-padding height-9">
                                    <div className="row mx-2">
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_FRONT}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!insuranceF ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            insuranceF &&
                                                            insuranceF.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName
                                                                                    ? item.fileName.length > 20
                                                                                        ? item.fileName.slice(0, 20) + "..."
                                                                                        : item.fileName
                                                                                    : INSURANCE_FRONT + ".png"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success btn-xs"
                                                                                onClick={() => handleDownload(item.image, item.fileName, INSURANCE_FRONT)}
                                                                            >
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_BACK}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!insuranceB ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            insuranceB &&
                                                            insuranceB.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName
                                                                                    ? item.fileName.length > 20
                                                                                        ? item.fileName.slice(0, 20) + "..."
                                                                                        : item.fileName
                                                                                    : INSURANCE_BACK + ".png"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success btn-xs"
                                                                                onClick={() => handleDownload(item.image, item.fileName, INSURANCE_BACK)}
                                                                            >
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{XRAY}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!imageX ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            imageX &&
                                                            imageX.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : XRAY + ".png"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, XRAY)}>
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{NOTE}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!imageN ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            imageN &&
                                                            imageN.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : NOTE + ".png"}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, NOTE)}>
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{ID}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!imageId ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            imageId &&
                                                            imageId.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : ID + ".png"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, ID)}>
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-between my-2 mx-2">
                                                <div>
                                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{OTHERS}</p>
                                                </div>
                                            </div>
                                            <div className="table-scrollable">
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{NO}</th>
                                                            <th>{FILE_NAME}</th>
                                                            <th>{THUMBNAIL}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!imageOt ? (
                                                            <>{<NoDataFound />}</>
                                                        ) : (
                                                            imageOt &&
                                                            imageOt.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                                        <td>
                                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : OTHERS + ".png"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <Zoom>
                                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                                </Zoom>
                                                                            )}{" "}
                                                                        </td>
                                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                                <i className="fa  fa-eye"></i>
                                                                            </button>
                                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, OTHERS)}>
                                                                                <i className="fa  fa-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="row">
                                    {/* Diagnosis */}
                                    <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                                        <div className="card">
                                            <div className="card-head">
                                                <header>{DIAGNOSES}</header>
                                            </div>
                                            <div className="card-body no-padding height-9">
                                                <ul className="performance-list">
                                                    <table className="table  table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{DIAGNOSES}</th>
                                                                <th>{CODE}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!diagnoses.length ? (
                                                                <NoDataFound />
                                                            ) : (
                                                                diagnoses &&
                                                                diagnoses.map((item, i) => {
                                                                    return (
                                                                        <tr className="unread panel-heading-gray" key={i}>
                                                                            {item.isNote == 0 ? (
                                                                                <>
                                                                                    <td className="view-message  dont-show">{item.name}</td>
                                                                                    <td className="view-message  dont-show">{item.code}</td>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Procedure */}
                                    <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                                        <div className="card">
                                            <div className="card-head">
                                                <header>{PROCEDURE}</header>
                                            </div>
                                            {Object.keys(procedure).map((item, i) => (
                                                <div className="card-body" id="line-parent" key={i}>
                                                    <div className="panel-group accordion" id={"accordion" + i}>
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading panel-heading-gray">
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ width: "97%" }}>
                                                                        <h4>{Object.keys(PROCEDURE_TYPE_WITH_VALUE).find((key) => PROCEDURE_TYPE_WITH_VALUE[key] == procedure[item].type)}</h4>
                                                                    </div>
                                                                    <div>
                                                                        <a
                                                                            className="accordion-toggle accordion-toggle-styled collapsed"
                                                                            data-bs-toggle="collapse"
                                                                            data-parent={"accordion" + i}
                                                                            href={"#accodation_3_" + i}
                                                                        >
                                                                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={"accodation_3_" + i} className="panel-collapse in">
                                                                <div className="panel-body" style={{ height: "auto", overflowY: "auto" }}>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{CPT}</th>
                                                                                <th>{DESCRIPTION}</th>
                                                                                {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                                    <th>{$FEE}</th>
                                                                                }
                                                                                <th>{UNIT}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {procedure[item].data.map((prod, i) => (
                                                                                <tr key={i} className={`${prod.priorityList != MAX_PRIORITY ? "bgPriorityRowColor" : ""}`}>
                                                                                    <td className={`${prod.value > 0 ? "prodCode prodBorder col-1" : "d-none"} `}>{prod.code}</td>
                                                                                    <td className={`${prod.value > 0 ? "prodDesc prodBorder col-7" : "d-none"} `}>{prod.description}</td>
                                                                                    {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                                        <td className={`${prod.value > 0 ? "prodFee prodBorder col-1" : "d-none"} `}>{prod.fee}</td>
                                                                                    }
                                                                                    <td className={`${prod.value > 0 ? "prodAddFee prodBorder col-1" : "d-none"} `}>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            name={prod.id}
                                                                                            data-type={procedure[item].type}
                                                                                            value={prod.value}
                                                                                            disabled
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="form-actions mt-5">
                                    <div className="center">
                                        {claimPath === "opNote" ? (
                                            <Link to="/manageOpNotes" className="btn btn-default m-r-20 col-1 p-3">
                                                {" "}
                                                {CANCEL}
                                            </Link>
                                        ) : claimPath === "patientDetail" ? (
                                            <Link to="/patientProfile" className="btn btn-default m-r-20 col-1 p-3">
                                                {" "}
                                                {CANCEL}
                                            </Link>
                                        ) : (
                                            <Link to="/manageClaim" className="btn btn-default m-r-20 col-1 p-3">
                                                {" "}
                                                {CANCEL}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default DetailClaim;
