import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { EDIT_DOCTOR_PROFILE, PAYER_LIST_WITHOUT_MIDDLEWARE_API, PROFILE_API } from "../../../Helper/ApiPath";
import {
    BIRTH_DATE,
    CANCEL,
    CHOOSE_DOCTOR_TYPE,
    DOCTOR_SPECIALITY,
    EDIT_TRIAL,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    NPI1,
    NPI2,
    PAYER,
    PHONE_LENGTH,
    PHONE_NO,
    PTAN_NUMBER,
    SELECT,
    SELECT_PAYER,
    SSN,
    TAXID,
    TRIAL_LIST,
    UPDATE,
} from "../../../Helper/Constants";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../../Helper/Helper";
import {
    DOCTOR_ID_NOT_FOUND,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI_LESS_THAN_10,
    ENTER_PAYER,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALITY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_TAX_ID_LESS_THAN_10,
    MAX_LENGTH,
    PHONE_NUMBER,
} from "../../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "universal-cookie";

function EditTrial() {
    let doctorId = localStorage.getItem("doctorId");
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;

    let navigate = useNavigate();

    const [profileInfo, setProfileInfo] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        gender: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        speciality: "",
        ptanNumber: "",
        fee: "",
        profilePic: "",
        payerLabel: "",
        payerId: "",
    });
    const [payer, setPayer] = useState([]);
    const [payerName, setPayerName] = useState("");
    const [payerValue, setPayerValue] = useState("");
    const [payerId, setPayerId] = useState("");
    const [payerChange, setPayerChange] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setProfileInfo({ ...profileInfo, [name]: onlyNumber });
        } else {
            setProfileInfo({ ...profileInfo, [name]: value });
        }
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${PROFILE_API}/${doctorId}`, {
                headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                setProfileInfo(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setPayerChange(true);
        setPayerValue(e.value);
        setPayerName(e.label);
        setPayerId(e.id);
        setProfileInfo({ ...profileInfo, payerLabel: label, payerId: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const handleUpdate = async () => {
        if (!doctorId) {
            toast.error(DOCTOR_ID_NOT_FOUND);
        } else if (!profileInfo.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!profileInfo.lastName) {
            toast.error(ENTER_LNAME);
        } else if (profileInfo.npi1 && profileInfo.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (profileInfo.npi2 && profileInfo.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (profileInfo.ssn && profileInfo.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (profileInfo.taxId && profileInfo.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (profileInfo.ptanNumber && profileInfo.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (!profileInfo.speciality) {
            toast.error(ENTER_SPECIALITY);
        } else if (!profileInfo.payerId) {
            toast.error(ENTER_PAYER);
        } else if (profileInfo.phoneNo1 && profileInfo.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            await axios
                .post(`${baseURL}/${EDIT_DOCTOR_PROFILE}`, {
                    headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
                    firstName: profileInfo.firstName,
                    middleName: profileInfo.middleName,
                    lastName: profileInfo.lastName,
                    phoneNo1: profileInfo.phoneNo1,
                    dob: dateAPIFormate(profileInfo.dob),
                    npi1: profileInfo.npi1,
                    npi2: profileInfo.npi2,
                    ssn: profileInfo.ssn,
                    taxId: profileInfo.taxId,
                    licenseNumber: profileInfo.licenseNumber,
                    gender: profileInfo.gender,
                    speciality: profileInfo.speciality,
                    ptanNumber: profileInfo.ptanNumber,
                    doctorId: doctorId,
                    payerId: profileInfo.payerId,
                })
                .then((res) => {
                    navigate("/manageTrial");
                    if (res.status === 200) {
                        toast.success(res.data.message);

                        if (payerChange) {
                            localStorage.setItem("payerName", payerName);
                            localStorage.setItem("payerValue", payerValue);
                            localStorage.setItem("payerId", payerId);
                        }
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageTrial");
    };

    useEffect(() => {
        fetchData();
        payerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className="pull-left">
                                <div className="page-title">{EDIT_TRIAL}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-user-secret"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageTrial">
                                        {TRIAL_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_TRIAL}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EDIT_TRIAL}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <form action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        placeholder=" Enter first name"
                                                        value={profileInfo.firstName}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {LAST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        placeholder=" Enter last name"
                                                        value={profileInfo.lastName}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                <div className="col-md-5">
                                                    <DatePicker
                                                        className="form-control input-height mr-sm-2"
                                                        value={dateFormateMonthDateYear(profileInfo && profileInfo.dob)}
                                                        placeholderText={"Please Select Date"}
                                                        maxDate={new Date()}
                                                        scrollableMonthDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        onChange={(date) => setProfileInfo({ ...profileInfo, dob: date })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{GENDER}</label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="gender" value={profileInfo.gender} onChange={handleChange}>
                                                        <option value=""> {SELECT}</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{PHONE_NO}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="phoneNo1"
                                                        type="number"
                                                        placeholder="mobile number"
                                                        onChange={handleChange}
                                                        value={profileInfo.phoneNo1}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{NPI1}</label>
                                                <div className="col-md-5">
                                                    <input name="npi1" type="text" placeholder="NPI number" onChange={handleChange} value={profileInfo.npi1} className="form-control input-height" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{NPI2}</label>
                                                <div className="col-md-5">
                                                    <input name="npi2" type="text" placeholder="NPI number" onChange={handleChange} value={profileInfo.npi2} className="form-control input-height" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{SSN}</label>
                                                <div className="col-md-5">
                                                    <input name="ssn" type="text" placeholder="SSN number" onChange={handleChange} value={profileInfo.ssn} className="form-control input-height" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{TAXID}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="taxId"
                                                        type="text"
                                                        placeholder="TaxId number"
                                                        onChange={handleChange}
                                                        value={profileInfo.taxId}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="licenseNumber"
                                                        type="text"
                                                        placeholder="License Number number"
                                                        onChange={handleChange}
                                                        value={profileInfo.licenseNumber}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="ptanNumber"
                                                        type="text"
                                                        placeholder="License PTAN number"
                                                        onChange={handleChange}
                                                        value={profileInfo.ptanNumber}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {DOCTOR_SPECIALITY}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="speciality" onChange={handleChange} value={profileInfo.speciality}>
                                                        {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                            return (
                                                                <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PAYER}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        menuPlacement="top"
                                                        className="text-dark"
                                                        name="payerId"
                                                        value={payerOptions && payerOptions.filter(({ id }) => id === profileInfo.payerId)}
                                                        options={payerOptions}
                                                        onChange={handlePayerChange}
                                                        placeholder={SELECT_PAYER}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="row mt-3 mb-0 d-flex justify-content-center">
                                                <div className="col-1 m-r-20 my-3">
                                                    <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                                                        {UPDATE}
                                                    </button>
                                                </div>
                                                <div className="col-1  m-r-20 my-3">
                                                    <button type="button" className="btn btn-default" onClick={handleCancel}>
                                                        {CANCEL}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditTrial;
