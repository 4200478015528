import React, { useEffect, useState } from "react";
import {
    HOME,
    CREATED_DATE,
    DASH,
    ROWS_PER_PAGE,
    ACTION,
    SELECT_OFFICE,
    RECEIPT_LIST,
    OFFICE_NAME,
    RECEIPT_NO,
    RECEIPT_DATE,
    RECEIPT_AMOUNT,
    DOWNLOAD_RECEIPT,
    ADD,
    DOWNLOAD,
    DOWNLOAD_EXCEL,
} from "../../Helper/Constants";
import { DETAIL_RECEIPT_API, RECEIPT_OFFICE_LIST_API, RECEIPT_DOCUMENT_LIST_API, RECEIPT_LIST_API, RECEIPT_EXCEL_DOWNLOAD_API } from "../../Helper/ApiPath";
import { dateAPIFormate, dateFormateMonthDateYear, defaultTimeZone } from "../../Helper/Helper";
import axios from "axios";
import { baseURL, frontURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_RECEIPT_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";
import DownloadReceiptFiles from "../Forms/Receipt/DownloadReceiptFiles";

function ManageReceipt() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const receiptPagination = JSON.parse(sessionStorage.getItem("receiptPagination"));
    const [isLoading, setIsLoading] = useState(true);
    const [receiptList, setReceiptList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(receiptPagination && receiptPagination.offset ? receiptPagination.offset : 1);
    const [pageCount, setPageCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [show, setShow] = useState(false);
    let token = JSON.parse(localStorage.getItem("logToken"));
    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        status: receiptPagination && receiptPagination.status ? receiptPagination.status : "",
        officeSearch: receiptPagination && receiptPagination.officeSearch ? receiptPagination.officeSearch : "",
        startDate: receiptPagination && receiptPagination.startDate ? defaultTimeZone(receiptPagination.startDate) : new Date(date.setMonth(date.getMonth() - 12)),
        endDate: receiptPagination && receiptPagination.endDate ? defaultTimeZone(receiptPagination.endDate) : new Date(date.setMonth(date.getMonth() + 13)),
        receiptNo: receiptPagination && receiptPagination.receiptNo ? receiptPagination.receiptNo : "",
    });

    const handleShow = (id) => {
        setShow(true);
        fetchThumbnailData(id);
    };

    const handleClose = () => {
        setShow(false);
    };

    const [receiptFile, setReceiptFile] = useState([]);

    const fetchThumbnailData = async (receiptId) => {
        await axios
            .post(`${baseURL}/${RECEIPT_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                receiptId: receiptId,
            })
            .then((res) => {
                setReceiptFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClick = () => {
        navigate("/addReceipt");
    };

    const [officeId, setOfficeId] = useState(receiptPagination && receiptPagination.officeId ? receiptPagination.officeId : "");

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${RECEIPT_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                receiptNo: filterValues.receiptNo,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
            })
            .then((res) => {
                setIsLoading(false);
                setReceiptList(res.data.data[0]);
                setRowCount(res.data.extraData.rowCount);
                setPageCount(res.data.extraData.pageCount);
                setTotalAmount(res.data.data[1].totalReceiptAmount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [officeSelect, setOfficeSelect] = useState([]);

    let officeList = [
        {
            id: "",
            name: SELECT_OFFICE,
        },
    ];

    const fetchOfficeList = async (e) => {
        await axios
            .post(`${baseURL}/${RECEIPT_OFFICE_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeSelect(res.data.data ? officeList.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        officeSelect &&
        officeSelect.map((item, i) => {
            return {
                value: item.uniqueId,
                label: item.name,
            };
        });

    const handleOfficeChange = async (e) => {
        setOffset(1);
        setCurrentPage(0);
        setOfficeId(e.value);
        let value = e.id;
        setFilterValues({ ...filterValues, officeSearch: value });
        sessionStorage.setItem(
            "receiptPagination",
            JSON.stringify({
                ...filterValues,
                officeId: officeId,
                officeSearch: value,
                receiptNo: e.target.value,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                offset: offset,
            })
        );
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("receiptPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        sessionStorage.setItem(
            "receiptPagination",
            JSON.stringify({
                ...filterValues,
                officeId: officeId,
                officeSearch: filterValues.officeSearch,
                receiptNo: filterValues.receiptNo,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                offset: offset,
            })
        );
        localStorage.setItem("receiptId", id);
        navigate("/editReceipt");
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_RECEIPT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DETAIL_RECEIPT_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status === 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleExcelDownload = async () => {
        await axios
            .post(`${baseURL}/${RECEIPT_EXCEL_DOWNLOAD_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
            })
            .then((res) => {
                if (res.status === 200) {
                    axios({
                        url: `${frontURL}${res.data.data.ReceiptPath}`, //your url
                        method: "GET",
                        responseType: "blob", // important
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "receiptdownload" + ".xlsx"); //or any other extension
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                    });
                    // console.log("res", res.data.data.ReceiptPath);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchData();
        fetchOfficeList();
    }, [offset, officeId, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{RECEIPT_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{RECEIPT_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="col-md-1 mr-sm-2">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleClick}>
                                                                            {ADD} <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="btn-group col-md-3 pr-0">
                                                                        <div className="col-md-4">
                                                                            <label className="mt-2">{RECEIPT_DATE}</label>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "receiptPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        startDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, startDate: date });
                                                                            }}
                                                                        />
                                                                        <span className="todate">To</span>
                                                                    </div>

                                                                    <div className="btn-group col-md-2">
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.endDate}
                                                                            minDate={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "receiptPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        endDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, endDate: date });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {/* <div className="col-1"></div> */}
                                                                    {/* <div className="col-md-2 mr-sm-2"></div> */}

                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <Select
                                                                                className="col-5"
                                                                                name="officeSelect"
                                                                                value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeId)}
                                                                                options={officeSelectOptions}
                                                                                onChange={handleOfficeChange}
                                                                                placeholder={SELECT_OFFICE}
                                                                            />

                                                                            <div className="col-3">
                                                                                <div className="btn-group nameBtnDoctor">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="receiptNo"
                                                                                        value={filterValues.receiptNo}
                                                                                        onChange={(e) => {
                                                                                            sessionStorage.setItem(
                                                                                                "receiptPagination",
                                                                                                JSON.stringify({
                                                                                                    ...filterValues,
                                                                                                    officeId: officeId,
                                                                                                    officeSearch: filterValues.officeSearch,
                                                                                                    receiptNo: e.target.value,
                                                                                                    startDate: dateAPIFormate(filterValues.startDate),
                                                                                                    endDate: dateAPIFormate(filterValues.endDate),
                                                                                                    offset: offset,
                                                                                                })
                                                                                            );
                                                                                            setFilterValues({ ...filterValues, receiptNo: e.target.value });
                                                                                        }}
                                                                                        placeholder={RECEIPT_NO}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{OFFICE_NAME}</th>
                                                                        <th>{RECEIPT_NO}</th>
                                                                        <th>{RECEIPT_DATE}</th>
                                                                        <th>{RECEIPT_AMOUNT}</th>
                                                                        {/* <th>{CREATED_DATE}</th> */}
                                                                        <th>{DOWNLOAD_RECEIPT}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <DownloadReceiptFiles show={show} handleShow={handleShow} handleClose={handleClose} receiptFile={receiptFile} />
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!receiptList || (receiptList && !receiptList.length) ? (
                                                                                <NoDataFound />
                                                                            ) : (
                                                                                receiptList &&
                                                                                receiptList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX">
                                                                                            <td>{item.office.name}</td>
                                                                                            <td>{item.receiptNo ? item.receiptNo : DASH}</td>
                                                                                            <td>{item.receiptDate ? dateFormateMonthDateYear(item.receiptDate) : DASH}</td>
                                                                                            <td>${item.receiptAmount ? item.receiptAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : DASH}</td>
                                                                                            {/* <td>{dateFormateMonthDateYear(item.created_at)}</td> */}
                                                                                            <td>
                                                                                                <button className="btn btn-success" onClick={() => handleShow(item.id)}>
                                                                                                    {DOWNLOAD} <i className="fa fa-download"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </div>

                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div className="d-flex justify-content-start">
                                                            <div>Total {receiptList == null ? "0" : rowCount.toLocaleString("en-US")} Receipt</div>
                                                            {/* <div className="mx-3">Total Amount ${totalAmount == null ? "0" : totalAmount.toLocaleString("en-US")} </div>
                                                            <button className="btn btn-warning mx-3" onClick={handleExcelDownload} style={{ marginBottom: "auto" }}>
                                                                {DOWNLOAD_EXCEL} <i className="fa fa-download"></i>
                                                            </button> */}
                                                        </div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={receiptPagination && receiptPagination.offset ? receiptPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageReceipt;
