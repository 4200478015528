import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { Modal, Button } from "react-bootstrap";
import {
    ACTION,
    ADD,
    BIRTH_DATE,
    CHOOSE_DOCTOR_TYPE,
    DASH,
    DOCTOR_LIST,
    DOCTOR_SPECIALITY,
    DOCTOR_STATUS,
    EMAIL,
    FIRST_NAME,
    GENDER,
    HOME,
    IS_TRIAL,
    LAST_NAME,
    NAME,
    NPI1,
    NPI2,
    PHONE_NO,
    ROWS_PER_PAGE,
    SSN,
    STATUS,
    TAXID,
    TYPE,
    ALL,
} from "../../Helper/Constants";
import ReactPaginate from "react-paginate";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import { NavLink, useNavigate } from "react-router-dom";
import { DELETE_TRIAL_API, MANAGE_DOCTOR_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_DOCTOR_MESSAGE } from "../../Helper/Messages";
import Swal from "sweetalert2";
import Loader from "../CommonComponent/Loader";

function ManageDoctor() {
    const navigate = useNavigate();
    const doctorPagination = JSON.parse(sessionStorage.getItem("doctorPagination"));
    const [isLoading, setIsLoading] = useState(true);
    const [doctorList, setDoctorList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(doctorPagination && doctorPagination.offset ? doctorPagination.offset : 1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    const token = JSON.parse(localStorage.getItem("logToken"));
    const [showChangePassMdl, setShowChangePassMdl] = useState(false);
    const [changePassEmail, setChangePassEmail] = useState("");
    const [changeIndex, setChangeIndex] = useState(-1);
    const [changePassStep, setChangePassStep] = useState(2);
    const [resetType, setResetType] = useState("1");
    const [emailSend, setEmailSend] = useState("1");
    const [changePassLoader, setChangePassLoader] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [filterValues, setFilterValues] = useState({
        email: doctorPagination && doctorPagination.email ? doctorPagination.email : "",
        search: doctorPagination && doctorPagination.search ? doctorPagination.search : "",
        status: doctorPagination && doctorPagination.status ? doctorPagination.status : "",
        trial: doctorPagination && doctorPagination.trial ? doctorPagination.trial : "0",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        sessionStorage.setItem(
            "doctorPagination",
            JSON.stringify({
                ...filterValues,
                [name]: value,
                offset: offset,
            })
        );
        setOffset(1);
        setCurrentPage(0);
    };
    const handleChangePassMdl = (type, index = -1) => {
        setShowChangePassMdl(type ? true : false);
        setChangePassEmail("");
        if (index !== -1) {
        }
        setChangeIndex(index);
        setChangePassStep(2);
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_DOCTOR_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                statusId: filterValues.status,
                search: filterValues.search,
                isTrial: filterValues.trial,
                emailSearch: filterValues.email,
            })
            .then((res) => {
                setIsLoading(false);
                setDoctorList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("doctorPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = async (id) => {
        sessionStorage.setItem(
            "doctorPagination",
            JSON.stringify({
                email: filterValues.email,
                search: filterValues.search,
                status: filterValues.status,
                trial: filterValues.trial,
                offset: offset,
            })
        );
        navigate("/editDoctor");
        localStorage.setItem("doctorId", id);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_DOCTOR_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${DELETE_TRIAL_API}`, {
                            headers: { authorization: token.token },
                            doctorId: id,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleClick = (e) => {
        e.preventDefault();
        navigate("/registerDoctor");
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    function generatePassword(passwordLength) {
        var numberChars = "0123456789";
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowerChars = "abcdefghijklmnopqrstuvwxyz";
        var specialChars = "!@#$%?&";
        var allChars = numberChars + upperChars + lowerChars + specialChars;
        var randPasswordArray = Array(passwordLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray[3] = specialChars;
        randPasswordArray = randPasswordArray.fill(allChars, 4);
        return shuffleArray(
            randPasswordArray.map(function (x) {
                return x[Math.floor(Math.random() * x.length)];
            })
        ).join("");
    }

    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
    const [newPass, setNewPass] = useState(generatePassword(10));
    const changePassFirstStep = async () => {};

    const changePassCfm = async () => {
        if (!changePassLoader) {
            if (resetType == "0" && newPass == "") {
                toast.error("Enter password");
                return false;
            }
            const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
            if (!validPassword.test(newPass)) {
                toast.error("At least 8 characters: Uppercase, Lowercase, Numeric, or Special Characters.");
                return false;
            }
            setChangePassLoader(true);
            await axios
                .post(`${baseURL}/api/v1/admin/doctor/resetPassByAdmin`, {
                    headers: { authorization: token.token },
                    resetType,
                    emailSend,
                    newPass,
                    _user: doctorList[changeIndex].user.uniqueId,
                })
                .then((res) => {
                    setChangePassLoader(false);
                    toast.success("Password has been change successfully.");
                    setShowChangePassMdl(false);
                    setNewPass("");
                    setResetType("1");
                    setEmailSend("1");
                    setChangePassStep(2);
                })
                .catch((err) => {
                    setChangePassLoader(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const getNewPass = (type) => {
        setResetType(type);
        if (type == "1") {
            setNewPass(generatePassword(10));
        } else {
            setNewPass("");
        }
    };

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{DOCTOR_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{DOCTOR_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="col-1">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleClick}>
                                                                            {ADD} <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-11">
                                                                        <div className="row">
                                                                            <div className="col-2"></div>
                                                                            <div className="col-3">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <label className="mt-2">{EMAIL}</label>{" "}
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            name="email"
                                                                                            value={filterValues.email}
                                                                                            type="search"
                                                                                            placeholder="Search"
                                                                                            aria-label="Search"
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <label className="mt-2">{NAME}</label>{" "}
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            name="search"
                                                                                            value={filterValues.search}
                                                                                            type="search"
                                                                                            placeholder="Search"
                                                                                            aria-label="Search"
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <label className="mt-2 ">{TYPE}</label>
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <select className="form-control col-md-3" name="trial" value={filterValues.trial} onChange={handleFilterValues}>
                                                                                            <option value="">{ALL}</option>
                                                                                            {Object.keys(IS_TRIAL).map((item, i) => {
                                                                                                return (
                                                                                                    <option key={i} value={IS_TRIAL[item]}>
                                                                                                        {item}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <label className="mt-2">{STATUS}</label>
                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <select
                                                                                            className="form-control col-md-3"
                                                                                            name="status"
                                                                                            value={filterValues.status}
                                                                                            onChange={handleFilterValues}
                                                                                        >
                                                                                            <option value="">{ALL}</option>
                                                                                            {Object.keys(DOCTOR_STATUS).map((item, i) => {
                                                                                                return (
                                                                                                    <option key={i} value={DOCTOR_STATUS[item]}>
                                                                                                        {item}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{EMAIL}</th>
                                                                        <th>{FIRST_NAME}</th>
                                                                        <th>{LAST_NAME}</th>
                                                                        <th>{GENDER}</th>
                                                                        <th>{PHONE_NO}</th>
                                                                        <th>{BIRTH_DATE}</th>
                                                                        <th>{NPI1}</th>
                                                                        <th>{NPI2}</th>
                                                                        <th>{SSN}</th>
                                                                        <th>{TAXID}</th>
                                                                        <th>{DOCTOR_SPECIALITY}</th>
                                                                        <th>{TYPE}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!doctorList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                doctorList &&
                                                                                doctorList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">
                                                                                                {item.user && item.user.email
                                                                                                    ? item.user.email
                                                                                                    : item.createUser && item.createUser.email
                                                                                                    ? item.createUser.email
                                                                                                    : DASH}
                                                                                            </td>
                                                                                            <td className="left">{item.firstName}</td>
                                                                                            <td className="left">{item.lastName}</td>
                                                                                            <td className="left">{item.gender ? item.gender : DASH}</td>
                                                                                            <td className="left">{item.phoneNo1 ? item.phoneNo1 : DASH}</td>
                                                                                            <td className="left">{item.dob ? dateFormateMonthDateYear(item.dob) : DASH}</td>
                                                                                            <td className="left">{item.npi1 ? item.npi1 : DASH}</td>
                                                                                            <td className="left">{item.npi2 ? item.npi2 : DASH}</td>
                                                                                            <td className="left">{item.ssn ? item.ssn : DASH}</td>
                                                                                            <td className="left">{item.taxId ? item.taxId : DASH}</td>
                                                                                            <td className="left">
                                                                                                {" "}
                                                                                                {item.speciality
                                                                                                    ? Object.keys(CHOOSE_DOCTOR_TYPE).find((key) => CHOOSE_DOCTOR_TYPE[key] == item.speciality)
                                                                                                    : DASH}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.isTrial == 1
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.isTrial == 0
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-dangerdeactiveStatus badge-warning")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(IS_TRIAL).find((key) => IS_TRIAL[key] == item.isTrial)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(DOCTOR_STATUS).find((key) => DOCTOR_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                                {item.isChangePass ? (
                                                                                                    <button
                                                                                                        className="btn btn-info btn-xs btn-radius"
                                                                                                        title="Change Password"
                                                                                                        onClick={() => handleChangePassMdl(1, index)}
                                                                                                    >
                                                                                                        <i className="fa fa-lock "></i>
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {doctorList == null ? "0" : rowCount} Doctors</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={doctorPagination && doctorPagination.offset ? doctorPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showChangePassMdl} onHide={() => handleChangePassMdl(0)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="h5 mt-0 mb-0">Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {changePassStep == 1 ? (
                        <div className="row">
                            <div className="col-md-12">
                                <label>Email</label>
                                <input type="text" value={changePassEmail} onChange={(e) => setChangePassEmail(e.target.value)} className="form-control" placeholder="Enter email" />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="row mt-3 resetpborder">
                                <div className="col-md-6">
                                    <label>Name</label>
                                    <span className="form-control spanIn">
                                        {doctorList && doctorList[changeIndex] ? doctorList[changeIndex]["firstName"] : ""}{" "}
                                        {doctorList && doctorList[changeIndex] ? doctorList[changeIndex]["lastName"] : ""}
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <label>Phone</label>
                                    <span className="form-control spanIn" style={{ height: "35px" }}>
                                        {doctorList && doctorList[changeIndex] ? doctorList[changeIndex]["phoneNo1"] : ""}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 resetpborder">
                                    <label>Reset Type</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>
                                                <label htmlFor="r_auto" className="cursor-pointer">
                                                    <input id="r_auto" type="radio" name="resetType" checked={resetType == "1" ? "checked" : ""} value="1" onChange={() => getNewPass("1")} /> Auto
                                                </label>
                                            </span>
                                        </div>
                                        <div className="col-md-6">
                                            <span>
                                                <label htmlFor="r_manual" className="cursor-pointer">
                                                    <input id="r_manual" type="radio" name="resetType" checked={resetType == "0" ? "checked" : ""} value="0" onChange={() => getNewPass("0")} /> Manual
                                                </label>
                                            </span>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <label>Password</label>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    value={newPass}
                                                    disabled={resetType == "1" ? true : false}
                                                    onChange={(e) => setNewPass(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                />
                                                <i className={passwordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={togglePassword}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label>Email Send</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>
                                                <label htmlFor="m_auto" className="cursor-pointer">
                                                    <input id="m_auto" type="radio" name="emailSend" checked={emailSend == "1" ? "checked" : ""} value="1" onChange={() => setEmailSend("1")} /> Yes
                                                </label>
                                            </span>
                                        </div>
                                        <div className="col-md-6">
                                            <span>
                                                <label htmlFor="m_manual" className="cursor-pointer">
                                                    <input id="m_manual" type="radio" name="emailSend" checked={emailSend == "0" ? "checked" : ""} value="0" onChange={() => setEmailSend("0")} /> No
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>
                <div className="d-flex mt-2 mb-4 justify-content-center mb-3">
                    <div className="center">
                        <Button className="mx-3 btn-danger btn-radius" variant="secondary" onClick={() => handleChangePassMdl(0)}>
                            Cancel
                        </Button>
                        <Button className="btn-success btn-radius" onClick={() => (changePassStep == 1 ? changePassFirstStep() : changePassCfm())}>
                            {changePassLoader ? <i className="spinner-border"></i> : ""}
                            {changePassStep == 1 ? "Verify" : "Confirm"}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default ManageDoctor;
