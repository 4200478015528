import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../Config";
import { ACTION, HOME, NAME, ROWS_PER_PAGE, ROLES_LIST, MANAGE_ROLES, NO, ROLE, DASH, ADD, STATUS, ROLE_STATUS, ALL, ROLES, DOCTOR_PAGES } from "../../Helper/Constants";

import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { PAGE_ADD_API, PAGE_DETAIL_API, ROLE_ADD_API, ROLE_DETAIL_API, ROLE_LIST_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import AddRoles from "../Forms/Roles/AddRoles";
import EditRolePages from "../Forms/Roles/EditRolePages";
import { DELETE_MESSAGE, ENTER_ROLE } from "../../Helper/Messages";
import _ from "lodash";
import Swal from "sweetalert2";

function ManageRoles() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roleList, setRoleList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [pageShow, setPageshow] = useState(false);
    const [parentId, setParentId] = useState("");
    const [id, setId] = useState("");

    const token = JSON.parse(localStorage.getItem("logToken"));

    const [filterValues, setfilterValues] = useState({
        search: "",
        statusId: "",
    });

    const [role, setRole] = useState({
        name: "",
        parentId: 5,
        statusId: 1,
    });
    const [roleId, setRoleId] = useState("");
    const [pages, setPages] = useState([
        { pageId: 1, isAuthority: 0, Name: "Dashboard" },
        { pageId: 2, isAuthority: 0, Name: "Doctor" },
        { pageId: 3, isAuthority: 0, Name: "Office" },
        { pageId: 4, isAuthority: 0, Name: "Patient" },
        { pageId: 5, isAuthority: 0, Name: "Employee" },
        { pageId: 14, isAuthority: 0, Name: "Office Employee" },
        { pageId: 6, isAuthority: 0, Name: "ChangeRequest" },
        { pageId: 7, isAuthority: 0, Name: "Practice" },
        { pageId: 8, isAuthority: 0, Name: "Trial" },
        { pageId: 9, isAuthority: 0, Name: "Claim" },
        { pageId: 12, isAuthority: 0, Name: "Notes" },
        { pageId: 13, isAuthority: 0, Name: "Support" },
        { pageId: 10, isAuthority: 0, Name: "EOB" },
        { pageId: 11, isAuthority: 0, Name: "Configuration" },
        { pageId: 21, isAuthority: 0, Name: "Receipt" },
    ]);

    const initPages = [
        { pageId: 1, isAuthority: 0, Name: "Dashboard" },
        { pageId: 2, isAuthority: 0, Name: "Doctor" },
        { pageId: 3, isAuthority: 0, Name: "Office" },
        { pageId: 4, isAuthority: 0, Name: "Patient" },
        { pageId: 5, isAuthority: 0, Name: "Employee" },
        { pageId: 14, isAuthority: 0, Name: "Office Employee" },
        { pageId: 6, isAuthority: 0, Name: "ChangeRequest" },
        { pageId: 7, isAuthority: 0, Name: "Practice" },
        { pageId: 8, isAuthority: 0, Name: "Trial" },
        { pageId: 9, isAuthority: 0, Name: "Claim" },
        { pageId: 12, isAuthority: 0, Name: "Notes" },
        { pageId: 13, isAuthority: 0, Name: "Support" },
        { pageId: 10, isAuthority: 0, Name: "EOB" },
        { pageId: 11, isAuthority: 0, Name: "Configuration" },
        { pageId: 21, isAuthority: 0, Name: "Receipt" },
    ];

    const [doctorPages, setDoctorPages] = useState([
        { pageId: 14, isAuthority: 0, Name: "Dashboard" },
        { pageId: 15, isAuthority: 0, Name: "OfficeEmployee" },
        { pageId: 16, isAuthority: 0, Name: "Patient" },
        { pageId: 17, isAuthority: 0, Name: "Claim" },
        { pageId: 18, isAuthority: 0, Name: "EOB" },
        { pageId: 19, isAuthority: 0, Name: "Support" },
        { pageId: 20, isAuthority: 0, Name: "Notes" },
        { pageId: 22, isAuthority: 0, Name: "Receipt" },
    ]);

    const initDoctorPages = [
        { pageId: 14, isAuthority: 0, Name: "Dashboard" },
        { pageId: 15, isAuthority: 0, Name: "OfficeEmployee" },
        { pageId: 16, isAuthority: 0, Name: "Patient" },
        { pageId: 17, isAuthority: 0, Name: "Claim" },
        { pageId: 18, isAuthority: 0, Name: "EOB" },
        { pageId: 19, isAuthority: 0, Name: "Support" },
        { pageId: 20, isAuthority: 0, Name: "Notes" },
        { pageId: 22, isAuthority: 0, Name: "Receipt" },
    ];

    const [title, setTitle] = useState(false);

    const handleclick = () => {
        setShow(true);
        setEdit(false);
    };

    const handleEdit = (roleId) => {
        setShow(true);
        setEdit(true);
        roleDetail(roleId);
        setRoleId(roleId);
    };
    const handleClose = () => {
        setShow(false);
        setEdit(false);
        setRole({
            name: "",
            parentId: 5,
            statusId: 1,
        });
    };
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ROLE_LIST_API}?isPagination=true`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterValues.search,
                statusId: filterValues.statusId,
            })
            .then((res) => {
                setIsLoading(false);
                setRoleList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const roleDetail = async (roleId) => {
        await axios
            .get(`${baseURL}/${ROLE_DETAIL_API}/${roleId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setRole(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleDelete = async (roleId) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ROLE_DETAIL_API}/${roleId}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        setRole({ ...role, [name]: value });
    };

    const handleRoleAdd = async (e) => {
        if (!role) {
            toast.error(ENTER_ROLE);
        } else {
            await axios
                .post(`${baseURL}/${ROLE_ADD_API}`, {
                    headers: { authorization: token.token },
                    name: role.name,
                    statusId: role.statusId,
                    parentId: role.parentId,
                    roleId: edit ? roleId : "",
                })
                .then((res) => {
                    handleClose();

                    toast.success(res.data.message);
                    fetchData();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    const handlePageModal = (roleId, roleName, parentId, id) => {
        setParentId(parentId);
        setPageshow(true);
        setRoleId(roleId);
        pageDetail(roleId, parentId);
        setTitle(roleName);
        setId(id);
    };

    const pageDetail = async (roleId, parentId) => {
        await axios
            .post(`${baseURL}/${PAGE_DETAIL_API}`, {
                headers: { authorization: token.token },
                roleId: roleId,
            })
            .then((res) => {
                if (res.data.data != undefined) {
                    if (parentId == ROLES.DOCTOR) {
                        var oldpagedetail = [...initDoctorPages];
                        for (var j = 0; j < res.data.data.length; j++) {
                            var pageId = res.data.data[j].pageId;
                            var isAuthority = res.data.data[j].isAuthority;
                            var filterddata = _.find(oldpagedetail, { pageId: pageId });
                            if (filterddata && isAuthority) {
                                var filterddata_index = oldpagedetail.findIndex((p) => p.pageId === pageId);
                                var newobj = {
                                    pageId: filterddata.pageId,
                                    isAuthority: isAuthority,
                                    Name: filterddata.Name,
                                };

                                oldpagedetail[filterddata_index] = newobj;
                            }
                        }
                        setDoctorPages([...oldpagedetail]);
                    } else {
                        var oldpagedetail = [...initPages];
                        for (var j = 0; j < res.data.data.length; j++) {
                            var pageId = res.data.data[j].pageId;
                            var isAuthority = res.data.data[j].isAuthority;
                            var filterddata = _.find(oldpagedetail, { pageId: pageId });
                            if (filterddata && isAuthority) {
                                var filterddata_index = oldpagedetail.findIndex((p) => p.pageId === pageId);
                                var newobj = {
                                    pageId: filterddata.pageId,
                                    isAuthority: isAuthority,
                                    Name: filterddata.Name,
                                };

                                oldpagedetail[filterddata_index] = newobj;
                            }
                        }
                        setPages([...oldpagedetail]);
                    }
                } else {
                    setPages([...initPages]);
                    setDoctorPages([...initDoctorPages]);
                }
            })
            .catch((err) => {
                setPages([...initPages]);
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleCheck = (e) => {
        const { checked } = e.target;
        setPages((value) => value?.map((data) => (data.pageId == e.target.value ? { ...data, isAuthority: checked ? 1 : 0 } : data)));
        setDoctorPages((value) => value?.map((data) => (data.pageId == e.target.value ? { ...data, isAuthority: checked ? 1 : 0 } : data)));
    };

    const handlePageAdd = async (e) => {
        await axios
            .post(`${baseURL}/${PAGE_ADD_API}`, {
                headers: { authorization: token.token },
                roleId: roleId,
                page: parentId == ROLES.DOCTOR ? doctorPages : pages,
            })
            .then((res) => {
                handlePageClose();
                setRole("");
                toast.success(res.data.message);
                fetchData();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClose = (e) => {
        setPageshow(false);
        setParentId("");
        setPages([
            { pageId: 1, isAuthority: 0, Name: "Dashboard" },
            { pageId: 2, isAuthority: 0, Name: "Doctor" },
            { pageId: 3, isAuthority: 0, Name: "Office" },
            { pageId: 4, isAuthority: 0, Name: "Patient" },
            { pageId: 5, isAuthority: 0, Name: "Employee" },
            { pageId: 14, isAuthority: 0, Name: "Office Employee" },
            { pageId: 6, isAuthority: 0, Name: "ChangeRequest" },
            { pageId: 7, isAuthority: 0, Name: "Practice" },
            { pageId: 8, isAuthority: 0, Name: "Trial" },
            { pageId: 9, isAuthority: 0, Name: "Claim" },
            { pageId: 12, isAuthority: 0, Name: "Notes" },
            { pageId: 13, isAuthority: 0, Name: "Support" },
            { pageId: 10, isAuthority: 0, Name: "EOB" },
            { pageId: 11, isAuthority: 0, Name: "Configuration" },
            { pageId: 21, isAuthority: 0, Name: "Receipt" },
        ]);
        setDoctorPages([
            { pageId: 14, isAuthority: 0, Name: "Dashboard" },
            { pageId: 15, isAuthority: 0, Name: "OfficeEmployee" },
            // { pageId: 16, isAuthority: 0, Name: "Patient" },
            { pageId: 17, isAuthority: 0, Name: "Claim" },
            { pageId: 18, isAuthority: 0, Name: "EOB" },
            { pageId: 19, isAuthority: 0, Name: "Support" },
            { pageId: 20, isAuthority: 0, Name: "Notes" },
            { pageId: 22, isAuthority: 0, Name: "Receipt" },
        ]);
    };

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_ROLES}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ROLES_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{MANAGE_ROLES}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleclick}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        <AddRoles
                                                                            role={role}
                                                                            edit={edit}
                                                                            show={show}
                                                                            handleClose={handleClose}
                                                                            handleChange={handleChange}
                                                                            handleRoleAdd={handleRoleAdd}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className="btn-group mx-3">
                                                                            <label className="mt-2 col-md-3">{NAME}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                name="search"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                value={filterValues.search}
                                                                                onChange={handleFilterValues}
                                                                            />
                                                                        </div>
                                                                        <div className="btn-group mx-5">
                                                                            <div className="btn-group mx-1">
                                                                                <label className="mt-2 col-md-5">{STATUS}</label>
                                                                                <select className="form-control col-md-3" name="statusId" value={filterValues.statusId} onChange={handleFilterValues}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(ROLE_STATUS).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={ROLE_STATUS[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{ROLE}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <EditRolePages
                                                                    id={id}
                                                                    parentId={parentId}
                                                                    title={title}
                                                                    pages={pages}
                                                                    doctorPages={doctorPages}
                                                                    pageShow={pageShow}
                                                                    handleCheck={handleCheck}
                                                                    handlePageAdd={handlePageAdd}
                                                                    handlePageClose={handlePageClose}
                                                                />
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!roleList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                roleList &&
                                                                                roleList.map((item, i) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={i}>
                                                                                            <td className="left">{(offset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                                                            <td className="left">{item.name ? item.name : DASH}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(ROLE_STATUS).find((key) => ROLE_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.parentId == null ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <button
                                                                                                            className="btn btn-warning btn-xs"
                                                                                                            onClick={() => handlePageModal(item.uniqueId, item.name, item.parentId, item.id)}
                                                                                                        >
                                                                                                            <i className="fa fa-plus mt-1"></i>
                                                                                                        </button>
                                                                                                        {item.id == 3 ? (
                                                                                                            ""
                                                                                                        ) : (
                                                                                                            <button
                                                                                                                className="btn btn-primary btn-xs"
                                                                                                                onClick={() => handleEdit(item.uniqueId, item.statusId)}
                                                                                                            >
                                                                                                                <i className="fa fa-pencil"></i>
                                                                                                            </button>
                                                                                                        )}

                                                                                                        {item.id == 3 ? (
                                                                                                            ""
                                                                                                        ) : (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-danger btn-xs"
                                                                                                                onClick={() => handleDelete(item.uniqueId)}
                                                                                                            >
                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {roleList == null ? "0" : rowCount} roles</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={roleList == null ? "0" : pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageRoles;
