import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import { baseURL } from "../../Config";
import {
    ADD,
    ADDRESS,
    BACK,
    BIRTH_DATE,
    CHOOSE_DOCTOR_TYPE,
    CITY,
    CONFIRM_PASSWORD,
    DEFAULT_PAYER,
    DOCTOR_INFORMATION,
    DOCTOR_LIST,
    DOCTOR_NAME,
    DOCTOR_SPECIALITY,
    EMAIL,
    FINISH,
    FIRST_NAME,
    GENDER,
    LAST_NAME,
    LICENSE_NUMBER,
    NAME,
    NEXT,
    NPI1,
    NPI2,
    OFFICE_INFORMATION,
    OFFICE_LIST,
    OFFICE_NAME,
    PASSWORD,
    PHONE_LENGTH,
    PHONE_NO,
    PTAN_NUMBER,
    ROLES,
    SELECT,
    SELECT_DOCTOR,
    SELECT_GENDER,
    SELECT_OFFICE,
    SELECT_PAYER,
    SELECT_SPECIALITY,
    SSN,
    STATE,
    TAXID,
    ZIP,
} from "../../Helper/Constants";
import {
    DOCTOR_OFFICE_ADD,
    DOCTOR_CREATE_ADD,
    DOCTOR_SELECT_ADD,
    PRACTICE_SHOW_LIST,
    FINAL_STEP,
    DOCTOR_REGISTER,
    OFFICE_ANOTHER_LIST,
    PRACTICE_LIST_API,
    STATE_LIST_WITHOUT_PAGINATION,
    CITY_LIST_WITHOUT_PAGINATION,
    REGISTER_OFFICE_DELETE,
    REGISTER_DOCTOR_DELETE,
    MANAGE_OFFICE_LIST_API,
    PAYER_LIST_WITHOUT_MIDDLEWARE_API,
} from "../../Helper/ApiPath";
import { dateAPIFormate, minYear, nowYear } from "../../Helper/Helper";
import {
    AGE_MORE_THAN_18,
    AT_LEAST_ONE_OFFICE,
    CHOOSE_OFFICE,
    DELETE_DOCTOR_MESSAGE,
    DELETE_OFFICE_MESSAGE,
    ENTER_ADDRESS,
    ENTER_CITY,
    ENTER_CONFIRMPASSWORD,
    ENTER_DOCTOR_DETAIL,
    ENTER_EMAIL,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI1,
    ENTER_NPI_LESS_THAN_10,
    ENTER_OFFICE_NAME,
    ENTER_PASSWORD,
    ENTER_PHONE,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALITY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_STATE,
    ENTER_TAX_ID_LESS_THAN_10,
    ENTER_ZIP,
    MAX_LENGTH,
    PASSWORD_DOES_NOT_MATCH,
    PASSWORD_ERROR_TEXT,
    PHONE_NUMBER,
} from "../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Recaptcha from "react-recaptcha";
import Swal from "sweetalert2";
import Select from "react-select";
import NoDataFound from "../CommonComponent/NoDataFound";
import Cookies from "universal-cookie";

/////////////////////////////////////////////////////////////////////////// general info //////////////////////////////////////////////////////////////////

const One = () => {
    const [error, setError] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [pwdError1, setPwdError1] = useState(false);
    const [pwdError2, setPwdError2] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const localStep = localStorage.getItem("step");
    const navigate = useNavigate();
    const rn = (Number(localStep) + 1).toString();
    const [step, setStep] = useState(rn);
    const MaxDoctor = 30;
    let doctorToken = JSON.parse(localStorage.getItem("DoctorToken"));
    const [city, setCity] = useState({
        officestate: "",
        officecity: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, officecity: e.value });
    };
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_OFFICE_LIST_API}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const [payer, setPayer] = useState([]);

    const [info1, setInfo1] = useState({
        email: "",
        password: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        profilePic: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        ptanNumber: "",
        gender: "",
        speciality: "",
        payer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });

    const onInputChanged = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setInfo1({ ...info1, [name]: onlyNumber });
        } else {
            setInfo1({ ...info1, [name]: value });
        }
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setInfo1({ ...info1, payerLabel: label, payer: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });

    const validate1 = async () => {
        const dob = info1.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();

        if (!info1.email) {
            toast.error(ENTER_EMAIL);
        } else if (!info1.password) {
            toast.error(ENTER_PASSWORD);
        } else if (!info1.confirmpassword) {
            toast.error(ENTER_CONFIRMPASSWORD);
        } else if (!info1.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!info1.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!info1.npi1) {
            toast.error(ENTER_NPI1);
        } else if (info1.npi1 && info1.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (info1.npi2 && info1.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (info1.ssn && info1.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (info1.taxId && info1.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (info1.ptanNumber && info1.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (!info1.speciality) {
            toast.error(ENTER_SPECIALITY);
        } else {
            setError("");
            const validEmail = new RegExp("^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$");
            const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

            if (!validEmail.test(info1.email)) {
                setEmailErr(true);
            } else if (!validPassword.test(info1.password)) {
                setPwdError1(true);
            } else if (!validPassword.test(info1.confirmpassword)) {
                setPwdError2(true);
            } else if (age_now < 18) {
                toast.error(AGE_MORE_THAN_18);
            } else if (info1.phoneNo1 && info1.phoneNo1.length > PHONE_LENGTH) {
                toast.error(PHONE_NUMBER);
            } else if (info1.password === info1.confirmpassword) {
                await axios
                    .post(`${baseURL}/${DOCTOR_REGISTER}`, {
                        email: info1.email,
                        password: info1.password,
                        firstName: info1.firstName,
                        middleName: info1.middleName,
                        lastName: info1.lastName,
                        phoneNo1: info1.phoneNo1,
                        dob: dateAPIFormate(info1.dob),
                        profilePic: "",
                        npi1: info1.npi1,
                        npi2: info1.npi2,
                        ssn: info1.ssn,
                        taxId: info1.taxId,
                        licenseNumber: info1.licenseNumber,
                        gender: info1.gender,
                        speciality: info1.speciality,
                        ptanNumber: info1.ptanNumber,
                        payerId: info1.payer,
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            setInfo1("");
                            toast.success(res.data.message);
                            const token = res.data.data.token;
                            doctorToken = token;
                            localStorage.setItem("DoctorToken", JSON.stringify(token));
                            setStep("2");
                            setPracticeShowList("");
                            officeListSel();
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            } else {
                toast.error("Password Not Matched...");
            }
        }
    };

    const backClick = async () => {
        fetchData();
        officeListSel();
        setStep("2");
    };

    const [allOfficeList, setAllOfficeList] = useState([]);
    const officeListSel = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_ANOTHER_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setAllOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        allOfficeList &&
        allOfficeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    // Step 2
    const [info2, setInfo2] = useState({
        officename: "",
        officephoneNo1: "",
        officeaddress: "",
        officezip: "",
    });

    const onInputChanged2 = (e) => {
        const { name, value } = e.target;
        setInfo2({ ...info2, [name]: value });
    };

    const validate2 = async () => {
        if (!info2.officename) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!info2.officephoneNo1) {
            toast.error(ENTER_PHONE);
        } else if (!info2.officeaddress) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.officecity) {
            toast.error(ENTER_CITY);
        } else if (!city.officestate) {
            toast.error(ENTER_STATE);
        } else if (!info2.officezip) {
            toast.error(ENTER_ZIP);
        } else if (info2.officephoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setError("");
            await axios
                .post(`${baseURL}/api/v1/doctor/office/add`, {
                    headers: { authorization: doctorToken },
                    name: info2.officename,
                    phoneNo1: info2.officephoneNo1,
                    address: info2.officeaddress,
                    state: city.officestate,
                    city: city.officecity,
                    zip: info2.officezip,
                })
                .then((res) => {
                    if (res.data.success == true) {
                        toast.success(res.data.message);
                        setAllOfficeList("");
                        setCity("");
                        setCityList("");
                        setInfo2("");
                        officeListSel();
                        fetchData();
                        officeListSel();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const validate3 = async () => {
        if (officeList == "" || officeList == null || officeList == undefined) {
            toast.error(AT_LEAST_ONE_OFFICE);
        } else {
            await axios
                .post(`${baseURL}/${FINAL_STEP}`, {
                    headers: { authorization: doctorToken },
                    step: 2,
                })
                .then((res) => {
                    fetchData();
                    setStep("3");
                    practiceOfficeApi();
                    practiceListApi();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [officeId, setOfficeId] = useState("");
    const onOfficeChanged2 = async (e) => {
        setOfficeId(e.value);
        if (e.value == 0) {
            setOfficeId("");
            document.getElementById("officeRow").style.display = "block";
        } else {
            document.getElementById("officeRow").style.display = "none";
        }
    };

    const addOffice = async (e) => {
        await axios
            .post(`${baseURL}/${DOCTOR_OFFICE_ADD}`, {
                headers: { authorization: doctorToken },
                officeId: officeId,
            })
            .then((res) => {
                if (res.status == 200) {
                    setAllOfficeList("");
                    setOfficeId("");
                    fetchData();
                    officeListSel();
                    document.getElementById("officeRow").style.display = "block";
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const btnBackOfficeAdd = () => {
        setOfficeId(0);
        document.getElementById("officeRow").style.display = "block";
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [conPasswordShown, setConPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConPassword = () => {
        setConPasswordShown(!conPasswordShown);
    };

    ////////////////////////////////////////////////////////////// doctor  information ////////////////////
    // const [payer, setPayer] = useState([]);
    const [practiceOffice, setPracticeOffice] = useState([]);
    const [officeLi, setOfficeLi] = useState("");
    const [doctorLi, setDoctorLi] = useState("");
    const [practiceShowList, setPracticeShowList] = useState([]);
    const [practiceList, setPracticeList] = useState([]);
    const [practiceTableList, setPracticeTableList] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        gender: "",
        speciality: "",
        ptanNumber: "",
        officeId: "",
        doctorPayer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });
    const doctorPayerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const tableList = async () => {
        await axios
            .post(`${baseURL}/${PRACTICE_SHOW_LIST}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeShowList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const onOfficeChanged3 = (e) => {
        // setOfficeLi(e.target.value);
        setOfficeLi(e.value);
    };

    const onDoctorChanged4 = async (e) => {
        // setDoctorLi(e.target.value);
        setDoctorLi(e.value);
    };

    const onParacticeChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setPracticeTableList({ ...practiceTableList, [name]: onlyNumber });
        } else {
            setPracticeTableList({ ...practiceTableList, [name]: value });
        }
    };

    const handleDoctorAdd = async (e) => {
        if (officeLi == 0 || officeLi == null || officeLi == "0" || officeLi == "") {
            toast.error(CHOOSE_OFFICE);
            return;
        }

        const dob = practiceTableList.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //     age_now--;
        // }

        if (!doctorLi && !practiceTableList.firstName && !practiceTableList.lastName && !practiceTableList.phoneNo1 && !practiceTableList.npi1 && !practiceTableList.speciality) {
            toast.error(ENTER_DOCTOR_DETAIL);
        } else if (doctorLi == 0 || doctorLi == null || doctorLi == "0") {
            if (!practiceTableList.firstName) {
                toast.error(ENTER_FNAME);
            } else if (!practiceTableList.lastName) {
                toast.error(ENTER_LNAME);
            } else if (!practiceTableList.npi1) {
                toast.error(ENTER_NPI1);
            } else if (practiceTableList.npi1 && practiceTableList.npi1.length > MAX_LENGTH) {
                toast.error(ENTER_NPI_LESS_THAN_10);
            } else if (practiceTableList.npi2 && practiceTableList.npi2.length > MAX_LENGTH) {
                toast.error(ENTER_NPI_LESS_THAN_10);
            } else if (practiceTableList.ssn && practiceTableList.ssn.length > MAX_LENGTH) {
                toast.error(ENTER_SSN_LESS_THAN_10);
            } else if (practiceTableList.taxId && practiceTableList.taxId.length > MAX_LENGTH) {
                toast.error(ENTER_TAX_ID_LESS_THAN_10);
            } else if (practiceTableList.ptanNumber && practiceTableList.ptanNumber.length > MAX_LENGTH) {
                toast.error(ENTER_PTAN_LESS_THAN_10);
            } else if (!practiceTableList.speciality) {
                toast.error(ENTER_SPECIALITY);
            } else if (practiceTableList.dob && age_now < 18) {
                toast.error(AGE_MORE_THAN_18);
            } else {
                await axios
                    .post(`${baseURL}/${DOCTOR_CREATE_ADD}`, {
                        headers: { authorization: doctorToken },
                        firstName: practiceTableList.firstName,
                        middleName: practiceTableList.middleName,
                        lastName: practiceTableList.lastName,
                        phoneNo1: practiceTableList.phoneNo1,
                        dob: dateAPIFormate(practiceTableList.dob),
                        profilePic: "",
                        npi1: practiceTableList.npi1,
                        npi2: practiceTableList.npi2,
                        ssn: practiceTableList.ssn,
                        taxId: practiceTableList.taxId,
                        licenseNumber: practiceTableList.licenseNumber,
                        gender: practiceTableList.gender,
                        speciality: practiceTableList.speciality,
                        ptanNumber: practiceTableList.ptanNumber,
                        payerId: practiceTableList.payer,
                        officeId: officeLi,
                        payerId: practiceTableList.payer,
                    })
                    .then((res) => {
                        if (res.data.success == true) {
                            toast.success(res.data.message);
                            setPracticeTableList("");
                            tableList();
                            setOfficeLi("");
                            setPracticeOffice("");
                            setPracticeList("");
                            practiceOfficeApi();
                            practiceListApi();
                        }
                    })
                    .catch((err) => {
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        } else {
            await axios
                .post(`${baseURL}/${DOCTOR_SELECT_ADD}`, {
                    headers: { authorization: doctorToken },
                    officeId: officeLi,
                    doctorId: doctorLi,
                })
                .then((res) => {
                    if (res.data.success == true) {
                        toast.success(res.data.message);
                        tableList();
                        setOfficeLi("");
                        setDoctorLi("");
                        setPracticeOffice("");
                        setPracticeList("");
                        practiceOfficeApi();
                        practiceListApi();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    let paracticeSelect = [
        {
            id: "",
            firstName: SELECT,
            lastName: "",
        },
    ];

    const practiceListApi = async () => {
        axios
            .post(`${baseURL}/${PRACTICE_LIST_API}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeList(res.data.data ? paracticeSelect.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const practiceListOptions =
        practiceList &&
        practiceList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
            };
        });
    const practiceOfficeApi = async () => {
        axios
            .post(`${baseURL}/${MANAGE_OFFICE_LIST_API}`, {
                headers: { authorization: doctorToken },
            })
            .then((res) => {
                setPracticeOffice(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const practiceOfficeOptions =
        practiceOffice &&
        practiceOffice.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const handleDoctorPayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setPracticeTableList({ ...practiceTableList, payerLabel: label, doctorPayer: value });
    };
    const [phoneError2, setPhoneError2] = useState(false);

    const finalRegister = () => {
        navigate("/login");
    };

    const finalRegistersubmit = async () => {
        await axios
            .post(`${baseURL}/${FINAL_STEP}`, {
                headers: { authorization: doctorToken },
                step: 3,
            })
            .then((res) => {
                navigate("/registrationDone");
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    useEffect(() => {
        if (rn == 2) {
            if (role == ROLES.DOCTOR) {
                fetchData();
                tableList();
            }
            officeListSel();
        } else if (rn == 3) {
            if (role == ROLES.DOCTOR) {
                fetchData();
                practiceOfficeApi();
            }
            tableList();
            practiceListApi();
        }
        payerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState("");

    const handleStateChange = (e) => {
        setCity({ ...city, officestate: e.value, officecity: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };
    const officestateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const officecityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const Officedelete = async (officeid) => {
        Swal.fire({
            title: DELETE_OFFICE_MESSAGE,
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${REGISTER_OFFICE_DELETE}`, {
                            headers: { authorization: doctorToken },
                            officedoctorid: officeid,
                        })
                        .then((res) => {
                            if (res.status == 200) {
                                Swal.fire(res.data.message, " ", "success");

                                setAllOfficeList("");
                                setOfficeId("");
                                fetchData();
                                officeListSel();
                                document.getElementById("officeRow").style.display = "block";
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const Doctordelete = async (officedoctorid) => {
        Swal.fire({
            title: DELETE_DOCTOR_MESSAGE,
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${REGISTER_DOCTOR_DELETE}`, {
                            headers: { authorization: doctorToken },
                            officedoctorid: officedoctorid,
                        })
                        .then((res) => {
                            if (res.status == 200) {
                                Swal.fire(res.data.message, " ", "success");

                                tableList();
                                setOfficeLi("");
                                setDoctorLi("");
                                setPracticeOffice("");
                                setPracticeList("");
                                practiceOfficeApi();
                                practiceListApi();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    // useEffect(() => {
    //     practiceOfficeApi()
    //     practiceListApi()
    //     // handle2()
    //     tableList()
    // }, [])

    return (
        <div>
            {step === "1" && (
                <div>
                    <span className="login100-form-logo">
                        <img alt="" src="assets/img/logo/login-logo.png" />
                    </span>
                    <span className="login100-form-title p-b-34 p-t-27">Basic Information</span>
                    <span style={{ color: "red" }}>{error}</span>
                    <div className="row">
                        <div className="form-group col-12">
                            <i className="fa fa-envelope font-left" aria-hidden="true"></i>
                            {EMAIL}
                            <span className="requireField"> * </span>
                            <input type="text" className="form-control" id="txtemail" name="email" placeholder="Enter email" onChange={onInputChanged} />
                            {emailErr && (
                                <p className="txtError" style={{ fontSize: "12px" }}>
                                    Your Email is like example@gmail.com
                                </p>
                            )}
                        </div>

                        <div className="form-group col-6 ">
                            <i className="fa fa-unlock font-left"> </i>
                            {PASSWORD}
                            <span className="requireField"> * </span>

                            <div style={{ display: "flex" }}>
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    name="password"
                                    placeholder="Password"
                                    onChange={onInputChanged}
                                />
                                <i className={passwordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={togglePassword}></i>
                            </div>
                            {pwdError1 === false ? <p style={{ fontSize: "10px", color: "white" }}>{PASSWORD_ERROR_TEXT}</p> : <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-lock font-left"></i>
                            {CONFIRM_PASSWORD}
                            <span className="requireField"> * </span>
                            <div style={{ display: "flex" }}>
                                <input
                                    type={conPasswordShown ? "text" : "password"}
                                    className="form-control"
                                    id="exampleInputPassword2"
                                    name="confirmpassword"
                                    placeholder="Confirm Password"
                                    onChange={onInputChanged}
                                />
                                <i className={conPasswordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={toggleConPassword}></i>
                            </div>
                            {pwdError2 && <p className="txtError">{PASSWORD_DOES_NOT_MATCH}</p>}
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-lock font-left"></i>
                            {FIRST_NAME}
                            <span className="requireField"> * </span>
                            <input type="text" className="form-control" id="exampleInputPassword2" name="firstName" placeholder="Enter First Name" onChange={onInputChanged} />
                        </div>

                        {/* <div className="form-group col-6">
                            <i className="fa fa-lock font-left"></i>
                            {MIDDLE_NAME}
                            <input type="text" className="form-control" id="exampleInputPassword2" name="middleName" placeholder="Enter Middle Name" onChange={onInputChanged} />
                        </div> */}

                        <div className="form-group col-6">
                            <i className="fa fa-lock font-left"></i>
                            {LAST_NAME}
                            <span className="requireField"> * </span>
                            <input type="text" className="form-control" id="exampleInputPassword2" name="lastName" placeholder="Enter Last Name" onChange={onInputChanged} />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-transgender-alt mx-1 font-left"></i>
                            {GENDER}
                            {/* <span className="requireField"> * </span> */}
                            <select name="gender" className="form-control" onChange={onInputChanged}>
                                <option value=""> {SELECT_GENDER} </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-calendar font-left"></i> {BIRTH_DATE}
                            {/* <span className="requireField"> * </span> */}
                            {/* <input type="date" className="form-control" name="dob" max={minYear} onChange={onInputChanged} /> */}
                            <DatePicker
                                className="form-control input-height mr-sm-2"
                                selected={info1.dob}
                                // max={minYear}
                                maxDate={new Date(minYear)}
                                // onChange={onInputChanged}
                                yearDropdownItemNumber={100}
                                scrollableMonthDropdown={true}
                                showYearDropdown
                                showMonthDropdown
                                placeholderText={"Please select a date"}
                                onChange={(date) => setInfo1({ ...info1, dob: date })}
                            />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-phone-square font-left"></i> {PHONE_NO}
                            {/* <span className="requireField"> * </span> */}
                            <input type="number" className="form-control" name="phoneNo1" placeholder="Enter Phone" onChange={onInputChanged} />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-sticky-note font-left"></i>
                            {NPI1}
                            <span className="requireField"> * </span>
                            <input type="text" className="form-control" name="npi1" placeholder="Enter NPI 1" onChange={onInputChanged} />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-sticky-note font-left"></i>
                            {NPI2}
                            {/* <span className="requireField"> * </span> */}
                            <input type="text" className="form-control" name="npi2" placeholder="Enter NPI 2" onChange={onInputChanged} />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-address-card font-left"></i>
                            {SSN}
                            {/* <span className="requireField"> * </span> */}
                            <input type="text" className="form-control" name="ssn" placeholder="Enter SSN" onChange={onInputChanged} />
                        </div>

                        <div className="form-group col-6">
                            <i className="fa fa-code-fork font-left"></i>
                            {TAXID}
                            {/* <span className="requireField"> * </span> */}
                            <input type="text" className="form-control" name="taxId" placeholder="Enter Tax ID" onChange={onInputChanged} />
                        </div>
                        <div className="form-group col-6">
                            <i class="fa fa-id-card-o  font-left" aria-hidden="true"></i>
                            {LICENSE_NUMBER}
                            {/* <span className="requireField"> * </span> */}
                            <input type="text" className="form-control" name="licenseNumber" placeholder="Enter License Number" onChange={onInputChanged} />
                        </div>
                        <div className="form-group col-6">
                            <i className="fa fa-space-shuttle font-left"></i>
                            {DOCTOR_SPECIALITY}
                            <span className="requireField"> * </span>
                            <select name="speciality" className="form-control" onChange={onInputChanged}>
                                <option value="">{SELECT_SPECIALITY} </option>
                                {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                    return (
                                        <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group col-6">
                            <i className="fa fa-id-badge font-left"></i>
                            {PTAN_NUMBER}
                            {/* <span className="requireField"> * </span> */}
                            <input type="text" className="form-control" name="ptanNumber" placeholder="Enter PTAN Number" onChange={onInputChanged} />
                        </div>
                        <div className="form-group col-6">
                            <i class="fa fa-credit-card font-left" aria-hidden="true"></i>

                            {DEFAULT_PAYER}
                            <span className="requireField"> * </span>
                            <Select
                                menuPlacement="top"
                                className="text-dark"
                                name="payer"
                                defaultValue={{ label: "CA Medicare South" }}
                                options={payerOptions}
                                onChange={handlePayerChange}
                                placeholder={SELECT_PAYER}
                            ></Select>
                        </div>
                    </div>

                    <div style={{ float: "right" }}>
                        <button className="btn login100-form-btn btn-circle" onClick={validate1}>
                            {NEXT}
                        </button>
                    </div>

                    <br />
                    <div className="text-center p-t-30">
                        <NavLink className="txt1" to="/login">
                            You already have a membership?
                        </NavLink>
                    </div>

                    <Recaptcha sitekey="6Lf9RssfAAAAAGgaNQSd6CL_MpDfa9rmgCxgHqMQ" size="invisible" />
                </div>
            )}

            {step === "2" && (
                <>
                    <span className="login100-form-logo">
                        <img alt="" src="assets/img/logo/login-logo.png" />
                    </span>
                    <span className="login100-form-title p-b-34 p-t-27">{OFFICE_INFORMATION}</span>

                    {/* {
                        step === "1"
                            ?
                            ""
                            :
                            <> */}

                    {/* ///////////// if length is more than 4 show this div //////////////// */}
                    {officeList && officeList.length >= MaxDoctor ? (
                        <>
                            <div id="officeRow">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card card-box">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body showHeader">You can add only {MaxDoctor} offices. So you can go to next step.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* ///////////// if length is more than 4 show this div else this div //////////////// */}

                            <div>
                                <label className="p-t-20 ">Select Office</label>
                                <div className="form-group col-12">
                                    {/* <select name="officeSelect" className="form-control" onChange={onOfficeChanged2}>
                                        <option className="text-center" value="">
                                            {" "}
                                            {SELECT_OFFICE}{" "}
                                        </option>
                                        {allOfficeList &&
                                            allOfficeList.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                    </select> */}

                                    <Select
                                        name="officeSelect"
                                        className="text-dark"
                                        value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeId)}
                                        options={officeSelectOptions}
                                        onChange={onOfficeChanged2}
                                        placeholder={SELECT_OFFICE}
                                    ></Select>

                                    {officeId == 0 ? (
                                        ""
                                    ) : (
                                        <div className="form-group mt-5" id="addBtn">
                                            <div className="text-center">
                                                <button className="btn btn-circle btn-info btn-register m-r-1" onClick={addOffice}>
                                                    {ADD}
                                                </button>

                                                <button className="btn btn-register btn-circle btn-github mx-3" onClick={btnBackOfficeAdd}>
                                                    {BACK}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div id="officeRow">
                                <div className="p-b-20 text-center">OR</div>
                                <div className="row">
                                    <div className="form-group col-6 ">
                                        <i className="fa fa-building font-left"></i>
                                        {OFFICE_NAME}
                                        <span className="requireField"> * </span>
                                        <input type="text" className="form-control" name="officename" value={info2 && info2.officename} placeholder="Enter Office Name" onChange={onInputChanged2} />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-phone-square font-left"></i> {PHONE_NO}
                                        <span className="requireField"> * </span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="officephoneNo1"
                                            value={info2 && info2.officephoneNo1}
                                            minLength={10}
                                            maxLength={10}
                                            placeholder="Enter Office Phone"
                                            onChange={onInputChanged2}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-address-card font-left"></i>
                                        {ADDRESS}
                                        <span className="requireField"> * </span>
                                        <input type="text" className="form-control" name="officeaddress" value={info2 && info2.officeaddress} placeholder="Enter Address" onChange={onInputChanged2} />
                                    </div>

                                    <div className="form-group col-6 ">
                                        <i className="fa fa-building font-left"></i>
                                        {STATE}
                                        <span className="requireField"> * </span>
                                        {/* <select name="officestate" value={info2 && info2.officestate} className="form-control" onChange={handleStateChange}>
                                            <option value="">{SELECT}</option>
                                            {stateList &&
                                                stateList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </select> */}
                                        <Select
                                            className="text-dark"
                                            name="officestate"
                                            value={officestateOptions && officestateOptions.filter(({ value }) => value == city.officestate)}
                                            options={officestateOptions}
                                            onChange={handleStateChange}
                                            placeholder={SELECT}
                                        />
                                    </div>
                                    <div className="form-group col-6 ">
                                        <i className="fa fa-building font-left"></i>
                                        {CITY}
                                        <span className="requireField"> * </span>

                                        {/* <select className="form-control" name="officecity" value={info2 && info2.city} onChange={onInputChanged2}>
                                            <option value=""> {SELECT}</option>
                                            {cityList &&
                                                cityList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </select> */}
                                        <Select
                                            className="text-dark"
                                            value={officecityOptions && officecityOptions.filter(({ value }) => value == city.officecity)}
                                            name="officecity"
                                            options={officecityOptions}
                                            onChange={handleCityChange}
                                            placeholder={SELECT}
                                        ></Select>
                                    </div>
                                    <div className="form-group col-6">
                                        <i className="fa fa-code-fork font-left"></i>
                                        {ZIP}
                                        <span className="requireField"> * </span>
                                        <input type="number" className="form-control" name="officezip" value={info2 && info2.officezip} placeholder="Enter Zip code" onChange={onInputChanged2} />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <button className="btn btn-info  btn-circle btn-register  m-r-1" onClick={validate2}>
                                                {ADD}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="row p-t-20 ">
                        <div className="col-md-12 col-sm-12">
                            <div className="card1">
                                <div className="card-head">
                                    <header>{OFFICE_LIST}</header>
                                </div>
                                <div className="">
                                    <div className="table-wrap">
                                        <div className="table-responsive">
                                            <table className="table display product-overview ">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th scope="col">{NAME}</th>
                                                        <th scope="col">{ADDRESS}</th>
                                                        <th scope="col">{CITY}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="text-center">
                                                    {officeList && officeList.length > 0 ? (
                                                        officeList.map((item) => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.address}</td>
                                                                    <td>{item.city}</td>
                                                                    <td>
                                                                        <a className="btn btn-danger btn-xs" onClick={() => Officedelete(item.OfficeDoctorId)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoDataFound />
                                                        // <tr>
                                                        //     <td colSpan={3}>
                                                        //         <h4 className="noRecordFound">No Data Found..</h4>
                                                        //     </td>
                                                        // </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ float: "right" }}>
                            <button className="btn login100-form-btn " onClick={validate3}>
                                {NEXT}
                            </button>
                        </div>
                    </div>

                    {/* </>
                    } */}

                    <Recaptcha sitekey="6Lf9RssfAAAAAGgaNQSd6CL_MpDfa9rmgCxgHqMQ" size="invisible" />
                </>
            )}

            {step === "3" && (
                <>
                    <span className="login100-form-logo">
                        <img alt="" src="assets/img/logo/login-logo.png" />
                    </span>
                    <span className="login100-form-title p-b-34 p-t-27">{DOCTOR_INFORMATION}</span>

                    {/* {
                        step === "2"
                            ?
                            ""
                            :
                            <> */}

                    {practiceShowList && practiceShowList.length >= MaxDoctor ? (
                        <>
                            <div id="officeRow">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="card card-box">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body showHeader">You can add only {MaxDoctor} Practice. So you can go to next step.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <label className="p-t-20 ">Select Office</label>
                                <div className="form-group col-12">
                                    {/* <select name="officeSelect" className="form-control" onChange={onOfficeChanged3}>
                                        <option className="text-center" value="">
                                            {" "}
                                            {SELECT_OFFICE}{" "}
                                        </option>
                                        {practiceOffice &&
                                            practiceOffice.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                    </select> */}

                                    <Select
                                        name="officeSelect"
                                        className="text-dark"
                                        value={practiceOfficeOptions && practiceOfficeOptions.filter(({ value }) => value == officeLi)}
                                        options={practiceOfficeOptions}
                                        onChange={onOfficeChanged3}
                                        placeholder={SELECT_OFFICE}
                                    ></Select>
                                </div>
                            </div>

                            <div>
                                <label className="p-t-20 ">Select Doctor</label>
                                <div className="form-group col-12">
                                    {/* <select name="officeSelect" className="form-control" onChange={onDoctorChanged4}>
                                        <option className="text-center" value="">
                                            {" "}
                                            {SELECT_DOCTOR}{" "}
                                        </option>
                                        {practiceList &&
                                            practiceList.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>
                                                        {item.firstName} {item.lastName}
                                                    </option>
                                                );
                                            })}
                                    </select> */}

                                    <Select
                                        name="officeSelect"
                                        className="text-dark"
                                        value={practiceListOptions && practiceListOptions.filter(({ value }) => value == doctorLi)}
                                        options={practiceListOptions}
                                        onChange={onDoctorChanged4}
                                        placeholder={SELECT_DOCTOR}
                                    ></Select>
                                </div>
                            </div>

                            <div id="officeRow">
                                <div className="p-b-20 text-center">OR</div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <i className="fa fa-user font-left"></i> {FIRST_NAME}
                                        <span className="requireField"> * </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            placeholder="Enter First Name"
                                            value={practiceTableList && practiceTableList.firstName}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    {/* <div className="form-group col-6">
                                        <i className="fa fa-user font-left"></i> {MIDDLE_NAME}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="middleName"
                                            placeholder="Enter Middle Name"
                                            value={practiceTableList && practiceTableList.middleName}
                                            onChange={onParacticeChange}
                                        />
                                    </div> */}

                                    <div className="form-group col-6">
                                        <i className="fa fa-user font-left"></i>
                                        {LAST_NAME}
                                        <span className="requireField"> * </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            placeholder="Enter Last Name"
                                            value={practiceTableList && practiceTableList.lastName}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-transgender-alt mx-1 font-left"></i>
                                        {GENDER}
                                        {/* <span className="requireField"> * </span> */}
                                        <select name="gender" className="form-control" value={practiceTableList && practiceTableList.gender} onChange={onParacticeChange}>
                                            <option value=""> {SELECT_GENDER} </option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-calendar font-left"></i> {BIRTH_DATE}
                                        {/* <span className="requireField"> * </span> */}
                                        {/* <input type="date" className="form-control" name="dob" max={minYear} value={practiceTableList && practiceTableList.dob} onChange={onParacticeChange} /> */}
                                        <DatePicker
                                            className="form-control input-height mr-sm-2"
                                            selected={practiceTableList.dob}
                                            maxDate={new Date(minYear)}
                                            // onChange={onInputChanged} yearDropdownItemNumber={100}
                                            scrollableMonthDropdown={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            placeholderText={"Please select a date"}
                                            onChange={(date) => setPracticeTableList({ ...practiceTableList, dob: date })}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-phone-square font-left"></i> {PHONE_NO}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="phoneNo1"
                                            minLength={10}
                                            maxLength={10}
                                            placeholder="Enter Phone"
                                            value={practiceTableList && practiceTableList.phoneNo1}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-sticky-note font-left"></i>
                                        {NPI1}
                                        <span className="requireField"> * </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="npi1"
                                            placeholder="Enter NPI 1"
                                            value={practiceTableList && practiceTableList.npi1}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-sticky-note font-left"></i>
                                        {NPI2}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="npi2"
                                            placeholder="Enter NPI 2"
                                            value={practiceTableList && practiceTableList.npi2}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-address-card font-left"></i>
                                        {SSN}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ssn"
                                            placeholder="Enter SSN"
                                            value={practiceTableList && practiceTableList.ssn}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-code-fork font-left"></i>
                                        {TAXID}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="taxId"
                                            placeholder="Enter Tax ID"
                                            value={practiceTableList && practiceTableList.taxId}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i class="fa fa-id-card-o font-left" aria-hidden="true"></i>
                                        {LICENSE_NUMBER}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="licenseNumber"
                                            placeholder="Enter License Number"
                                            value={practiceTableList && practiceTableList.licenseNumber}
                                            onChange={onParacticeChange}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <i className="fa fa-space-shuttle font-left"></i>
                                        {DOCTOR_SPECIALITY}
                                        <span className="requireField"> * </span>
                                        <select name="speciality" className="form-control" value={practiceTableList && practiceTableList.speciality} onChange={onParacticeChange}>
                                            <option value=""> {SELECT_SPECIALITY} </option>
                                            {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                return (
                                                    <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                        {item}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group col-6">
                                        <i class="fa fa-id-badge font-left" aria-hidden="true"></i>
                                        {PTAN_NUMBER}
                                        {/* <span className="requireField"> * </span> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ptanNumber"
                                            placeholder="Enter PTAN Number"
                                            value={practiceTableList && practiceTableList.ptanNumber}
                                            onChange={onParacticeChange}
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <i class="fa fa-credit-card font-left" aria-hidden="true"></i>

                                        {DEFAULT_PAYER}
                                        <span className="requireField"> * </span>
                                        <Select
                                            className="text-dark"
                                            name="doctorPayer"
                                            defaultValue={{ label: "CA Medicare South" }}
                                            options={doctorPayerOptions}
                                            onChange={handleDoctorPayerChange}
                                            placeholder={SELECT_PAYER}
                                        ></Select>
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <button className="btn btn-info btn-circle  btn-register m-r-1" style={{ marginRight: "15px" }} onClick={handleDoctorAdd}>
                                                {ADD}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="row p-t-20">
                        <div className="col-md-12 col-sm-12">
                            <div className="card1">
                                <div className="card-head">
                                    <header>{DOCTOR_LIST}</header>
                                </div>
                                <div className="">
                                    <div className="table-wrap">
                                        <div className="table-responsive">
                                            <table className="table display product-overview ">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th scope="col">{DOCTOR_NAME}</th>
                                                        <th scope="col">{OFFICE_NAME}</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {practiceShowList && practiceShowList.length > 0 ? (
                                                        practiceShowList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {item.firstName} {item.lastName}
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <a className="btn btn-danger btn-xs" onClick={() => Doctordelete(item.OfficeDoctorId)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoDataFound />
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div style={{ float: "left" }}>
                            <button className="btn btn-register btn-circle btn-github" onClick={backClick}>
                                {BACK}
                            </button>

                            {/* <button className="btn btn-register btn-circle btn-github" onClick= {() => setStep('2')}>Back</button> */}
                        </div>
                        <div>
                            <div style={{ float: "right" }}>
                                <button className="btn login100-form-btn" onClick={finalRegistersubmit}>
                                    {FINISH}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* </>
                    } */}
                    <Recaptcha sitekey="6Lf9RssfAAAAAGgaNQSd6CL_MpDfa9rmgCxgHqMQ" size="invisible" />
                </>
            )}
        </div>
    );
};

/////////////////////////////////////////////////////////////////////////// final stage //////////////////////////////////////////////////////////////////

function Register() {
    const RegFormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={RegFormSubmit}>
                            {/* <form className="login100-form validate-form"> */}
                            <div className="step1">
                                <One />
                            </div>
                            {/* <ToastContainer /> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
