import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import {
    CITY_LIST_WITHOUT_PAGINATION,
    DETAIL_OFFICE_EMPLOYEE_API,
    EDIT_DOCTOR_PROFILE,
    OFFICE_EMPLOYEE_EDIT_API,
    PAYER_LIST_WITHOUT_MIDDLEWARE_API,
    PROFILE_API,
    STATE_LIST_WITHOUT_PAGINATION,
} from "../../Helper/ApiPath";
import {
    ADDRESS,
    BIRTH_DATE,
    CANCEL,
    CHOOSE_DOCTOR_TYPE,
    CITY,
    DOCTOR_SPECIALITY,
    EDIT_PROFILE,
    EDIT_PROFILE_DETAILS,
    EDIT_PROFILE_INFORMATION,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    NPI1,
    NPI2,
    PAYER,
    PHONE_LENGTH,
    PHONE_NO,
    PTAN_NUMBER,
    ROLES,
    SELECT,
    SELECT_PAYER,
    SSN,
    STATE,
    TAXID,
    UPDATE,
} from "../../Helper/Constants";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../Helper/Helper";
import {
    DOCTOR_ID_NOT_FOUND,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI1,
    ENTER_NPI_LESS_THAN_10,
    ENTER_OFFICE_NAME,
    ENTER_PAYER,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALITY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_TAX_ID_LESS_THAN_10,
    MAX_LENGTH,
    PHONE_NUMBER,
} from "../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "universal-cookie";

function EditProfile() {
    const cookies = new Cookies();

    let doctorId = localStorage.getItem("doctorId");
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;
    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let adminRole = Number(localStorage.getItem("adminRole"));
    let navigate = useNavigate();

    const [profileInfo, setProfileInfo] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        gender: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        speciality: "",
        ptanNumber: "",
        fee: "",
        profilePic: "",
        payerLabel: "",
        payerId: "",
    });
    const [payer, setPayer] = useState([]);
    const [payerName, setPayerName] = useState("");
    const [payerValue, setPayerValue] = useState("");
    const [payerId, setPayerId] = useState("");
    const [payerChange, setPayerChange] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setProfileInfo({ ...profileInfo, [name]: onlyNumber });
        } else {
            setProfileInfo({ ...profileInfo, [name]: value });
        }
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${PROFILE_API}/${doctorId}`, {
                headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                setProfileInfo(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setPayerChange(true);
        setPayerValue(e.value);
        setPayerName(e.label);
        setPayerId(e.id);
        setProfileInfo({ ...profileInfo, payerLabel: label, payerId: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const handleUpdate = async () => {
        if (!doctorId) {
            toast.error(DOCTOR_ID_NOT_FOUND);
        } else if (!profileInfo.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!profileInfo.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!profileInfo.npi1 && isTrial == 0) {
            toast.error(ENTER_NPI1);
        } else if (profileInfo.npi1 && profileInfo.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (profileInfo.npi2 && profileInfo.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (profileInfo.ssn && profileInfo.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (profileInfo.taxId && profileInfo.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (profileInfo.ptanNumber && profileInfo.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (!profileInfo.speciality) {
            toast.error(ENTER_SPECIALITY);
        } else if (!profileInfo.payerId) {
            toast.error(ENTER_PAYER);
        } else if (profileInfo.phoneNo1 && profileInfo.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            await axios
                .post(`${baseURL}/${EDIT_DOCTOR_PROFILE}`, {
                    headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
                    firstName: profileInfo.firstName,
                    middleName: profileInfo.middleName,
                    lastName: profileInfo.lastName,
                    phoneNo1: profileInfo.phoneNo1,
                    dob: dateAPIFormate(profileInfo.dob),
                    npi1: profileInfo.npi1,
                    npi2: profileInfo.npi2,
                    ssn: profileInfo.ssn,
                    taxId: profileInfo.taxId,
                    licenseNumber: profileInfo.licenseNumber,
                    gender: profileInfo.gender,
                    speciality: profileInfo.speciality,
                    ptanNumber: profileInfo.ptanNumber,
                    doctorId: doctorId,
                    payerId: profileInfo.payerId,
                })
                .then((res) => {
                    navigate("/Profile");
                    if (res.status === 200) {
                        window.location.reload();
                        toast.success(res.data.message);

                        if (payerChange) {
                            localStorage.setItem("payerName", payerName);
                            localStorage.setItem("payerValue", payerValue);
                            localStorage.setItem("payerId", payerId);
                        }
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/Profile");
    };

    const [employee, setEmployee] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        address1: "",
        pageAuthorityRoleId: "",
        roleId: "",
        statusId: "",
        agreement: "",
    });
    let officeId = localStorage.getItem("officeId");

    const handleEmployeeChange = (e) => {
        const { name, value } = e.target;
        setEmployee({ ...employee, [name]: value });
    };

    const fetchEmployeeData = async () => {
        await axios
            .get(`${baseURL}/${DETAIL_OFFICE_EMPLOYEE_API}/${doctorId}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                if (res.status === 200) {
                    setEmployee(res.data.data);
                    setStateId(res.data.data.stateId);
                    setCityId(res.data.data.cityId);
                    if (res.data.data.stateId) {
                        cityData(res.data.data.stateId);
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!officeId) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!employee.firstName) {
            toast.error(ENTER_FNAME);
        } else {
            await axios
                .post(`${baseURL}/${OFFICE_EMPLOYEE_EDIT_API}`, {
                    headers: { authorization: logToken },
                    doctorId: doctorId,
                    officeId: officeId,
                    roleId: employee.roleId,
                    pageAuthorityRoleId: employee.pageAuthorityRoleId,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    address1: employee.address1,
                    state: stateId,
                    city: cityId,
                    statusId: employee.statusId,
                    isAdmin: (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN) && (adminRole == ROLES.ADMIN || adminRole == ROLES.EMPLOYEE_ADMIN) ? true : false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        navigate("/Profile");
                        window.location.reload();
                        setEmployee("");
                        toast.success(res.data.message);
                        navigate("/manageOfficeEmployee");
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [stateList, setStateList] = useState([]);
    const [stateId, setStateId] = useState("");

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });
    const handleStateChange = (e) => {
        setStateId(e.value);
        cityData(e.value);
    };

    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState("");

    const cityData = async (stateId) => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };
    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const handleCityChange = (e) => {
        setCityId(e.value);
    };

    useEffect(() => {
        if (isTrial == 2) {
            fetchEmployeeData();
            stateData();
        } else {
            fetchData();

            payerList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className="pull-left">
                                <div className="page-title">{EDIT_PROFILE_INFORMATION}</div>
                            </div>
                            {isTrial == 0 ? (
                                <ol className="breadcrumb page-breadcrumb pull-right">
                                    <li>
                                        <i className="fa fa-home"></i>&nbsp;
                                        <NavLink className="parent-item" to="/dashboard">
                                            {HOME}
                                        </NavLink>
                                        &nbsp;<i className="fa fa-angle-right"></i>
                                    </li>
                                    <li className="active">{EDIT_PROFILE}</li>
                                </ol>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EDIT_PROFILE_DETAILS}</header>
                                </div>
                                {isTrial == 2 ? (
                                    <div className="card-body" id="bar-parent">
                                        <div action="#" id="form_sample_1" className="form-horizontal">
                                            <div className="form-body">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {FIRST_NAME}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            value={employee && employee.firstName}
                                                            onChange={handleEmployeeChange}
                                                            data-required="1"
                                                            placeholder="Enter First Name"
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{LAST_NAME}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            value={employee && employee.lastName}
                                                            onChange={handleEmployeeChange}
                                                            placeholder="Enter Last Name"
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{ADDRESS}</label>
                                                    <div className="col-md-5">
                                                        <textarea
                                                            name="address1"
                                                            value={employee && employee.address1}
                                                            onChange={handleEmployeeChange}
                                                            placeholder="Address"
                                                            className="form-control form-control-textarea"
                                                            rows="3"
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{STATE}</label>
                                                    <div className="col-md-5">
                                                        {stateId ? (
                                                            <Select
                                                                name="stateId"
                                                                value={stateOptions && stateOptions.filter(({ value }) => value == stateId)}
                                                                options={stateOptions}
                                                                onChange={handleStateChange}
                                                                placeholder={SELECT}
                                                            />
                                                        ) : (
                                                            <Select name="stateId" options={stateOptions} onChange={handleStateChange} placeholder={SELECT} />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{CITY}</label>
                                                    <div className="col-md-5">
                                                        <Select
                                                            name="cityId"
                                                            value={cityOptions && cityOptions.filter(({ value }) => value == cityId)}
                                                            options={cityOptions}
                                                            onChange={handleCityChange}
                                                            placeholder={SELECT}
                                                        ></Select>
                                                    </div>
                                                </div>

                                                <div className="form-actions">
                                                    <div className="row">
                                                        <div className="offset-md-3 col-md-9">
                                                            <button type="submit" className="btn btn-info m-r-20" onClick={handleEditSubmit}>
                                                                {UPDATE}
                                                            </button>

                                                            <button type="button" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                {CANCEL}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body" id="bar-parent">
                                        <form action="#" id="form_sample_1" className="form-horizontal">
                                            <div className="form-body">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {FIRST_NAME}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            placeholder=" Enter first name"
                                                            value={profileInfo.firstName}
                                                            onChange={handleChange}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {LAST_NAME}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            placeholder=" Enter last name"
                                                            value={profileInfo.lastName}
                                                            onChange={handleChange}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                    <div className="col-md-5">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            value={dateFormateMonthDateYear(profileInfo && profileInfo.dob)}
                                                            placeholderText={"Please Select Date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            onChange={(date) => setProfileInfo({ ...profileInfo, dob: date })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{GENDER}</label>
                                                    <div className="col-md-5">
                                                        <select className="form-control input-height" name="gender" value={profileInfo.gender} onChange={handleChange}>
                                                            <option value=""> {SELECT}</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{PHONE_NO}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="phoneNo1"
                                                            type="number"
                                                            placeholder="mobile number"
                                                            onChange={handleChange}
                                                            value={profileInfo.phoneNo1}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {NPI1}
                                                        {isTrial == 0 ? <span className="required"> * </span> : ""}
                                                    </label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="npi1"
                                                            type="text"
                                                            placeholder="NPI number"
                                                            onChange={handleChange}
                                                            value={profileInfo.npi1}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{NPI2}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="npi2"
                                                            type="text"
                                                            placeholder="NPI number"
                                                            onChange={handleChange}
                                                            value={profileInfo.npi2}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{SSN}</label>
                                                    <div className="col-md-5">
                                                        <input name="ssn" type="text" placeholder="SSN number" onChange={handleChange} value={profileInfo.ssn} className="form-control input-height" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{TAXID}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="taxId"
                                                            type="text"
                                                            placeholder="TaxId number"
                                                            onChange={handleChange}
                                                            value={profileInfo.taxId}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="licenseNumber"
                                                            type="text"
                                                            placeholder="License Number number"
                                                            onChange={handleChange}
                                                            value={profileInfo.licenseNumber}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                    <div className="col-md-5">
                                                        <input
                                                            name="ptanNumber"
                                                            type="text"
                                                            placeholder="License PTAN number"
                                                            onChange={handleChange}
                                                            value={profileInfo.ptanNumber}
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {DOCTOR_SPECIALITY}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <select className="form-control input-height" name="speciality" onChange={handleChange} value={profileInfo.speciality}>
                                                            {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {PAYER}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <Select
                                                            menuPlacement="top"
                                                            className="text-dark"
                                                            name="payerId"
                                                            value={payerOptions && payerOptions.filter(({ id }) => id === profileInfo.payerId)}
                                                            options={payerOptions}
                                                            onChange={handlePayerChange}
                                                            placeholder={SELECT_PAYER}
                                                        ></Select>
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mb-0 d-flex justify-content-center">
                                                    <div className="col-1 m-r-20 my-3">
                                                        <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                                                            {UPDATE}
                                                        </button>
                                                    </div>
                                                    <div className="col-1  m-r-20 my-3">
                                                        <button type="button" className="btn btn-default" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfile;
