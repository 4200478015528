import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import DashboardDoctor from "./components/Dashboard/DashboardDoctor";
import ProtectedComponent from "./components/Auth/ProtectedComponent";
import Lay from "./components/Auth/Lay";
import ManageDoctor from "./components/AdminPanel/ManageDoctor";
import ManageOffice from "./components/AdminPanel/ManageOffice";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import AddDoctor from "./components/Forms/AddDoctor";
import AddEmployee from "./components/Forms/Employee/AddEmployee";
import EditEmployee from "./components/Forms/Employee/EditEmployee";
import ManageEmployee from "./components/AdminPanel/ManageEmployee";
import DoctorProfilePage from "./components/ProfilePage/DoctorProfilePage";
import ResetPassword from "./components/Forms/ResetPassword";
import ConfirmPassword from "./components/Forms/ConfirmPassword";
import ErrorPage from "./components/CommonComponent/ErrorPage";
import AddPatient from "./components/Forms/AddPatient";
import ManagePatient from "./components/DoctorPanel/ManagePatient";
import ChooseOfficeDoctor from "./components/Forms/ChooseOfficeDoctor";
import CongratulationPage from "./components/Auth/CongratulationPage";
import AdminProfilePage from "./components/ProfilePage/AdminProfilePage";
import EditProfile from "./components/ProfilePage/EditProfile";
import AddEligibilityCheck from "./components/Forms/AddEligibilityCheck";
import ManageClaim from "./components/DoctorPanel/ManageClaim";
import DetailClaim from "./components/Forms/Claim/DetailClaim";
import AddClaim from "./components/Forms/Claim/AddClaim";
import ChangePassword from "./components/Auth/ChangePassword";
import EditOfficeDetail from "./components/ProfilePage/EditOfficeDetail";
import EditEmployeeProfile from "./components/ProfilePage/EditEmployeeProfile";
import AdminChangePassword from "./components/Auth/AdminChangePassword";
import ManualResetPassword from "./components/Auth/ManualResetPassword";
import AdminEditDoctor from "./components/Forms/AdminEditDoctor";
import DoctorManageOffice from "./components/DoctorPanel/DoctorManageOffice";
import DoctorOfficeAdd from "./components/Forms/DoctorOfficeAdd";
import DoctorAssignDoctor from "./components/Forms/DoctorAssignDoctor";
import { DOCTOR_PAGES, PAGES, ROLES } from "./Helper/Constants";
import ManagePractice from "./components/AdminPanel/ManagePractice";
import Configuration from "./components/AdminPanel/Configuration";
import DoctorEditOffice from "./components/Forms/DoctorEditOffice";
import DoctorEditDoctor from "./components/Forms/DoctorEditDoctor";
import DoctorAddDoctor from "./components/Forms/DoctorAddDoctor";
import AdminAddDoctor from "./components/Forms/AdminAddDoctor";
import AdminAssignDoctor from "./components/Forms/AdminAssignDoctor";
import AdminEditOffice from "./components/Forms/AdminEditOffice";
import AdminAddOffice from "./components/Forms/AdminAddOffice";
import PasswordVerification from "./components/Auth/PasswordVerification";
import RequestData from "./components/AdminPanel/RequestData";
import ManageDiagnoses from "./components/AdminPanel/ManageDiagnoses";
import ManageProcedure from "./components/AdminPanel/ManageProcedure";
import ManagePayer from "./components/AdminPanel/ManagePayer";
import AdminManageClaim from "./components/AdminPanel/ManageClaim";
import PendingDoctorDetail from "./components/Forms/pendingDoctor/pendingDoctorDetail";
import ViewOffice from "./components/AdminPanel/ViewOffice";
import EmployeeProfilePage from "./components/ProfilePage/EmployeeProfilePage";
import AdminAssignedDoctorEdit from "./components/Forms/AdminAssignedDoctorEdit";
import { ToastContainer } from "react-toastify";
import EligibiltyCheck from "./components/AdminPanel/EligibiltyCheck";
import EditClaim from "./components/Forms/Claim/EditClaim";
import ManageNotice from "./components/AdminPanel/ManageNotice";
import AddNotice from "./components/Forms/Notice/AddNotice";
import Cookies from "universal-cookie";
import ManageTrial from "./components/AdminPanel/ManageTrial";
import AddTrial from "./components/Forms/Trial/AddTrial";
import ManageRoles from "./components/AdminPanel/ManageRoles";
import { showHomePage } from "./Config";
import Iframe from "./components/Auth/Iframe";
import AdminManageOpNotes from "./components/AdminPanel/ManageOpNotes";
import LoadingPage from "./components/CommonComponent/LoadingPage";
import OpNotes from "./components/Forms/OpNotes/OpNotes";
import EditOpNote from "./components/Forms/OpNotes/EditOpNote";
import ManageEob from "./components/AdminPanel/ManageEob";
import DoctorManageEOB from "./components/DoctorPanel/DoctorManageEOB";
import AddEob from "./components/Forms/EOB/AddEob";
import EditEob from "./components/Forms/EOB/EditEOB";
import ManageReceipt from "./components/AdminPanel/ManageReceipt";
import DoctorManageReceipt from "./components/DoctorPanel/DoctorManageReceipt";
import EditReceipt from "./components/Forms/Receipt/EditReceipt";
import AddReceipt from "./components/Forms/Receipt/AddReceipt";
import DoctorManageOpNotes from "./components/DoctorPanel/DoctorManageOpNotes";
import PatientChartTabs from "./components/Forms/Patients/PatientChart/PatientChartTabs";
import PatientTabs from "./components/Forms/Patients/PatientdetailChart/PatientTabs";
import ManagePatientTabs from "./components/AdminPanel/PatientChart/ManagePatientTabs";
import ManageQuestions from "./components/AdminPanel/PatientChart/ManageQuestions";
import ManageTabgroup from "./components/AdminPanel/PatientChart/ManageTabgroup";
import ManageComprehensive from "./components/DoctorPanel/ManageComprehensive";
import AddComprehensive from "./components/Forms/ComprehensiveNotes/AddComprehensive";
import EditComprehensive from "./components/Forms/ComprehensiveNotes/EditComprehensive";
import AdminAddComprehensive from "./components/Forms/ComprehensiveNotes/AdminAddComprehensive";
import ManageJsonGroup from "./components/Forms/ComprehensiveNotes/Jsondata/ManageJsonGroup";
import ManageJsonQuestion from "./components/Forms/ComprehensiveNotes/Jsondata/ManageJsonQuestion";
import ManageDocuments from "./components/AdminPanel/ManageDocuments";
import AddDocuments from "./components/Forms/Documents/AddDocuments";
import EditDocuments from "./components/Forms/Documents/EditDocuments";
import EditTrial from "./components/Forms/Trial/EditTrial";
import VerifyDocument from "./components/Auth/VerifyDocument";
import ManagePreAuthorization from "./components/DoctorPanel/ManagePreAuthorization";
import AddPreAuthorization from "./components/Forms/PreAuthorization/AddPreAuthorization";
import AdminAddPreAuthorizarion from "./components/Forms/PreAuthorization/AdminAddPreAuthorizarion";
import AddOfficeEmployee from "./components/Forms/OfficeEmployee/AddOfficeEmployee";
import ManageOfficeEmployee from "./components/Forms/OfficeEmployee/ManageOfficeEmployee";
import ViewOfficeEmployee from "./components/Forms/OfficeEmployee/ViewOfficeEmployee";

function App() {
    const cookies = new Cookies();
    const patientId = localStorage.getItem("patientId");

    let pageAccess = cookies.get("pageAccess") ? (cookies.get("pageAccess").length ? cookies.get("pageAccess") : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]) : "";
    let doctorPageAccess = cookies.get("doctorPageAccess") ? (cookies.get("doctorPageAccess").length ? cookies.get("doctorPageAccess") : [14, 15, 16, 17, 18, 19, 20]) : "";
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    let adminRole = localStorage.getItem("adminRole");

    return (
        <BrowserRouter>
            <ToastContainer />
            <Routes>
                <Route path="/" element={showHomePage ? <Iframe /> : <Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify" element={<VerifyDocument />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/setPassword" element={<ConfirmPassword />} />
                <Route path="/chooseDoctor" element={<ChooseOfficeDoctor />} />
                <Route path="/registrationDone" element={<CongratulationPage />} />
                <Route path="/password-verification/:link" element={<PasswordVerification />} />

                <Route path="/*" element={<LoadingPage />} />
                <Route element={<ProtectedComponent />}>
                    <Route element={<Lay />}>
                        {/* DASHBOARD */}
                        <Route
                            exact
                            path="/dashboard"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Dashboard)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AdminDashboard />
                                ) : ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Dashboard)) || isTrial == 0 ? (
                                    <DashboardDoctor />
                                ) : (
                                    <LoadingPage />
                                )
                            }
                        />
                        {/* PROFILE */}
                        <Route
                            exact
                            path="/Profile"
                            element={
                                role == ROLES.ADMIN && adminRole != ROLES.DOCTOR && adminRole != ROLES.DOCTOR ? (
                                    <AdminProfilePage />
                                ) : role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                    <DoctorProfilePage />
                                ) : (role == ROLES.EMPLOYEE_ADMIN || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EmployeeProfilePage />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editProfile"
                            element={
                                (role == ROLES.EMPLOYEE_ADMIN || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditEmployeeProfile />
                                ) : role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                    <EditProfile />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* MANAGE DOCTOR */}
                        <Route
                            exact
                            path="/manageDoctor"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Doctor))) && adminRole != ROLES.DOCTOR ? <ManageDoctor /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/registerDoctor"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && (pageAccess.includes(PAGES.Doctor) || pageAccess.includes(PAGES.Practice)))) && adminRole != ROLES.DOCTOR ? (
                                    <AddDoctor />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/addDoctor"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAddDoctor />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorAddDoctor />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/assignDoctor"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAssignDoctor />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorAssignDoctor />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editDoctor"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Doctor))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminEditDoctor />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorEditDoctor />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editAssignedDoctor"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAssignedDoctorEdit />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorEditDoctor />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* MANAGE OFFICE */}
                        <Route
                            exact
                            path="/ViewOffice"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? <ViewOffice /> : <ErrorPage />}
                        />
                        <Route exact path="/editOfficeDetail" element={role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR || isTrial == 0 ? <EditOfficeDetail /> : <ErrorPage />} />
                        <Route
                            exact
                            path="/manageOffice"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <ManageOffice />
                                ) : role == ROLES.DOCTOR && isTrial == 0 ? (
                                    <DoctorManageOffice />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/addOffice"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAddOffice />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorOfficeAdd />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editOffice"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Office))) && adminRole != ROLES.DOCTOR ? (
                                    <AdminEditOffice />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <DoctorEditOffice />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* MANAGE PATIENT */}
                        <Route
                            exact
                            path="/patientChart"
                            element={
                                patientId && (role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) || adminRole == ROLES.DOCTOR) ? (
                                    <PatientChartTabs />
                                ) : (
                                    <LoadingPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/managePatient"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Patient)) ||
                                role == ROLES.ADMIN ||
                                (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) ? (
                                    <ManagePatient />
                                ) : (
                                    <LoadingPage />
                                )
                            }
                        />
                        {/* <Route exact path="/managePatient" element={role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? <ManagePatient /> : <LoadingPage />} /> */}
                        <Route exact path="/addPatient" element={role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? <AddPatient /> : <ErrorPage />} />
                        <Route
                            exact
                            path="/patientProfile"
                            element={
                                role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) || adminRole == ROLES.DOCTOR ? (
                                    <PatientTabs />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* <Route exact path="/editPatient" element={role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? <EditPatient /> : <ErrorPage />} /> */}
                        <Route
                            exact
                            path="/editPatient"
                            element={
                                role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) || adminRole == ROLES.DOCTOR ? (
                                    <PatientChartTabs />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/checkPatientEligibility"
                            element={
                                role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) || adminRole == ROLES.DOCTOR ? (
                                    <AddEligibilityCheck />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/eligiblePatient"
                            element={
                                role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Patient)) || adminRole == ROLES.DOCTOR ? (
                                    <EligibiltyCheck />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* MANAGE EMPLOYEE */}
                        <Route
                            exact
                            path="/manageEmployee"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Employee))) && adminRole != ROLES.DOCTOR ? <ManageEmployee /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/addEmployee"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Employee))) && adminRole != ROLES.DOCTOR ? <AddEmployee /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/editEmployee"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Employee))) && adminRole != ROLES.DOCTOR ? <EditEmployee /> : <ErrorPage />}
                        />

                        {/* MANAGE OFFICE EMPLOYEE */}
                        <Route
                            exact
                            path="/addOfficeEmployee"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.OfficeEmployee))) && adminRole != ROLES.DOCTOR ? (
                                    <AddOfficeEmployee />
                                ) : ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.OfficeEmployee)) || isTrial == 0 ? (
                                    <AddOfficeEmployee />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/manageOfficeEmployee"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.OfficeEmployee))) && adminRole != ROLES.DOCTOR ? (
                                    <ManageOfficeEmployee />
                                ) : ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.OfficeEmployee)) || isTrial == 0 ? (
                                    <ManageOfficeEmployee />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/viewOfficeEmployee"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Dashboard))) && adminRole != ROLES.DOCTOR ? <ViewOfficeEmployee /> : <ErrorPage />
                            }
                        />

                        {/* MANAGE PENDINGDATA */}
                        <Route exact path="/viewPendingDoctor" element={(role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN) && adminRole != ROLES.DOCTOR ? <PendingDoctorDetail /> : <ErrorPage />} />
                        <Route
                            exact
                            path="/requestData"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.ChangeRequest))) && adminRole != ROLES.DOCTOR ? <RequestData /> : <ErrorPage />}
                        />

                        {/* MANAGE PRACTICE */}
                        <Route
                            exact
                            path="/managePractice"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Practice)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManagePractice />
                                ) : role !== ROLES.ADMIN && role !== ROLES.EMPLOYEE_ADMIN && role !== ROLES.EMPLOYEE && adminRole !== ROLES.DOCTOR ? (
                                    <ErrorPage />
                                ) : (
                                    <LoadingPage />
                                )
                            }
                        />

                        {/* MANAGE TRIAL */}
                        <Route
                            exact
                            path="/manageTrial"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Trial))) && adminRole != ROLES.DOCTOR ? (
                                    <ManageTrial />
                                ) : role !== ROLES.ADMIN && role !== ROLES.EMPLOYEE_ADMIN && adminRole !== ROLES.DOCTOR ? (
                                    <ErrorPage />
                                ) : (
                                    <LoadingPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/addTrial"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Trial))) && adminRole != ROLES.DOCTOR ? <AddTrial /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/editTrial"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Trial))) && adminRole != ROLES.DOCTOR ? <EditTrial /> : <ErrorPage />}
                        />
                        {/* MANAGE CLAIM */}
                        <Route
                            exact
                            path="/detailClaim"
                            element={
                                role == ROLES.DOCTOR || role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Claim)) || role == ROLES.EMPLOYEE ? (
                                    <DetailClaim />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/addClaim"
                            element={((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Claim)) || isTrial == 0 ? <AddClaim /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/manageClaim"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Claim)) || isTrial == 0 ? (
                                    <ManageClaim />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Claim)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AdminManageClaim />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editClaim"
                            element={
                                role == ROLES.DOCTOR ||
                                role == ROLES.ADMIN ||
                                (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Claim)) ||
                                role == ROLES.EMPLOYEE ||
                                adminRole == ROLES.DOCTOR ? (
                                    <EditClaim />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* OP NOTES */}
                        <Route
                            exact
                            path="/OpNotes"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <OpNotes />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <OpNotes />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editOpNotes"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <EditOpNote />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditOpNote />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/manageOpNotes"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <DoctorManageOpNotes />
                                ) : role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || (role == ROLES.EMPLOYEE && adminRole != ROLES.DOCTOR) ? (
                                    <AdminManageOpNotes />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* COMPREHENSIVE */}
                        <Route
                            exact
                            path="/manageJsonGroup"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <ManageJsonGroup />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageJsonGroup />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/ManageJsonQuestion"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <ManageJsonQuestion />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageJsonQuestion />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/ManageComprehensive"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <ManageComprehensive />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageComprehensive />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/AddComprehensive"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <AddComprehensive />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAddComprehensive />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/EditComprehensive"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <EditComprehensive />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditComprehensive />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* COMPREHENSIVE */}

                        <Route
                            exact
                            path="/manage-preAuthorization-notes"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <ManagePreAuthorization />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManagePreAuthorization />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/add-preAuthorization-note"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                    <AddPreAuthorization />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Notes)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AdminAddPreAuthorizarion />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* EOB */}
                        <Route
                            exact
                            path="/addEOB"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.EOB)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AddEob />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editEOB"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.EOB)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditEob />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/manageEOB"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.EOB)) || isTrial == 0 ? (
                                    <DoctorManageEOB />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.EOB)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageEob />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* Receipt */}
                        <Route
                            exact
                            path="/manageReceipt"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Receipt)) || isTrial == 0 ? (
                                    <DoctorManageReceipt />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Receipt)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageReceipt />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/addReceipt"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Receipt)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AddReceipt />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editReceipt"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Receipt)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditReceipt />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* DOCUMENT */}
                        <Route
                            exact
                            path="/addDocument"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Documents)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AddDocuments />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/editDocument"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Documents)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <EditDocuments />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/manageDocument"
                            element={
                                ((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && doctorPageAccess.includes(DOCTOR_PAGES.Documents)) || isTrial == 0 ? (
                                    <ManageDocuments />
                                ) : (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Documents)) || role == ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <ManageDocuments />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />

                        {/* MANAGE CONFIGURATION */}
                        <Route
                            exact
                            path="/configuration"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <Configuration /> : <ErrorPage />
                            }
                        />

                        <Route
                            exact
                            path="/manage-diagnoses"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageDiagnoses /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/manage-procedure"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageProcedure /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/manage-payer"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManagePayer /> : <ErrorPage />}
                        />
                        <Route
                            exact
                            path="/manage-tabs"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManagePatientTabs /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/manage-questions"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageQuestions /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/manage-groups"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageTabgroup /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/manage-roles"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageRoles /> : <ErrorPage />}
                        />
                        {/* MANAGE NOTICE */}
                        <Route
                            exact
                            path="/manage-notice"
                            element={
                                (role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <ManageNotice /> : <ErrorPage />
                            }
                        />
                        <Route
                            exact
                            path="/addNotice"
                            element={(role == ROLES.ADMIN || (role == ROLES.EMPLOYEE_ADMIN && pageAccess.includes(PAGES.Configuration))) && adminRole != ROLES.DOCTOR ? <AddNotice /> : <ErrorPage />}
                        />
                        {/* CHANGE PASSWORD */}
                        <Route
                            exact
                            path="/changePassword"
                            element={
                                (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN || ROLES.EMPLOYEE) && adminRole != ROLES.DOCTOR ? (
                                    <AdminChangePassword />
                                ) : role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR ? (
                                    <ChangePassword />
                                ) : (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN || role == ROLES.EMPLOYEE) && adminRole == ROLES.DOCTOR ? (
                                    <ErrorPage />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                        {/* MANUAL RESET PASSWORD */}
                        <Route
                            exact
                            path="/manualReset"
                            element={
                                (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN || ROLES.EMPLOYEE) && adminRole == ROLES.DOCTOR ? (
                                    <ManualResetPassword />
                                ) : (
                                    <ErrorPage />
                                )
                            }
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
