import axios from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, DIAGNOSE_CODE, showAllQue } from "../../../Config";
import { CLAIM_DETAIL, DIAGNOSES_API } from "../../../Helper/ApiPath";
import {
    ADDITIONAL_QUESTION,
    ARTICAINE_HCL_4_EPINEPHRINE,
    BLADE,
    MAIN_INCISION_LENGTH,
    VERTICAL_INCISION_LENGTH,
    BLOOD_PRESSURE,
    BONE_GRAFT_UPPER_JAW,
    BONE_GRAFT_LOWER_JAW,
    BOTH,
    BUCCAL,
    PALATAL,
    LINGUAL,
    BUPIVACAINE_HCL_05_EPINEPHRINE,
    CANCEL,
    CC,
    CLAIM_OP_NOTES_LIST,
    CM,
    CONSULTATION_TIME,
    END_TIME,
    ESTIMATED_BLOOD_LOSS,
    EXCISION_OF_INFECTION,
    HEART_RATE,
    HOME,
    IMPLANT_FULL_ARCH,
    IMPLANT_PARTIAL,
    IAN,
    ASA,
    PSA,
    MSA,
    LEFT,
    LIDICAINE_HCL_2_EPINEPHRINE,
    LIDICAINE_HCL_2_EPINEPHRINE_50,
    LOWER_JAW,
    MANDIBLE,
    OTHER_BONE_MATERIAL,
    NOT_PATIENT_BONE,
    MAXILLA,
    MEPIVACAINE_HCL,
    MINUTES,
    NEXT,
    NITROUS_ADMINISTERED,
    NITROUS_QSTN1,
    NITROUS_QSTN2,
    OPERATION_TYPE,
    OP_NOTES,
    PATHOLOGY,
    PROCEDURE_TYPE,
    REMOVAL_OF_CYSTS_BY_ENCLUEA,
    RIGHT,
    SINUSOTOMY,
    SINUS_LIFT_REPAIR_OF_WINDOW,
    START_TIME,
    SURGICAL_EXTRACTION_OF_TEETH,
    TOOTH,
    UPPER_JAW,
    VESTIBULOPLASTY40840,
    VESTIBULOPLASTY40842,
    VESTIBULOPLASTY40843,
    VESTIBULOPLASTY,
    VESTIBULOPLASTY40845,
    ORAL_COM_FIS,
    ANTERIOR_NAS_SPINE,
    CANINE_EMINENCE,
    FROM_CANINE_EMINENCE,
    LATERAL_SINUS_LIFT,
    ALVEOLECTOMY,
    MM,
    PATIENT_HARVEST_BONE,
    MEMBRANE_UNIT,
    MEMEBRANE_TYPE,
    MEMBRANE_SIZE,
    BONE_GRAFT_QUANTITY,
    TYPE_OF_SUTURE,
    TYPE_OF_CLOSE_SUTURE,
    BONE_GRAFT_TYPE,
    POSTERIOR_CANINE_EMINENCE,
    IMPLANT_MANUFACTURE,
    IMPLANT_SIZE,
    DIAMETER,
    NCM,
    OSTEOPLASTY,
    LIFT_MEMBRANE,
    LATERAL_WINDOW,
    ALVEOLAR_RIDGE,
    CLAIM_INFORMATION,
    DASH,
    SERVICE_LOCATION_TYPE,
    PRE_AUTHORIZATION,
    CLAIM_,
    PAYER,
    PATIENT,
    DATE_OF_SERVICE,
    SERVICE_LOCATION_TYPE_TITLE,
    RENDERRING_PROVIDER,
    ACCELERATE_WAITING_PERIOD_TITLE,
} from "../../../Helper/Constants";
import OpNotesText from "./OpNotesText";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";

function OpNotes() {
    let navigate = useNavigate();
    let token = JSON.parse(localStorage.getItem("logToken"));
    const claimId = localStorage.getItem("claimId");
    const [isLoading, setIsLoading] = useState(true);
    const [claimDetail, setClaimDetail] = useState([]);
    const [diagnoses, setDiagnoses] = useState([]);
    const [proc, setProc] = useState([]);
    const [procedure, setProcedure] = useState([]);
    const [procedureType, setProcedureType] = useState([]);
    const [upperCode, setUpperCode] = useState([]);
    const [lowerCode, setLowerCode] = useState([]);
    const [alveolectomy, setAlveolectomy] = useState({
        left: "",
        right: "",
        both: "",
    });

    const [LJalveolectomy, setLJAlveolectomy] = useState({
        left: "",
        right: "",
        both: "",
    });

    const [osteoplasty, setOsteoplasty] = useState({
        left: "",
        right: "",
        both: "",
        buccal: "",
        palatal: "",
        bucpal: "",
    });

    const [LJosteoplasty, setLJOsteoplasty] = useState({
        left: "",
        right: "",
        both: "",
        buccal: "",
        lingual: "",
        buclin: "",
    });

    let checkedList = [];
    const UPPER_JAW_CODE = [/*"p9020",*/ "41899", "21030", "21026", "40840", "40842", "40843", "40844", "40845", "30580", "21248", "21249", "21210", "31030", "41830", "21209"];
    const LOWER_JAW_CODE = [/*"p9020",*/ "41899", "21040", "21025", "40840", "40842", "40843", "40844", "21248", "21249", "21215", "41830", "21209"];
    const isUpperJaw = UPPER_JAW_CODE.map((item, i) => {
        return upperCode.includes(item);
    }).includes(true);
    const isLowerJaw = LOWER_JAW_CODE.map((item, i) => {
        return lowerCode.includes(item);
    }).includes(true);

    const [proc21026_unit, setproc21026_unit] = useState([]);
    const [proc21025_unit, setproc21025_unit] = useState([]);
    const [signatureImage, setSignatureImage] = useState("");

    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${CLAIM_DETAIL}/${claimId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.data.data.diagnosesId != null) {
                    let diagnosesId = res.data.data.diagnosesId;
                    checkedList = diagnosesId.split("~");
                }
                setClaimDetail(res.data.data);
                setProc(res.data.data.procedures);
                setSignatureImage(res.data.data.doctor.signatureImage);
                setProcedure(
                    res.data.data.procedures.map((item, i) => {
                        if (item.unit != 0) {
                            return item.code;
                        }
                    })
                );
                const groupbydata = _.chain(
                    res.data.data.procedures.map((item, i) => {
                        return { code: item.code, type: item.type, unit: item.unit };
                    })
                )
                    .groupBy("type")
                    .map((value, key) => ({ type: key, data: value }))
                    .value();

                let ujCode = [];
                let ljCode = [];
                groupbydata.map((item, i) => {
                    if (item.type == PROCEDURE_TYPE.UPPER_JAW) {
                        return item.data.map((item, i) => {
                            if (item.unit != 0) {
                                ujCode.push(item.code);
                                if (item.code === "21026") {
                                    setproc21026_unit(item.unit);
                                }
                            }
                        });
                    }
                    if (item.type == PROCEDURE_TYPE.LOWER_JAW) {
                        return item.data.map((item, i) => {
                            if (item.unit != 0) {
                                ljCode.push(item.code);
                                if (item.code === "21025") {
                                    setproc21025_unit(item.unit);
                                }
                            }
                        });
                    }
                });
                setUpperCode(ujCode);
                setLowerCode(ljCode);
                setProcedureType(
                    res.data.data.procedures.map((item, i) => {
                        return item.type;
                    })
                );

                axios
                    .post(`${baseURL}/${DIAGNOSES_API}`, {
                        headers: { authorization: token.token },
                        isNote: "0",
                    })
                    .then((res1) => {
                        var diagno = [];

                        checkedList.map((item) => {
                            var diagnosis = res1.data.data;
                            var filterddata = _.find(diagnosis, { id: parseInt(item) });

                            var newobj = filterddata.code;
                            diagno.push(newobj);
                        });

                        setDiagnoses(diagno);
                    })
                    .catch((err1) => {
                        if (err1.response) {
                            if (err1.response.status === 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err1.response.data.message);
                            }
                        }
                    });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    if (diagnoses.includes(DIAGNOSE_CODE)) {
        let itemToFind = DIAGNOSE_CODE;

        let foundIdx = diagnoses.findIndex((el) => el == itemToFind);
        diagnoses.splice(foundIdx, 1);
        diagnoses.unshift(itemToFind);
    }

    const handleCancel = () => {
        navigate("/manageOpNotes");
    };
    useEffect(() => {
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [bloodDraw, setBloodDraw] = useState({
        left: "",
        right: "",
        both: "",
        redVial: "",
        whiteVial: "",
        fibrib_clot_remove: "",
        blood_drawn_from: "",
    });

    const [surgical, setSurgical] = useState({
        tooth: "",
    });

    const [removal, setRemoval] = useState({
        encluea: "",
        pathology: "",
        left: "",
        right: "",
        both: "",
    });

    const [excision, setExcision] = useState({
        infection: "",
        pathology: "",
        left: "",
        right: "",
        both: "",
        oral_com_fis: "",
        canine_eminende: "",
        anterior_nasal_spine: "",
        canine_eminende2: "",
        anterior_nasal_spine2: "",
    });

    const [vestibuloplasty, setVestibuloplasty] = useState({
        blade: "",
        length: "",
        vtlength: "",
    });

    const [vestibuloplasty40840, setVestibuloplasty40840] = useState({
        blade40840: "",
        length40840: "",
        vtlength40840: "",
    });

    const [vestibuloplasty40842, setVestibuloplasty40842] = useState({
        blade40842: "",
        length40842: "",
        vtlength40842: "",
    });

    const [vestibuloplasty40843, setVestibuloplasty40843] = useState({
        blade40843: "",
        length40843: "",
        vtlength40843: "",
    });

    const [vestibuloplasty40845, setVestibuloplasty40845] = useState({
        blade40845: "",
        length40845: "",
        vtlength40845: "",
    });

    const [sinus, setSinus] = useState({
        left: "",
        right: "",
        both: "",
        liftMembrane: "",
        lateralWindow: "",
        alveolarRidge: "",
        canineEminence: "",
        lateralSinus: "",
    });

    const [consulationTime, setConsulationTime] = useState("");

    const [partial, setPartial] = useState([]);
    const [ImplantPartial, setImplantPartial] = useState({
        ImplantposteriorCanine: "",
        ImplantManufacture: "",
        ImplantSize: "",
        ImplantDiameter: "",
        ImplantNcm: "",
    });

    const [fullArch, setFullArch] = useState([]);
    const [ImplantFullArch, setImplantFullArch] = useState({
        FullArchposteriorCanine: "",
        FullArchManufacture: "",
        FullArchSize: "",
        FullArchDiameter: "",
        FullArchNcm: "",
    });

    const [bone, setBone] = useState({
        left: "",
        right: "",
        both: "",
        patientHarvst: "",
        membraneUnit: "",
        membraneType: "",
        membraneSize: "",
        membraneMaterial: "",
        boneGraftType: "",
        boneGraftQuantity: "",
        typeSiture: "",
        closeSuture: "",
    });

    const [bone21215, setBone21215] = useState({
        left: "",
        right: "",
        both: "",
        patientHarvst21215: "",
        membraneUnit21215: "",
        membraneType21215: "",
        membraneSize21215: "",
        membraneMaterial21215: "",
        boneGraftType21215: "",
        boneGraftQuantity21215: "",
        typeSiture21215: "",
        closeSuture21215: "",
    });

    const [flap, setFlap] = useState({
        left: "",
        right: "",
        flapLength: "",
        verticalIncision: "",
        both: "",
    });

    const [time, setTime] = useState({
        startTime: "",
        endTime: "",
    });

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setTime({ ...time, [name]: value });
    };

    const handleImplantPartialChange = (e) => {
        const { name, value } = e.target;
        setImplantPartial({ ...ImplantPartial, [name]: value });
    };

    const handleLJImplantPartialChange = (e) => {
        const { name, value } = e.target;
        setLJImplantPartial({ ...LJImplantPartial, [name]: value });
    };

    const handleImplantFullArchChange = (e) => {
        const { name, value } = e.target;
        setImplantFullArch({ ...ImplantFullArch, [name]: value });
    };
    const handleLJImplantFullArchChange = (e) => {
        const { name, value } = e.target;
        setLJImplantFullArch({ ...LJImplantFullArch, [name]: value });
    };

    let implantFull = fullArch;
    const handleImplantFullChange = (e) => {
        let value = e.target.value;
        implantFull.push(value);
        setFullArch(implantFull);
    };

    let implant = partial;
    const handleImplantChange = (e) => {
        let value = e.target.value;
        implant.push(value);
        setPartial(implant);
    };
    const handlePathlogyChange = (e) => {
        let value = e.target.value;
        if (value == "yes") {
            setRemoval({ pathology: "yes" });
        }
    };

    const handleExcisionPathologyChange = (e) => {
        let value = e.target.checked;

        if (value) {
            setExcision({ ...excision, pathology: "yes" });
        } else {
            setExcision({ ...excision, pathology: "" });
        }
    };

    const handleExcision_oral_com_fis_Change = (e) => {
        let value = e.target.checked;

        if (value) {
            setExcision({ ...excision, oral_com_fis: "yes" });
        } else {
            setExcision({ ...excision, oral_com_fis: "" });
        }
    };

    const handleRemovalChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setRemoval({ ...removal, left: "left" });
        }
        if (value == "right") {
            setRemoval({ ...removal, right: "right" });
        }
        if (value == "both") {
            setRemoval({ ...removal, both: "both" });
        }
    };

    const handleExcisionChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setExcision({ ...excision, left: "left" });
        }
        if (value == "right") {
            setExcision({ ...excision, right: "right" });
        }
        if (value == "both") {
            setExcision({ ...excision, both: "both" });
        }
    };

    const handleSinusChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setSinus({ ...sinus, left: "left" });
        }
        if (value == "right") {
            setSinus({ ...sinus, right: "right" });
        }
        if (value == "both") {
            setSinus({ ...sinus, both: "both" });
        }
    };
    const handleAlveolectomyChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setAlveolectomy({ ...alveolectomy, left: "left" });
        }
        if (value == "right") {
            setAlveolectomy({ ...alveolectomy, right: "right" });
        }
        if (value == "both") {
            setAlveolectomy({ ...alveolectomy, both: "both" });
        }
    };
    const handleLJAlveolectomyChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJAlveolectomy({ ...LJalveolectomy, left: "left" });
        }
        if (value == "right") {
            setLJAlveolectomy({ ...LJalveolectomy, right: "right" });
        }
        if (value == "both") {
            setLJAlveolectomy({ ...LJalveolectomy, both: "both" });
        }
    };

    const handle21209SideChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setOsteoplasty({ ...osteoplasty, left: "left" });
        }
        if (value == "right") {
            setOsteoplasty({ ...osteoplasty, right: "right" });
        }
        if (value == "both") {
            setOsteoplasty({ ...osteoplasty, both: "both" });
        }
    };

    const handleLJ21209SideChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJOsteoplasty({ ...LJosteoplasty, left: "left" });
        }
        if (value == "right") {
            setLJOsteoplasty({ ...LJosteoplasty, right: "right" });
        }
        if (value == "both") {
            setLJOsteoplasty({ ...LJosteoplasty, both: "both" });
        }
    };

    const handle21209BucpalChange = (e) => {
        let value = e.target.value;
        if (value == "buccal") {
            setOsteoplasty({ ...osteoplasty, buccal: "buccal" });
        }
        if (value == "palatal") {
            setOsteoplasty({ ...osteoplasty, palatal: "palatal" });
        }
        if (value == "bucpal") {
            setOsteoplasty({ ...osteoplasty, bucpal: "bucpal" });
        }
    };

    const handleLJ21209BuclinChange = (e) => {
        let value = e.target.value;
        if (value == "buccal") {
            setLJOsteoplasty({ ...LJosteoplasty, buccal: "buccal" });
        }
        if (value == "lingual") {
            setLJOsteoplasty({ ...LJosteoplasty, lingual: "lingual" });
        }
        if (value == "buclin") {
            setLJOsteoplasty({ ...LJosteoplasty, buclin: "buclin" });
        }
    };

    const handleBoneChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setBone({ ...bone, left: "left" });
        }
        if (value == "right") {
            setBone({ ...bone, right: "right" });
        }
        if (value == "both") {
            setBone({ ...bone, both: "both" });
        }
    };

    const handleBone21215Change = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setBone21215({ ...bone21215, left: "left" });
        }
        if (value == "right") {
            setBone21215({ ...bone21215, right: "right" });
        }
        if (value == "both") {
            setBone21215({ ...bone21215, both: "both" });
        }
    };

    const handleFlapChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setFlap({ ...flap, left: "left" });
        }
        if (value == "right") {
            setFlap({ ...flap, right: "right" });
        }
        if (value == "both") {
            setFlap({ ...flap, both: "both" });
        }
    };

    const handleSurgical = (e) => {
        const { name, value } = e.target;
        setSurgical({ ...surgical, [name]: value });
    };
    const handleRemoval = (e) => {
        const { name, value } = e.target;
        setRemoval({ ...removal, [name]: value });
    };
    const handleExcision = (e) => {
        const { name, value } = e.target;
        setExcision({ ...excision, [name]: value });
    };
    const handleVestibuloplasty = (e) => {
        const { name, value } = e.target;
        setVestibuloplasty({ ...vestibuloplasty, [name]: value });
    };
    const handleVestibuloplasty40840 = (e) => {
        const { name, value } = e.target;
        setVestibuloplasty40840({ ...vestibuloplasty40840, [name]: value });
    };
    const handleVestibuloplasty40842 = (e) => {
        const { name, value } = e.target;
        setVestibuloplasty40842({ ...vestibuloplasty40842, [name]: value });
    };
    const handleVestibuloplasty40843 = (e) => {
        const { name, value } = e.target;
        setVestibuloplasty40843({ ...vestibuloplasty40843, [name]: value });
    };
    const handleVestibuloplasty40845 = (e) => {
        const { name, value } = e.target;
        setVestibuloplasty40845({ ...vestibuloplasty40845, [name]: value });
    };
    const handleSinus = (e) => {
        const { name, value } = e.target;
        setSinus({ ...sinus, [name]: value });
    };

    const handleBone = (e) => {
        const { name, value } = e.target;
        setBone({ ...bone, [name]: value });
    };

    const handleConsulationChange = (e) => {
        setConsulationTime(e.target.value);
    };

    const [articaine, setArticaine] = useState({
        articaine: "",
        left: "",
        right: "",
        both: "",
        asa: "",
        psa: "",
        msa: "",
    });

    const handleArticaineChange = (e) => {
        const { name, value } = e.target;
        setArticaine({ ...articaine, [name]: value });
    };

    const handleArticaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setArticaine({ ...articaine, left: "left" });
        }
        if (value == "right") {
            setArticaine({ ...articaine, right: "right" });
        }
        if (value == "both") {
            setArticaine({ ...articaine, both: "both" });
        }
    };

    const handleArtApmsaRadioChange = (e) => {
        let value = e.target.value;
        if (value == "asa") {
            setArticaine({ ...articaine, asa: "ASA" });
        }
        if (value == "psa") {
            setArticaine({ ...articaine, psa: "PSA" });
        }
        if (value == "msa") {
            setArticaine({ ...articaine, msa: "MSA" });
        }
    };

    const [bupivacaine, setBupivacaine] = useState({
        bupivacaine: "",
        left: "",
        right: "",
        both: "",
        asa: "",
        psa: "",
        msa: "",
    });

    const handleBupivacaineChange = (e) => {
        const { name, value } = e.target;
        setBupivacaine({ ...bupivacaine, [name]: value });
    };

    const handleBupivacaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setBupivacaine({ ...bupivacaine, left: "left" });
        }
        if (value == "right") {
            setBupivacaine({ ...bupivacaine, right: "right" });
        }
        if (value == "both") {
            setBupivacaine({ ...bupivacaine, both: "both" });
        }
    };

    const handleBupApmsaRadioChange = (e) => {
        let value = e.target.value;
        if (value == "asa") {
            setBupivacaine({ ...bupivacaine, asa: "ASA" });
        }
        if (value == "psa") {
            setBupivacaine({ ...bupivacaine, psa: "PSA" });
        }
        if (value == "msa") {
            setBupivacaine({ ...bupivacaine, msa: "MSA" });
        }
    };
    
    const [lidicaine, setLidicaine] = useState({
        lidicaine: "",
        left: "",
        right: "",
        both: "",
        asa: "",
        psa: "",
        msa: "",
    });

    const handleLidicaineChange = (e) => {
        const { name, value } = e.target;
        setLidicaine({ ...lidicaine, [name]: value });
    };

    const handleLidicaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLidicaine({ ...lidicaine, left: "left" });
        }
        if (value == "right") {
            setLidicaine({ ...lidicaine, right: "right" });
        }
        if (value == "both") {
            setLidicaine({ ...lidicaine, both: "both" });
        }
    };

    const handleLidApmsaRadioChange = (e) => {
        let value = e.target.value;
        if (value == "asa") {
            setLidicaine({ ...lidicaine, asa: "ASA" });
        }
        if (value == "psa") {
            setLidicaine({ ...lidicaine, psa: "PSA" });
        }
        if (value == "msa") {
            setLidicaine({ ...lidicaine, msa: "MSA" });
        }
    };

    const [lidicaine2, setLidicaine2] = useState({
        lidicaine2: "",
        left: "",
        right: "",
        both: "",
        asa: "",
        psa: "",
        msa: "",
    });

    const handleLidicaine2Change = (e) => {
        const { name, value } = e.target;
        setLidicaine2({ ...lidicaine2, [name]: value });
    };

    const handleLidicaine2RadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLidicaine2({ ...lidicaine2, left: "left" });
        }
        if (value == "right") {
            setLidicaine2({ ...lidicaine2, right: "right" });
        }
        if (value == "both") {
            setLidicaine2({ ...lidicaine2, both: "both" });
        }
    };

    const handleLid2ApmsaRadioChange = (e) => {
        let value = e.target.value;
        if (value == "asa") {
            setLidicaine2({ ...lidicaine2, asa: "ASA" });
        }
        if (value == "psa") {
            setLidicaine2({ ...lidicaine2, psa: "PSA" });
        }
        if (value == "msa") {
            setLidicaine2({ ...lidicaine2, msa: "MSA" });
        }
    };

    const [mepivacaine, setMepivacaine] = useState({
        mepivacaine: "",
        left: "",
        right: "",
        both: "",
        asa: "",
        psa: "",
        msa: "",
    });

    const handleMepivacaineChange = (e) => {
        const { name, value } = e.target;
        setMepivacaine({ ...mepivacaine, [name]: value });
    };

    const handleMepivacaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setMepivacaine({ ...mepivacaine, left: "left" });
        }
        if (value == "right") {
            setMepivacaine({ ...mepivacaine, right: "right" });
        }
        if (value == "both") {
            setMepivacaine({ ...mepivacaine, both: "both" });
        }
    };

    const handleMepApmsaRadioChange = (e) => {
        let value = e.target.value;
        if (value == "asa") {
            setMepivacaine({ ...mepivacaine, asa: "ASA" });
        }
        if (value == "psa") {
            setMepivacaine({ ...mepivacaine, psa: "PSA" });
        }
        if (value == "msa") {
            setMepivacaine({ ...mepivacaine, msa: "MSA" });
        }
    };

    const [nitrous, setNitrous] = useState({
        nitrousAdmin: "",
        nitrousDuration: "",
    });

    const handleNitrousChange = (e) => {
        const { name, value } = e.target;
        setNitrous({ ...nitrous, [name]: value });
    };

    /////////////////////////////////////////// lowe jaw ////////////////////////////////

    const [LJbloodDraw, setLJBloodDraw] = useState({
        LJleft: "",
        LJright: "",
        LJredVial: "",
        LJwhiteVial: "",
        LJboth: "",
        LJfibrib_clot_remove: "",
        LJblood_drawn_from: "",
    });

    const [LJsurgical, setLJSurgical] = useState({
        LJtooth: "",
    });

    const [LJremoval, setLJRemoval] = useState({
        LJencluea: "",
        LJpathology: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const [LJexcision, setLJExcision] = useState({
        LJinfection: "",
        LJpathology: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
        LJoral_com_fis: "",
        LJcanine_eminende: "",
        LJanterior_nasal_spine: "",
        LJcanine_eminende2: "",
        LJanterior_nasal_spine2: "",
    });

    const [LJvestibuloplasty, setLJVestibuloplasty] = useState({
        LJblade: "",
        LJlength: "",
        LJvtlength: "",
    });

    const [LJvestibuloplasty40840, setLJVestibuloplasty40840] = useState({
        LJblade40840: "",
        LJlength40840: "",
        LJvtlength40840: "",
    });

    const [LJvestibuloplasty40842, setLJVestibuloplasty40842] = useState({
        LJblade40842: "",
        LJlength40842: "",
        LJvtlength40842: "",
    });

    const [LJvestibuloplasty40843, setLJVestibuloplasty40843] = useState({
        LJblade40843: "",
        LJlength40843: "",
        LJvtlength40843: "",
    });

    const [LJsinus, setLJSinus] = useState({
        LJleft: "",
        LJright: "",
        LJboth: "",
        LJmaterial: "",
        LJmembrance: "",
    });

    const [LJpartial, setLJPartial] = useState([]);
    const [LJfullArch, setLJFullArch] = useState([]);

    const [LJbone, setLJBone] = useState({
        LJleft: "",
        LJright: "",
        LJboth: "",
        LJmaterial: "",
        LJmembrance: "",
    });

    let checkArch = LJfullArch;
    const handleLJImplantFullChange = (e) => {
        let value = e.target.value;
        checkArch.push(value);
        setLJFullArch(checkArch);
    };

    let checkImp = LJpartial;
    const handleLJImplantChange = (e) => {
        let value = e.target.value;
        checkImp.push(value);
        setLJPartial(checkImp);
    };

    const [LJImplantPartial, setLJImplantPartial] = useState({
        LJImplantposteriorCanine: "",
        LJImplantManufacture: "",
        LJImplantSize: "",
        LJImplantDiameter: "",
        LJImplantNcm: "",
    });

    const [LJImplantFullArch, setLJImplantFullArch] = useState({
        LJFullArchposteriorCanine: "",
        LJFullArchManufacture: "",
        LJFullArchSize: "",
        LJFullArchDiameter: "",
        LJFullArchNcm: "",
    });

    const handleLJPathlogyChange = (e) => {
        let value = e.target.value;
        if (value == "yes") {
            setLJRemoval({ pathology: "yes" });
        }
    };

    const handleLJExcisionPathologyChange = (e) => {
        let value = e.target.checked;
        if (value) {
            setLJExcision({ ...LJexcision, LJpathology: "yes" });
        } else {
            setLJExcision({ ...LJexcision, LJpathology: "" });
        }
    };

    const handleLJExcision_oral_com_fis_Change = (e) => {
        let value = e.target.checked;
        if (value) {
            setLJExcision({ ...LJexcision, LJoral_com_fis: "yes" });
        } else {
            setLJExcision({ ...LJexcision, LJoral_com_fis: "" });
        }
    };

    const handleLJRemovalChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJRemoval({ ...LJremoval, left: "left" });
        }
        if (value == "right") {
            setLJRemoval({ ...LJremoval, right: "right" });
        }
        if (value == "both") {
            setLJRemoval({ ...LJremoval, both: "both" });
        }
    };

    const handleLJExcisionChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJExcision({ ...LJexcision, left: "left" });
        }
        if (value == "right") {
            setLJExcision({ ...LJexcision, right: "right" });
        }
        if (value == "both") {
            setLJExcision({ ...LJexcision, both: "both" });
        }
    };

    const handleLJSurgical = (e) => {
        const { name, value } = e.target;
        setLJSurgical({ ...surgical, [name]: value });
    };
    const handleLJRemoval = (e) => {
        const { name, value } = e.target;
        setLJRemoval({ ...removal, [name]: value });
    };
    const handleLJExcision = (e) => {
        const { name, value } = e.target;
        setLJExcision({ ...LJexcision, [name]: value });
    };
    const handleLJVestibuloplasty = (e) => {
        const { name, value } = e.target;
        setLJVestibuloplasty({ ...LJvestibuloplasty, [name]: value });
    };

    const handleLJVestibuloplasty40840 = (e) => {
        const { name, value } = e.target;
        setLJVestibuloplasty40840({ ...LJvestibuloplasty40840, [name]: value });
    };
    const handleLJVestibuloplasty40842 = (e) => {
        const { name, value } = e.target;
        setLJVestibuloplasty40842({ ...LJvestibuloplasty40842, [name]: value });
    };
    const handleLJVestibuloplasty40843 = (e) => {
        const { name, value } = e.target;
        setLJVestibuloplasty40843({ ...LJvestibuloplasty40843, [name]: value });
    };

    const [bp, setBp] = useState("");

    const handleBpchange = (e) => {
        setBp(e.target.value);
    };

    const [hr, setHr] = useState("");

    const handleHrchange = (e) => {
        setHr(e.target.value);
    };

    const handleBone21215 = (e) => {
        const { name, value } = e.target;
        setBone21215({ ...bone21215, [name]: value });
    };

    const [step, setstep] = useState(1);
    const handleNext = () => {
        document.getElementById("question").style.display = "none";
        setstep(2);
    };

    const [LJarticaine, setLJArticaine] = useState({
        LJarticaine: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const handleLJArticaineChange = (e) => {
        const { name, value } = e.target;
        setLJArticaine({ ...LJarticaine, [name]: value });
    };

    const handleLJArticaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJArticaine({ ...LJarticaine, LJleft: "left" });
        }
        if (value == "right") {
            setLJArticaine({ ...LJarticaine, LJright: "right" });
        }
        if (value == "both") {
            setLJArticaine({ ...LJarticaine, LJboth: "both" });
        }
    };

    const [LJbupivacaine, setLJBupivacaine] = useState({
        LJbupivacaine: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const handleLJBupivacaineChange = (e) => {
        const { name, value } = e.target;
        setLJBupivacaine({ ...LJbupivacaine, [name]: value });
    };

    const handleLJBupivacaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJBupivacaine({ ...LJbupivacaine, LJleft: "left" });
        }
        if (value == "right") {
            setLJBupivacaine({ ...LJbupivacaine, LJright: "right" });
        }
        if (value == "both") {
            setLJBupivacaine({ ...LJbupivacaine, LJboth: "both" });
        }
    };

    const [LJlidicaine, setLJLidicaine] = useState({
        LJlidicaine: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const handleLJLidicaineChange = (e) => {
        const { name, value } = e.target;
        setLJLidicaine({ ...LJlidicaine, [name]: value });
    };

    const handleLJLidicaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJLidicaine({ ...LJlidicaine, LJleft: "left" });
        }
        if (value == "right") {
            setLJLidicaine({ ...LJlidicaine, LJright: "right" });
        }
        if (value == "both") {
            setLJLidicaine({ ...LJlidicaine, LJboth: "both" });
        }
    };

    const [LJlidicaine2, setLJLidicaine2] = useState({
        LJlidicaine2: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const handleLJLidicaine2Change = (e) => {
        const { name, value } = e.target;
        setLJLidicaine2({ ...LJlidicaine2, [name]: value });
    };

    const handleLJLidicaine2RadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJLidicaine2({ ...LJlidicaine2, LJleft: "left" });
        }
        if (value == "right") {
            setLJLidicaine2({ ...LJlidicaine2, LJright: "right" });
        }
        if (value == "both") {
            setLJLidicaine2({ ...LJlidicaine2, LJboth: "both" });
        }
    };

    const [LJmepivacaine, setLJMepivacaine] = useState({
        LJmepivacaine: "",
        LJleft: "",
        LJright: "",
        LJboth: "",
    });

    const handleLJMepivacaineChange = (e) => {
        const { name, value } = e.target;
        setLJMepivacaine({ ...LJmepivacaine, [name]: value });
    };

    const handleLJMepivacaineRadioChange = (e) => {
        let value = e.target.value;
        if (value == "left") {
            setLJMepivacaine({ ...LJmepivacaine, LJleft: "left" });
        }
        if (value == "right") {
            setLJMepivacaine({ ...LJmepivacaine, LJright: "right" });
        }
        if (value == "both") {
            setLJMepivacaine({ ...LJmepivacaine, LJboth: "both" });
        }
    };

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{OP_NOTES}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-sticky-note" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOpNotes">
                                        {CLAIM_OP_NOTES_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{OP_NOTES}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-lg-12 col-sm-12">
                            <div className="card">
                                <div className="card-head">
                                    <header>{CLAIM_INFORMATION}</header>
                                </div>

                                <div className="card-body" style={{ paddingRight: "250px", paddingLeft: " 35px" }}>
                                    <div className="form-horizontal">
                                        <div className="form-body">
                                            <div className="pull-left">
                                                <div>
                                                    <b>{CLAIM_}</b> : <span>{claimDetail.code ? claimDetail.code : DASH}</span>
                                                </div>
                                                <div>
                                                    <b>{PAYER} </b> : <span>{claimDetail.payer ? claimDetail.payer && claimDetail.payer.name : DASH}</span>
                                                </div>
                                                <div>
                                                    <b>{PATIENT} </b> :{" "}
                                                    <span>
                                                        {claimDetail.patient && claimDetail.patient.firstName} {claimDetail.patient && claimDetail.patient.lastName}
                                                    </span>
                                                </div>
                                                <div>
                                                    <b>{DATE_OF_SERVICE} </b> : <span>{claimDetail.dateOfService ? claimDetail && dateFormateMonthDateYear(claimDetail.dateOfService) : DASH}</span>
                                                </div>
                                            </div>
                                            <div className="pull-right">
                                                <div>
                                                    <b>{SERVICE_LOCATION_TYPE_TITLE} </b> :{" "}
                                                    <span>
                                                        {claimDetail.serviceLocationTypeId
                                                            ? Object.keys(SERVICE_LOCATION_TYPE).find((key) => SERVICE_LOCATION_TYPE[key] === claimDetail.serviceLocationTypeId)
                                                            : DASH}
                                                    </span>
                                                </div>

                                                <div>
                                                    <b>{RENDERRING_PROVIDER} </b> :{" "}
                                                    <span>
                                                        {" "}
                                                        {claimDetail.doctor && claimDetail.doctor.firstName} {claimDetail.doctor && claimDetail.doctor.lastName}
                                                    </span>
                                                </div>
                                                <div>
                                                    <b>{ACCELERATE_WAITING_PERIOD_TITLE} </b> :{" "}
                                                    <span>
                                                        {claimDetail.accelerateWaitingPeriodId && claimDetail.accelerateWaitingPeriodId === 1
                                                            ? "Established Patient Level 1"
                                                            : claimDetail.accelerateWaitingPeriodId === 2
                                                            ? "Established Patient Level 2"
                                                            : claimDetail.accelerateWaitingPeriodId === 3
                                                            ? "New Patient Level 1"
                                                            : claimDetail.accelerateWaitingPeriodId === 4
                                                            ? "New Patient Level 2"
                                                            : DASH}
                                                    </span>
                                                </div>
                                                <div>
                                                    <b>{PRE_AUTHORIZATION}</b> : <span>{claimDetail.preAuthorization ? claimDetail && claimDetail.preAuthorization : DASH}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {isLoading ? (
                            <div className="form-body" style={{ height: "100px" }}>
                                <div id="text">
                                    <i className="fa fa-spinner fa-spin fa-2x"></i>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12 col-12" id="question">
                                <div className="card card-box">
                                    <div className="card-head">
                                        <header>{ADDITIONAL_QUESTION}</header>
                                    </div>

                                    <div className="card-body" id="bar-parent">
                                        {/* //////////////////////   UPPER JAW-MAXILLA ///////////////////// */}
                                        {isUpperJaw || showAllQue ? (
                                            <>
                                                <div className="upperJaw">
                                                    <h3>{UPPER_JAW}</h3>
                                                    <hr style={{ height: "0.1rem" }} />
                                                    <div className="text-end">
                                                        <h5 className="text-dark my-3 font-bold mx-4">{OPERATION_TYPE}</h5>
                                                        <div className="btn-group btn-group-circle btn-group-solid">
                                                            <button type="button" className="btn deepPink-bgcolor some-padding">
                                                                {MAXILLA}
                                                            </button>

                                                            <button type="button" className="btn btn-info" disabled>
                                                                {MANDIBLE}
                                                            </button>
                                                        </div>

                                                        <h5 className="text-dark mx-5 my-2 font-bold font-bold padding-maxilla">{MAXILLA}</h5>
                                                    </div>
                                                    <div className="row">
                                                        {/* 41899 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("41899")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12  d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 41899</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12  d-flex">
                                                                            <label className="control-label">{SURGICAL_EXTRACTION_OF_TEETH}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <label className="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-form-label">{TOOTH}</label>
                                                                        <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-md-8">
                                                                            <input type="text" className="form-control" name="tooth" value={surgical.tooth} onChange={handleSurgical} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21030 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21030")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21030</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 d-flex">
                                                                            <label className="control-label">{REMOVAL_OF_CYSTS_BY_ENCLUEA}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                                    <input type="text" className="form-control" name="" onChange={handleRemoval} />
                                                                </div>
                                                                <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-8">
                                                                                    <label className="col-form-label d-flex justify-content-md-end">{PATHOLOGY}</label>
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex">
                                                                                    <input type="checkbox" name="pathlogy" value="yes" onChange={handlePathlogyChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12">
                                                                            <div className="row ">
                                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                    <div className="row" style={{ paddingTop: "4px" }}>
                                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex m-r-10">
                                                                                            <div className="mx-1">{LEFT}</div>
                                                                                            <div className="">
                                                                                                <input type="radio" name="removal" value="left" onChange={handleRemovalChange} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-3 d-flex mx-2">
                                                                                            <div className="mx-1">{RIGHT}</div>
                                                                                            <div className="">
                                                                                                <input type="radio" name="removal" value="right" onChange={handleRemovalChange} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-3 d-flex mx-lg-1">
                                                                                            <div className="mx-1">{BOTH}</div>
                                                                                            <div className="">
                                                                                                <input type="radio" name="removal" value="both" onChange={handleRemovalChange} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21026 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21026")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex">
                                                                            <label className="control-label">CPT 21026</label>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{EXCISION_OF_INFECTION}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-7 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex " style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1">{LEFT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="excision" value="left" onChange={handleExcisionChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex mx-1" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="excision" value="right" onChange={handleExcisionChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="excision" value="both" onChange={handleExcisionChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-1 col-lg-3 col-md-3 col-sm-12 d-flex" style={{ paddingTop: "4px" }}>
                                                                    <div className="mx-lg-2"> {PATHOLOGY}</div>
                                                                    <div className="mx-lg-3">
                                                                        <input type="checkbox" name="pathology" value="yes" onChange={handleExcisionPathologyChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-4 mx-1"></div>
                                                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2"> {ORAL_COM_FIS}</div>
                                                                    <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                        <input type="checkbox" name="oral_com_fis" value="yes" onChange={handleExcision_oral_com_fis_Change} />
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-2">
                                                                    <div className="col-12  mx-2" style={{ paddingTop: "4px" }}>
                                                                        <div className="row d-flex">
                                                                            <div className="col-xl-4 "></div>

                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{CANINE_EMINENCE}</div>
                                                                            <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="canine_eminende"
                                                                                    value={excision.canine_eminende}
                                                                                    onChange={handleExcision}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex pt-2">{MM}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mx-2" style={{ paddingTop: "4px" }}>
                                                                        <div className="row pt-2 d-flex">
                                                                            <div className="col-xl-4 "></div>

                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{ANTERIOR_NAS_SPINE}</div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="anterior_nasal_spine"
                                                                                    value={excision.anterior_nasal_spine}
                                                                                    onChange={handleExcision}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1  d-flex pt-2">{MM}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {proc21026_unit >= 2 || showAllQue ? (
                                                                    <div className="row pt-2">
                                                                        <div className="col-12  mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="row d-flex">
                                                                                <div className="col-xl-4 "></div>
                                                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{CANINE_EMINENCE}</div>
                                                                                <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="canine_eminende2"
                                                                                        value={excision.canine_eminende2}
                                                                                        onChange={handleExcision}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex pt-2">{MM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="row pt-2 d-flex">
                                                                                <div className="col-xl-4 "></div>
                                                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{ANTERIOR_NAS_SPINE}</div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="anterior_nasal_spine2"
                                                                                        value={excision.anterior_nasal_spine2}
                                                                                        onChange={handleExcision}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1  d-flex pt-2">{MM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40840 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("40840")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40840</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40840}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 ">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="blade40840"
                                                                                        value={vestibuloplasty40840.blade40840}
                                                                                        onChange={handleVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length40840"
                                                                                        value={vestibuloplasty40840.length40840}
                                                                                        onChange={handleVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="vtlength40840"
                                                                                        value={vestibuloplasty40840.vtlength40840}
                                                                                        onChange={handleVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40842 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("40842")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40842</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40842}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8  col-lg-8">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="blade40842"
                                                                                        value={vestibuloplasty40842.blade40842}
                                                                                        onChange={handleVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length40842"
                                                                                        value={vestibuloplasty40842.length40842}
                                                                                        onChange={handleVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="vtlength40842"
                                                                                        value={vestibuloplasty40842.vtlength40842}
                                                                                        onChange={handleVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40843 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("40843")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40843</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40843}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8">
                                                                    <div className="row pt-2">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="blade40843"
                                                                                        value={vestibuloplasty40843.blade40843}
                                                                                        onChange={handleVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length40843"
                                                                                        value={vestibuloplasty40843.length40843}
                                                                                        onChange={handleVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="vtlength40843"
                                                                                        value={vestibuloplasty40843.vtlength40843}
                                                                                        onChange={handleVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40844 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("40844")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40844</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 ">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="blade"
                                                                                        value={vestibuloplasty.blade}
                                                                                        onChange={handleVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length"
                                                                                        value={vestibuloplasty.length}
                                                                                        onChange={handleVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="vtlength"
                                                                                        value={vestibuloplasty.vtlength}
                                                                                        onChange={handleVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40845 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("40845")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40845</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40845}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="blade40845"
                                                                                        value={vestibuloplasty40845.blade40845}
                                                                                        onChange={handleVestibuloplasty40845}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="length40845"
                                                                                        value={vestibuloplasty40845.length40845}
                                                                                        onChange={handleVestibuloplasty40845}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="vtlength40845"
                                                                                        value={vestibuloplasty40845.vtlength40845}
                                                                                        onChange={handleVestibuloplasty40845}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 30580 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("30580")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 30580</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{SINUS_LIFT_REPAIR_OF_WINDOW}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row" style={{ paddingTop: "4px" }}>
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="" style={{ paddingRight: "4px" }}>
                                                                                {LEFT}
                                                                            </div>
                                                                            <div className="">
                                                                                <input type="radio" name="sinus" value="left" onChange={handleSinusChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="sinus" value="right" onChange={handleSinusChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1"> {BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="sinus" value="both" onChange={handleSinusChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="offset-xl-4 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                    {LIFT_MEMBRANE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                <input type="text" className="form-control" name="liftMembrane" value={sinus.liftMembrane} onChange={handleSinus} />
                                                                            </div>
                                                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                <label className="control-label">{MM}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="offset-xl-4 col-xl-8 col-lg-12 col-md-12 col-sm-12 mt-md-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                <label className="col-form-label d-flex">{LATERAL_WINDOW}</label>
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                <input type="text" className="form-control" name="lateralWindow" value={sinus.lateralWindow} onChange={handleSinus} />
                                                                            </div>
                                                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                <label className="control-label">{MM}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingLeft: "18px" }}>
                                                                        <div className="row">
                                                                            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                    {ALVEOLAR_RIDGE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                <input type="text" className="form-control" name="alveolarRidge" value={sinus.alveolarRidge} onChange={handleSinus} />
                                                                            </div>
                                                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                <label className="control-label">{MM}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-6">{FROM_CANINE_EMINENCE}</div>
                                                                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                <input type="text" className="form-control" name="canineEminence" value={sinus.canineEminence} onChange={handleSinus} />
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                {MM}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "4px" }}>
                                                                                    {LATERAL_SINUS_LIFT}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                <input type="text" className="form-control" name="lateralSinus" value={sinus.lateralSinus} onChange={handleSinus} />
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                {MM}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21248 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21248")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21248</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{IMPLANT_PARTIAL}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="d-flex " style={{ paddingTop: "5px" }}>
                                                                                1
                                                                                <input type="radio" className="mx-2 " name="implant-partial" value="1" onChange={handleImplantChange} />
                                                                                2
                                                                                <input type="radio" className="mx-2 " name="implant-partial" value="2" onChange={handleImplantChange} />
                                                                                3
                                                                                <input type="radio" className="mx-2 " name="implant-partial" value="3" onChange={handleImplantChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                    <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                        {POSTERIOR_CANINE_EMINENCE}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="ImplantposteriorCanine"
                                                                                        value={ImplantPartial.ImplantposteriorCanine}
                                                                                        onChange={handleImplantPartialChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                    <label className="control-label">{MM}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 mt-md-2">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: "5px" }}>
                                                                            <label className="control-label">{IMPLANT_MANUFACTURE}</label>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="ImplantManufacture"
                                                                                value={ImplantPartial.ImplantManufacture}
                                                                                onChange={handleImplantPartialChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingLeft: "18px" }}>
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "4px" }}>
                                                                                    {IMPLANT_SIZE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="ImplantSize"
                                                                                    value={ImplantPartial.ImplantSize}
                                                                                    onChange={handleImplantPartialChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label">{DIAMETER}</label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="ImplantDiameter"
                                                                                    value={ImplantPartial.ImplantDiameter}
                                                                                    onChange={handleImplantPartialChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-1 col-lg-2 col-md-2 col-sm-5 col-6" style={{ paddingTop: "4px", paddingLeft: "18px" }}>
                                                                        {NCM}
                                                                    </div>
                                                                    <div className="col-xl-7 col-lg-10 col-md-10 col-sm-6 col-6" style={{ padding: "4px 0px 0px 16px" }}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="ImplantNcm"
                                                                            value={ImplantPartial.ImplantNcm}
                                                                            onChange={handleImplantPartialChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21249 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21249")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21249</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{IMPLANT_FULL_ARCH}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="d-flex " style={{ paddingTop: "5px" }}>
                                                                                4
                                                                                <input type="radio" className="mx-2" value="4" name="implant-full-arch" onChange={handleImplantFullChange} />
                                                                                5
                                                                                <input type="radio" className="mx-2" value="5" name="implant-full-arch" onChange={handleImplantFullChange} />
                                                                                6
                                                                                <input type="radio" className="mx-2" value="6" name="implant-full-arch" onChange={handleImplantFullChange} />
                                                                                7
                                                                                <input type="radio" className="mx-2" value="7" name="implant-full-arch" onChange={handleImplantFullChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                    <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                        {POSTERIOR_CANINE_EMINENCE}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="FullArchposteriorCanine"
                                                                                        value={ImplantFullArch.FullArchposteriorCanine}
                                                                                        onChange={handleImplantFullArchChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "5px" }}>
                                                                                    <label className="control-label">{MM}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 mt-md-2">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: "5px" }}>
                                                                            <label className="control-label">{IMPLANT_MANUFACTURE}</label>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="FullArchManufacture"
                                                                                value={ImplantFullArch.FullArchManufacture}
                                                                                onChange={handleImplantFullArchChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingLeft: "18px" }}>
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "4px" }}>
                                                                                    {IMPLANT_SIZE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="FullArchSize"
                                                                                    value={ImplantFullArch.FullArchSize}
                                                                                    onChange={handleImplantFullArchChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label">{DIAMETER}</label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="FullArchDiameter"
                                                                                    value={ImplantFullArch.FullArchDiameter}
                                                                                    onChange={handleImplantFullArchChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-1 col-lg-2 col-md-2 col-sm-5 col-6" style={{ paddingTop: "4px", paddingLeft: "18px" }}>
                                                                        {NCM}
                                                                    </div>
                                                                    <div className="col-xl-7 col-lg-10 col-md-10 col-sm-6 col-6" style={{ padding: "4px 0px 0px 16px" }}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="FullArchNcm"
                                                                            value={ImplantFullArch.FullArchNcm}
                                                                            onChange={handleImplantFullArchChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21210 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21210")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21210</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{BONE_GRAFT_UPPER_JAW}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row" style={{ paddingTop: "4px" }}>
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex">
                                                                            <div className="" style={{ paddingRight: "4px" }}>
                                                                                {LEFT}
                                                                            </div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft" value="left" onChange={handleBoneChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1">{RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft" value="right" onChange={handleBoneChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1">{BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft" value="both" onChange={handleBoneChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <div className="col-12 d-flex">
                                                                            <div className=" p-0">
                                                                                <label className="col-form-label d-flex justify-content-start">{PATIENT_HARVEST_BONE}</label>
                                                                            </div>
                                                                            <div className=" d-flex mx-3">
                                                                                <input type="text" className="form-control" name="patientHarvst" value={bone.patientHarvst} onChange={handleBone} />
                                                                            </div>

                                                                            <div className=" d-flex mx-3">
                                                                                <label className="col-form-label d-flex justify-content-end">{CC}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-1 my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMBRANE_UNIT}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="membraneUnit" value={bone.membraneUnit} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMEBRANE_TYPE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="membraneType" value={bone.membraneType} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMBRANE_SIZE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="membraneSize" value={bone.membraneSize} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-4">
                                                                                <label className="control-label">{OTHER_BONE_MATERIAL}</label>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="membraneMaterial"
                                                                                    value={bone.membraneMaterial}
                                                                                    onChange={handleBone}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex my-2">{NOT_PATIENT_BONE}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{BONE_GRAFT_TYPE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="boneGraftType" value={bone.boneGraftType} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{BONE_GRAFT_QUANTITY}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="boneGraftQuantity"
                                                                                    value={bone.boneGraftQuantity}
                                                                                    onChange={handleBone}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{TYPE_OF_SUTURE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="typeSiture" value={bone.typeSiture} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{TYPE_OF_CLOSE_SUTURE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input type="text" className="form-control" name="closeSuture" value={bone.closeSuture} onChange={handleBone} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 31030 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("31030")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 31030</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{SINUSOTOMY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="row" style={{ paddingTop: "4px" }}>
                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex m-r-10">
                                                                                <div className="mx-1">{LEFT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="flap" value="left" onChange={handleFlapChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{RIGHT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="flap" value="right" onChange={handleFlapChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{BOTH}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="flap" value="both" onChange={handleFlapChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 41830 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("41830")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <label className="control-label">CPT 41830</label>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{ALVEOLECTOMY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex " style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1">{LEFT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="Alveolectomy" value="left" onChange={handleAlveolectomyChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-1" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="Alveolectomy" value="right" onChange={handleAlveolectomyChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="Alveolectomy" value="both" onChange={handleAlveolectomyChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21209 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && upperCode.includes("21209")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex">
                                                                            <label className="control-label">CPT 21209</label>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{OSTEOPLASTY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="row" style={{ paddingTop: "4px" }}>
                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex m-r-10">
                                                                                <div className="mx-1">{LEFT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209Side" value="left" onChange={handle21209SideChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{RIGHT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209Side" value="right" onChange={handle21209SideChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{BOTH}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209Side" value="both" onChange={handle21209SideChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="row" style={{ paddingTop: "4px" }}>
                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex m-r-10">
                                                                                <div className="mx-1">{BUCCAL}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209bucpal" value="buccal" onChange={handle21209BucpalChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{PALATAL}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209bucpal" value="palatal" onChange={handle21209BucpalChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{BOTH}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="21209bucpal" value="bucpal" onChange={handle21209BucpalChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <hr style={{ height: "0.2rem" }} />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {/* //////////////////////   UPPER JAW ///////////////////// */}
                                        {/* //////////////////////   ARTICAINE_HCL_4_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{ARTICAINE_HCL_4_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input type="text" className="form-control" name="articaine" value={articaine.articaine} onChange={handleArticaineChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="row">
                                                                    <div className="col-5" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="artapmsaRadio" value="asa" onChange={handleArtApmsaRadioChange} />
                                                                        <span>{ASA}</span>
                                                                        <input type="radio" className="mx-2" name="artapmsaRadio" value="psa" onChange={handleArtApmsaRadioChange} />
                                                                        <span>{PSA}</span>
                                                                        <input type="radio" className="mx-2" name="artapmsaRadio" value="msa" onChange={handleArtApmsaRadioChange} />
                                                                        <span>{MSA}</span>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-6" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="articaineRadio" value="left" onChange={handleArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="articaineRadio" value="right" onChange={handleArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="articaineRadio" value="both" onChange={handleArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   BUPIVACAINE_HCL_05_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{BUPIVACAINE_HCL_05_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="bupivacaine"
                                                                            value={bupivacaine.bupivacaine}
                                                                            onChange={handleBupivacaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="row">
                                                                    <div className="col-5" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="bupapmsaRadio" value="asa" onChange={handleBupApmsaRadioChange} />
                                                                        <span>{ASA}</span>
                                                                        <input type="radio" className="mx-2" name="bupapmsaRadio" value="psa" onChange={handleBupApmsaRadioChange} />
                                                                        <span>{PSA}</span>
                                                                        <input type="radio" className="mx-2" name="bupapmsaRadio" value="msa" onChange={handleBupApmsaRadioChange} />
                                                                        <span>{MSA}</span>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-6" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="bupivacaineRadio" value="left" onChange={handleBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="bupivacaineRadio" value="right" onChange={handleBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="bupivacaineRadio" value="both" onChange={handleBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LIDICAINE_HCL_2_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{LIDICAINE_HCL_2_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input type="text" className="form-control" name="lidicaine" value={lidicaine.lidicaine} onChange={handleLidicaineChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="row">
                                                                    <div className="col-5" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="lidapmsaRadio" value="asa" onChange={handleLidApmsaRadioChange} />
                                                                        <span>{ASA}</span>
                                                                        <input type="radio" className="mx-2" name="lidapmsaRadio" value="psa" onChange={handleLidApmsaRadioChange} />
                                                                        <span>{PSA}</span>
                                                                        <input type="radio" className="mx-2" name="lidapmsaRadio" value="msa" onChange={handleLidApmsaRadioChange} />
                                                                        <span>{MSA}</span>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-6" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="lidicaineRadio" value="left" onChange={handleLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="lidicaineRadio" value="right" onChange={handleLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="lidicaineRadio" value="both" onChange={handleLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LIDICAINE_HCL_2_EPINEPHRINE_50 ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{LIDICAINE_HCL_2_EPINEPHRINE_50}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input type="text" className="form-control" name="lidicaine2" value={lidicaine2.lidicaine2} onChange={handleLidicaine2Change} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="row">
                                                                    <div className="col-5" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="lid2apmsaRadio" value="asa" onChange={handleLid2ApmsaRadioChange} />
                                                                        <span>{ASA}</span>
                                                                        <input type="radio" className="mx-2" name="lid2apmsaRadio" value="psa" onChange={handleLid2ApmsaRadioChange} />
                                                                        <span>{PSA}</span>
                                                                        <input type="radio" className="mx-2" name="lid2apmsaRadio" value="msa" onChange={handleLid2ApmsaRadioChange} />
                                                                        <span>{MSA}</span>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-6" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="lidicaine2Radio" value="left" onChange={handleLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="lidicaine2Radio" value="right" onChange={handleLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="lidicaine2Radio" value="both" onChange={handleLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   MEPIVACAINE_HCL ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{MEPIVACAINE_HCL}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="mepivacaine"
                                                                            value={mepivacaine.mepivacaine}
                                                                            onChange={handleMepivacaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="row">
                                                                    <div className="col-5" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="mepapmsaRadio" value="asa" onChange={handleMepApmsaRadioChange} />
                                                                        <span>{ASA}</span>
                                                                        <input type="radio" className="mx-2" name="mepapmsaRadio" value="psa" onChange={handleMepApmsaRadioChange} />
                                                                        <span>{PSA}</span>
                                                                        <input type="radio" className="mx-2" name="mepapmsaRadio" value="msa" onChange={handleMepApmsaRadioChange} />
                                                                        <span>{MSA}</span>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-6" style={{ paddingTop: "5px" }}>
                                                                        <input type="radio" className="mx-2" name="mepivacaineRadio" value="left" onChange={handleMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="mepivacaineRadio" value="right" onChange={handleMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="mepivacaineRadio" value="both" onChange={handleMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LOWER JAW- MANDIBLE ///////////////////// */}
                                        {isLowerJaw || showAllQue ? (
                                            <>
                                                <hr style={{ height: "0.2rem" }} />
                                                <div className="lowerJaw">
                                                    <h3>{LOWER_JAW}</h3>
                                                    <hr style={{ height: "0.1rem" }} />
                                                    <div className="text-end">
                                                        <h5 className="text-dark my-3 font-bold mx-4">{OPERATION_TYPE}</h5>
                                                        <div className="btn-group btn-group-circle btn-group-solid">
                                                            <button type="button" className="btn btn-info some-padding" disabled>
                                                                {MAXILLA}
                                                            </button>

                                                            <button type="button" className="btn deepPink-bgcolor">
                                                                {MANDIBLE}
                                                            </button>
                                                        </div>

                                                        <h5 className="text-dark mx-5 my-2 font-bold padding-mandible">{MANDIBLE}</h5>
                                                    </div>
                                                    <div className="row">
                                                        {/* 41899 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("41899")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12  d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 41899</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12  d-flex">
                                                                            <label className="control-label">{SURGICAL_EXTRACTION_OF_TEETH}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <label className="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-form-label">{TOOTH}</label>
                                                                        <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-md-8">
                                                                            <input type="text" className="form-control" name="LJtooth" value={LJsurgical.LJtooth} onChange={handleLJSurgical} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21040 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21040")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21040</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 d-flex">
                                                                            <label className="control-label">{REMOVAL_OF_CYSTS_BY_ENCLUEA}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                                    <input type="text" className="form-control" name="" onChange={handleLJRemoval} />
                                                                </div>
                                                                <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-8">
                                                                                    <label className="col-form-label d-flex justify-content-end">{PATHOLOGY}</label>
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex">
                                                                                    <input type="checkbox" name="pathology" value="yes" onChange={handleLJPathlogyChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                    <div className="row" style={{ paddingTop: "4px" }}>
                                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex m-r-10">
                                                                                            <div className="mx-1">{LEFT}</div>
                                                                                            <div>
                                                                                                <input type="radio" value="left" name="LJRemoval" onChange={handleLJRemovalChange} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-3 d-flex mx-2">
                                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                                            <div>
                                                                                                <input type="radio" value="right" name="LJRemoval" onChange={handleLJRemovalChange} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-3 d-flex mx-2">
                                                                                            <div className="mx-1"> {BOTH}</div>
                                                                                            <div>
                                                                                                <input type="radio" name="LJRemoval" value="both" onChange={handleLJRemovalChange} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21025 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21025")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21025</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{EXCISION_OF_INFECTION}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-7 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex " style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1">{LEFT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJexcision" value="left" onChange={handleLJExcisionChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex mx-1" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJexcision" value="right" onChange={handleLJExcisionChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-l-3 col-md-3 col-sm-12 d-flex mx-1" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJexcision" value="both" onChange={handleLJExcisionChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-1 col-lg-3 col-md-3 col-sm-12 d-flex" style={{ paddingTop: "4px" }}>
                                                                    <div className="mx-lg-2"> {PATHOLOGY}</div>
                                                                    <div className="mx-lg-3">
                                                                        <input type="checkbox" name="LJpathology" value="yes" onChange={handleLJExcisionPathologyChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-4 mx-1"></div>
                                                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2"> {ORAL_COM_FIS}</div>
                                                                    <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                        <input type="checkbox" name="LJoral_com_fis" value="yes" onChange={handleLJExcision_oral_com_fis_Change} />
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-12  mx-2" style={{ paddingTop: "4px" }}>
                                                                        <div className="row  d-flex">
                                                                            <div className="col-xl-4 "></div>
                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{CANINE_EMINENCE}</div>
                                                                            <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJcanine_eminende"
                                                                                    value={LJexcision.LJcanine_eminende}
                                                                                    onChange={handleLJExcision}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex pt-2">{MM}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 mx-2" style={{ paddingTop: "4px" }}>
                                                                        <div className="row pt-2 d-flex">
                                                                            <div className="col-xl-4 "></div>
                                                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{ANTERIOR_NAS_SPINE}</div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJanterior_nasal_spine"
                                                                                    value={LJexcision.LJanterior_nasal_spine}
                                                                                    onChange={handleLJExcision}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1  d-flex pt-2">{MM}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {proc21025_unit >= 2 || showAllQue ? (
                                                                    <div className="row pt-2">
                                                                        <div className="col-12  mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="row  d-flex">
                                                                                <div className="col-xl-4 "></div>
                                                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{CANINE_EMINENCE}</div>
                                                                                <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJcanine_eminende2"
                                                                                        value={LJexcision.LJcanine_eminende2}
                                                                                        onChange={handleLJExcision}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex pt-2">{MM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="row pt-2 d-flex">
                                                                                <div className="col-xl-4 "></div>
                                                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 d-flex pt-2">{ANTERIOR_NAS_SPINE}</div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJanterior_nasal_spine2"
                                                                                        value={LJexcision.LJanterior_nasal_spine2}
                                                                                        onChange={handleLJExcision}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex pt-2">{MM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40840 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("40840")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40840</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40840}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 ">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJblade40840"
                                                                                        value={LJvestibuloplasty40840.LJblade40840}
                                                                                        onChange={handleLJVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="row pt-2">
                                                                                    <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                        <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJlength40840"
                                                                                        value={LJvestibuloplasty40840.LJlength40840}
                                                                                        onChange={handleLJVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJvtlength40840"
                                                                                        value={LJvestibuloplasty40840.LJvtlength40840}
                                                                                        onChange={handleLJVestibuloplasty40840}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40842 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("40842")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40842</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40842}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8  col-lg-8">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJblade40842"
                                                                                        value={LJvestibuloplasty40842.LJblade40842}
                                                                                        onChange={handleLJVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJlength40842"
                                                                                        value={LJvestibuloplasty40842.LJlength40842}
                                                                                        onChange={handleLJVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJvtlength40842"
                                                                                        value={LJvestibuloplasty40842.LJvtlength40842}
                                                                                        onChange={handleLJVestibuloplasty40842}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40843 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("40843")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40843</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY40843}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8">
                                                                    <div className="row pt-2">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJblade40843"
                                                                                        value={LJvestibuloplasty40843.LJblade40843}
                                                                                        onChange={handleLJVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJlength40843"
                                                                                        value={LJvestibuloplasty40843.LJlength40843}
                                                                                        onChange={handleLJVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJvtlength40843"
                                                                                        value={LJvestibuloplasty40843.LJvtlength40843}
                                                                                        onChange={handleLJVestibuloplasty40843}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 40844 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("40844")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 40844</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{VESTIBULOPLASTY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-8 col-lg-8 ">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <label className="col-form-label">{BLADE}</label>
                                                                                </div>
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJblade"
                                                                                        value={LJvestibuloplasty.LJblade}
                                                                                        onChange={handleLJVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{MAIN_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJlength"
                                                                                        value={LJvestibuloplasty.LJlength}
                                                                                        onChange={handleLJVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row pt-2">
                                                                                <div className="col-xl-4 col-lg-7 col-md-5 col-sm-3">
                                                                                    <label className="col-form-label">{VERTICAL_INCISION_LENGTH}</label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJvtlength"
                                                                                        value={LJvestibuloplasty.LJvtlength}
                                                                                        onChange={handleLJVestibuloplasty}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 d-flex pt-2">{CM}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21248 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21248")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21248</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{IMPLANT_PARTIAL}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="d-flex " style={{ paddingTop: "5px" }}>
                                                                                1
                                                                                <input type="radio" className="mx-2" name="LJimplant-partial" value="1" onChange={handleLJImplantChange} />
                                                                                2
                                                                                <input type="radio" className="mx-2" name="LJimplant-partial" value="2" onChange={handleLJImplantChange} />
                                                                                3
                                                                                <input type="radio" className="mx-2" name="LJimplant-partial" value="3" onChange={handleLJImplantChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                    <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                        {POSTERIOR_CANINE_EMINENCE}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJImplantposteriorCanine"
                                                                                        value={LJImplantPartial.LJImplantposteriorCanine}
                                                                                        onChange={handleLJImplantPartialChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "4px" }}>
                                                                                    <label className="control-label">{MM}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 mt-md-2">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: "5px" }}>
                                                                            <label className="control-label">{IMPLANT_MANUFACTURE}</label>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="LJImplantManufacture"
                                                                                value={LJImplantPartial.LJImplantManufacture}
                                                                                onChange={handleLJImplantPartialChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingLeft: "18px" }}>
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "4px" }}>
                                                                                    {IMPLANT_SIZE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJImplantSize"
                                                                                    value={LJImplantPartial.LJImplantSize}
                                                                                    onChange={handleLJImplantPartialChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label">{DIAMETER}</label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJImplantDiameter"
                                                                                    value={LJImplantPartial.LJImplantDiameter}
                                                                                    onChange={handleLJImplantPartialChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-1 col-lg-2 col-md-2 col-sm-5 col-6" style={{ paddingTop: "4px", paddingLeft: "18px" }}>
                                                                        {NCM}
                                                                    </div>
                                                                    <div className="col-xl-7 col-lg-10 col-md-10 col-sm-6 col-6" style={{ padding: "4px 0px 0px 16px" }}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJImplantNcm"
                                                                            value={LJImplantPartial.LJImplantNcm}
                                                                            onChange={handleLJImplantPartialChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21249 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21249")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21249</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{IMPLANT_FULL_ARCH}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="d-flex " style={{ paddingTop: "5px" }}>
                                                                                4
                                                                                <input type="radio" name="LJimplant-full-arch" value="4" className="mx-2" onChange={handleLJImplantFullChange} />
                                                                                5
                                                                                <input type="radio" name="LJimplant-full-arch" value="5" className="mx-2" onChange={handleLJImplantFullChange} />
                                                                                6
                                                                                <input type="radio" name="LJimplant-full-arch" value="6" className="mx-2" onChange={handleLJImplantFullChange} />
                                                                                7
                                                                                <input type="radio" name="LJimplant-full-arch" value="7" className="mx-2" onChange={handleLJImplantFullChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                            <div className="row">
                                                                                <div className="col-xl-7 col-lg-6 col-md-6 col-sm-5 col-6">
                                                                                    <label className="control-label" style={{ paddingTop: "5px" }}>
                                                                                        {POSTERIOR_CANINE_EMINENCE}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="LJFullArchposteriorCanine"
                                                                                        value={LJImplantFullArch.LJFullArchposteriorCanine}
                                                                                        onChange={handleLJImplantFullArchChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2" style={{ paddingTop: "5px" }}>
                                                                                    <label className="control-label">{MM}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 mt-md-2">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: "5px" }}>
                                                                            <label className="control-label">{IMPLANT_MANUFACTURE}</label>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="LJFullArchManufacture"
                                                                                value={LJImplantFullArch.LJFullArchManufacture}
                                                                                onChange={handleLJImplantFullArchChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingLeft: "18px" }}>
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label" style={{ paddingTop: "4px" }}>
                                                                                    {IMPLANT_SIZE}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJFullArchSize"
                                                                                    value={LJImplantFullArch.LJFullArchSize}
                                                                                    onChange={handleLJImplantFullArchChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                                                        <div className="row">
                                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-6">
                                                                                <label className="control-label">{DIAMETER}</label>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-4 col-6">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="LJFullArchDiameter"
                                                                                    value={LJImplantFullArch.LJFullArchDiameter}
                                                                                    onChange={handleLJImplantFullArchChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-0 pt-3">
                                                                    <div className="offset-xl-4 col-xl-1 col-lg-2 col-md-2 col-sm-5 col-6" style={{ paddingTop: "4px", paddingLeft: "18px" }}>
                                                                        {NCM}
                                                                    </div>
                                                                    <div className="col-xl-7 col-lg-10 col-md-10 col-sm-6 col-6" style={{ padding: "4px 0px 0px 16px" }}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJFullArchNcm"
                                                                            value={LJImplantFullArch.LJFullArchNcm}
                                                                            onChange={handleLJImplantFullArchChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21215 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21215")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21215</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{BONE_GRAFT_LOWER_JAW}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row" style={{ paddingTop: "4px" }}>
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex">
                                                                            <div className="" style={{ paddingRight: "4px" }}>
                                                                                {LEFT}
                                                                            </div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft-lower" value="left" onChange={handleBone21215Change} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1">{RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft-lower" value="right" onChange={handleBone21215Change} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                            <div className="mx-1">{BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="bone-graft-lower" value="both" onChange={handleBone21215Change} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                                                                    <div className="row">
                                                                        <div className="col-12 d-flex">
                                                                            <div className=" p-0">
                                                                                <label className="col-form-label d-flex justify-content-start">{PATIENT_HARVEST_BONE}</label>
                                                                            </div>
                                                                            <div className=" d-flex mx-3">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="patientHarvst21215"
                                                                                    value={bone21215.patientHarvst21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>

                                                                            <div className=" d-flex mx-3">
                                                                                <label className="col-form-label d-flex justify-content-end">{CC}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-1 my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMBRANE_UNIT}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="membraneUnit21215"
                                                                                    value={bone21215.membraneUnit21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMEBRANE_TYPE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="membraneType21215"
                                                                                    value={bone21215.membraneType21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{MEMBRANE_SIZE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="membraneSize21215"
                                                                                    value={bone21215.membraneSize21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-4">
                                                                                <label className="control-label">{OTHER_BONE_MATERIAL}</label>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="membraneMaterial21215"
                                                                                    value={bone21215.membraneMaterial21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex my-2">{NOT_PATIENT_BONE}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{BONE_GRAFT_TYPE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="boneGraftType21215"
                                                                                    value={bone21215.boneGraftType21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{BONE_GRAFT_QUANTITY}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="boneGraftQuantity21215"
                                                                                    value={bone21215.boneGraftQuantity21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{TYPE_OF_SUTURE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="typeSiture21215"
                                                                                    value={bone21215.typeSiture21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-2" style={{ paddingRight: "4px" }}>
                                                                    <div className="offset-xl-4 col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                                <label className="control-label">{TYPE_OF_CLOSE_SUTURE}</label>
                                                                            </div>
                                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="closeSuture21215"
                                                                                    value={bone21215.closeSuture21215}
                                                                                    onChange={handleBone21215}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 41830 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("41830")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 41830</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{ALVEOLECTOMY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex " style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1">{LEFT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJAlveolectomy" value="left" onChange={handleLJAlveolectomyChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-1" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {RIGHT}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJAlveolectomy" value="right" onChange={handleLJAlveolectomyChange} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2" style={{ paddingTop: "4px" }}>
                                                                            <div className="mx-1"> {BOTH}</div>
                                                                            <div className="">
                                                                                <input type="radio" name="LJAlveolectomy" value="both" onChange={handleLJAlveolectomyChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* 21209 */}
                                                        {(procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && lowerCode.includes("21209")) || showAllQue ? (
                                                            <div className="form-group row">
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex">
                                                                            <div>
                                                                                <label className="control-label">CPT 21209</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 d-flex">
                                                                            <label className="control-label">{OSTEOPLASTY}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="row" style={{ paddingTop: "4px" }}>
                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex m-r-10">
                                                                                <div className="mx-1">{LEFT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209Side" value="left" onChange={handleLJ21209SideChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{RIGHT}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209Side" value="right" onChange={handleLJ21209SideChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{BOTH}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209Side" value="both" onChange={handleLJ21209SideChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="row">
                                                                        <div className="row" style={{ paddingTop: "4px" }}>
                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex m-r-10">
                                                                                <div className="mx-1">{BUCCAL}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209buclin" value="buccal" onChange={handleLJ21209BuclinChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{LINGUAL}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209buclin" value="lingual" onChange={handleLJ21209BuclinChange} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex mx-2">
                                                                                <div className="mx-1">{BOTH}</div>
                                                                                <div className="">
                                                                                    <input type="radio" name="LJ21209buclin" value="buclin" onChange={handleLJ21209BuclinChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <hr style={{ height: "0.2rem" }} />
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LOWER JAW ///////////////////// */}
                                        {/* //////////////////////   ARTICAINE_HCL_4_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{ARTICAINE_HCL_4_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJarticaine"
                                                                            value={LJarticaine.LJarticaine}
                                                                            onChange={handleLJArticaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-2" style={{ paddingTop: "1px" }}>
                                                                        <label className="control-label">{IAN}</label>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-10">
                                                                        <input type="radio" className="mx-2" name="LJarticaineRadio" value="left" onChange={handleLJArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="LJarticaineRadio" value="right" onChange={handleLJArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="LJarticaineRadio" value="both" onChange={handleLJArticaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   BUPIVACAINE_HCL_05_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{BUPIVACAINE_HCL_05_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJbupivacaine"
                                                                            value={LJbupivacaine.LJbupivacaine}
                                                                            onChange={handleLJBupivacaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-2" style={{ paddingTop: "1px" }}>
                                                                        <label className="control-label">{IAN}</label>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-10">
                                                                        <input type="radio" className="mx-2" name="LJupivacaineRadio" value="left" onChange={handleLJBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="LJbupivacaineRadio" value="right" onChange={handleLJBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="LJbupivacaineRadio" value="both" onChange={handleLJBupivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LIDICAINE_HCL_2_EPINEPHRINE ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{LIDICAINE_HCL_2_EPINEPHRINE}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJlidicaine"
                                                                            value={LJlidicaine.lLJidicaine}
                                                                            onChange={handleLJLidicaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-2" style={{ paddingTop: "1px" }}>
                                                                        <label className="control-label">{IAN}</label>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-10">
                                                                        <input type="radio" className="mx-2" name="LJlidicaineRadio" value="left" onChange={handleLJLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="LJlidicaineRadio" value="right" onChange={handleLJLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="LJlidicaineRadio" value="both" onChange={handleLJLidicaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   LIDICAINE_HCL_2_EPINEPHRINE_50 ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{LIDICAINE_HCL_2_EPINEPHRINE_50}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJlidicaine2"
                                                                            value={LJlidicaine2.LJlidicaine2}
                                                                            onChange={handleLJLidicaine2Change}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-2" style={{ paddingTop: "1px" }}>
                                                                        <label className="control-label">{IAN}</label>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-10">
                                                                        <input type="radio" className="mx-2" name="LJlidicaine2Radio" value="left" onChange={handleLJLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="LJlidicaine2Radio" value="right" onChange={handleLJLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="LJlidicaine2Radio" value="both" onChange={handleLJLidicaine2RadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   MEPIVACAINE_HCL ///////////////////// */}
                                        {procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) || showAllQue ? (
                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{MEPIVACAINE_HCL}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-6">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="LJmepivacaine"
                                                                            value={LJmepivacaine.LJmepivacaine}
                                                                            onChange={handleLJMepivacaineChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-2" style={{ paddingTop: "1px" }}>
                                                                        <label className="control-label">{IAN}</label>
                                                                    </div>
                                                                    <div className="btn-group btn-group-solid col-10">
                                                                        <input type="radio" className="mx-2" name="LJmepivacaineRadio" value="left" onChange={handleLJMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-primary my-1">{LEFT}</span>
                                                                        <input type="radio" className="mx-2" name="LJmepivacaineRadio" value="right" onChange={handleLJMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-success my-1">{RIGHT}</span>
                                                                        <input type="radio" className="mx-2" name="LJmepivacaineRadio" value="both" onChange={handleLJMepivacaineRadioChange} />
                                                                        <span className="badge badge-pill badge-danger my-1">{BOTH}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* //////////////////////   EXTRA QUESTION ///////////////////// */}
                                        <div>
                                            {/* //////////////////////   NITROUS_ADMINISTERED ///////////////////// */}
                                            <hr style={{ height: "0.2rem" }} />

                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-6 ">{NITROUS_ADMINISTERED}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-3">
                                                                        <input type="text" className="form-control" name="nitrousAdmin" value={nitrous.nitrousAdmin} onChange={handleNitrousChange} />
                                                                    </div>
                                                                    <div className="col-8" style={{ paddingTop: "5px" }}>
                                                                        <label className="control-label">{NITROUS_QSTN1}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="btn-group btn-group-solid col-3">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="nitrousDuration"
                                                                            value={nitrous.nitrousDuration}
                                                                            onChange={handleNitrousChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-8" style={{ paddingTop: "5px" }}>
                                                                        <label className="control-label">{NITROUS_QSTN2}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* //////////////////////   BLOOD_PRESSURE ///////////////////// */}
                                            <hr style={{ height: "0.2rem" }} />

                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{BLOOD_PRESSURE}</div>
                                                            <div className="col-4 ">
                                                                <input type="text" className="form-control" value={bp} onChange={handleBpchange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* //////////////////////   HEART_RATE ///////////////////// */}
                                            <hr style={{ height: "0.2rem" }} />

                                            <div>
                                                <div className="row my-3">
                                                    <div className="col-4">
                                                        <div className="row">
                                                            <div className="col-8 ">{HEART_RATE}</div>
                                                            <div className="col-4 ">
                                                                <input type="text" className="form-control" value={hr} onChange={handleHrchange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ height: "0.2rem" }} />

                                        {/* /////////////////////////////// EXTRA DETAILS ///////////////////////////// */}
                                        <div>
                                            <div className="row my-3 d-flex">
                                                <div className="col-4">
                                                    <div className="row">
                                                        <div className="col-8 ">{ESTIMATED_BLOOD_LOSS}</div>
                                                        <div className="col-4 ">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1">{CC}</div>
                                            </div>
                                            <div className="row my-3 d-flex">
                                                <div className="col-4">
                                                    <div className="row">
                                                        <div className="col-8 ">{CONSULTATION_TIME}</div>
                                                        <div className="col-2 ">
                                                            <input type="text" className="form-control" value={consulationTime} name="consulationTime" onChange={handleConsulationChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1">{MINUTES}</div>
                                            </div>
                                            <div className="my-3  d-flex">
                                                <div className="col-1">{START_TIME}</div>
                                                <div className="col-2">
                                                    <input type="time" className="form-control" name="startTime" value={time.startTime} onChange={handleTimeChange} />
                                                </div>
                                                <div className="mx-4 my-4"></div>
                                                <div className="mx-4 my-4"></div>
                                                <div className="col-1">{END_TIME}</div>
                                                <div className="col-2">
                                                    <input type="time" className="form-control" name="endTime" value={time.endTime} onChange={handleTimeChange} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* /////////////////////////////// BUTTONS ///////////////////////////// */}

                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-primary mx-2" onClick={handleNext}>
                                                {NEXT}
                                            </button>
                                            <button className="btn btn-default" onClick={handleCancel}>
                                                {CANCEL}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {step == 2 && isLoading == false ? (
                            <OpNotesText
                                time={time}
                                surgical={surgical}
                                removal={removal}
                                excision={excision}
                                vestibuloplasty40840={vestibuloplasty40840}
                                vestibuloplasty40842={vestibuloplasty40842}
                                vestibuloplasty40843={vestibuloplasty40843}
                                vestibuloplasty={vestibuloplasty}
                                vestibuloplasty40845={vestibuloplasty40845}
                                sinus={sinus}
                                partial={partial}
                                ImplantPartial={ImplantPartial}
                                fullArch={fullArch}
                                ImplantFullArch={ImplantFullArch}
                                proc={proc}
                                bone={bone}
                                flap={flap}
                                code={diagnoses}
                                claimDetail={claimDetail}
                                bloodDraw={bloodDraw}
                                consulationTime={consulationTime}
                                procedureType={procedureType}
                                procedure={procedure}
                                osteoplasty={osteoplasty}
                                LJbloodDraw={LJbloodDraw}
                                LJsurgical={LJsurgical}
                                LJremoval={LJremoval}
                                LJexcision={LJexcision}
                                LJvestibuloplasty40840={LJvestibuloplasty40840}
                                LJvestibuloplasty40842={LJvestibuloplasty40842}
                                LJvestibuloplasty40843={LJvestibuloplasty40843}
                                LJvestibuloplasty={LJvestibuloplasty}
                                LJsinus={LJsinus}
                                LJpartial={LJpartial}
                                LJImplantPartial={LJImplantPartial}
                                LJfullArch={LJfullArch}
                                LJImplantFullArch={LJImplantFullArch}
                                LJbone={LJbone}
                                bone21215={bone21215}
                                bp={bp}
                                hr={hr}
                                alveolectomy={alveolectomy}
                                LJalveolectomy={LJalveolectomy}
                                isLoading={isLoading}
                                articaine={articaine}
                                bupivacaine={bupivacaine}
                                lidicaine={lidicaine}
                                lidicaine2={lidicaine2}
                                mepivacaine={mepivacaine}
                                LJarticaine={LJarticaine}
                                LJbupivacaine={LJbupivacaine}
                                LJlidicaine={LJlidicaine}
                                LJlidicaine2={LJlidicaine2}
                                LJmepivacaine={LJmepivacaine}
                                LJosteoplasty={LJosteoplasty}
                                nitrous={nitrous}
                                signatureImage={signatureImage}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpNotes;
