import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { APPROVE_DOCTOR_API, PENDING_MAIN_DOCTOR_API, REJECT_DOCTOR_API } from "../../../Helper/ApiPath";
import {
    ADDRESS,
    APPROVE,
    BIRTH_DATE,
    CHOOSE_DOCTOR_TYPE,
    DASH,
    DOCTOR_CODE,
    DOCTOR_NAME,
    DOCTOR_SPECIALITY,
    EMAIL,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    NPI1,
    NPI2,
    PENDING_REQUEST,
    PHONE_NO,
    PTAN_NUMBER,
    REJECT,
    SSN,
    TAXID,
} from "../../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import { APPROVE_MESSAGE, REJECT_MESSAGE } from "../../../Helper/Messages";
import Swal from "sweetalert2";

function PendingDoctorDetail() {
    let navigate = useNavigate();
    const doctorId = localStorage.getItem("pendingDoctorId");
    const token = JSON.parse(localStorage.getItem("logToken"));

    const [officeList, setOfficeList] = useState([]);
    const [mainDoctorData, setMainDoctorList] = useState([]);

    const fetchOfficeData = async () => {
        await axios
            .post(`${baseURL}/${PENDING_MAIN_DOCTOR_API}`, {
                headers: { authorization: token.token },
                doctorId: doctorId,
            })
            .then((res) => {
                setMainDoctorList(res.data.data.maindr);
                setOfficeList(res.data.data.officedata);
            });
    };

    const handleApprove = async () => {
        Swal.fire({
            title: APPROVE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${APPROVE_DOCTOR_API}`, {
                            headers: { authorization: token.token },
                            doctorId: doctorId,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");

                            document.getElementById("reject").classList.add("d-none");
                            document.getElementById("approve").classList.add("d-none");
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleReject = async () => {
        Swal.fire({
            title: REJECT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, reject it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${REJECT_DOCTOR_API}`, {
                            headers: { authorization: token.token },
                            doctorId: doctorId,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success").then(() => {
                                navigate("/dashboard");
                            });

                            document.getElementById("reject").classList.add("d-none");
                            document.getElementById("approve").classList.add("d-none");
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchOfficeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{PENDING_REQUEST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{PENDING_REQUEST}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-9">
                            {Object.keys(officeList).map((item, i) => (
                                <div key={i} className="report">
                                    <div className="card">
                                        <div key={i} className="card-head">
                                            <header>{officeList[item][0].office.name}</header>
                                        </div>
                                        <div className="card-body no-padding height-9">
                                            <div className="table-responsive mt-2" key={i}>
                                                <p>
                                                    <b>{ADDRESS} : </b>
                                                    {officeList[item][0].office.address},{officeList[item][0].cityName}, {officeList[item][0].stateName} - {officeList[item][0].office.zip}
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b>{PHONE_NO} :</b>
                                                    {officeList[item][0].office.phoneNo1}
                                                </p>
                                                <hr className="my-2" style={{ color: "black", height: "0.2rem", width: "100%" }} />
                                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{DOCTOR_NAME}</th>
                                                            <th>{GENDER}</th>
                                                            <th>{BIRTH_DATE}</th>
                                                            <th>{PHONE_NO}</th>
                                                            <th>{NPI1}</th>
                                                            <th>{NPI2}</th>
                                                            <th>{SSN}</th>
                                                            <th>{TAXID}</th>
                                                            <th>{DOCTOR_SPECIALITY}</th>
                                                            <th>{LICENSE_NUMBER}</th>
                                                            <th>{PTAN_NUMBER}</th>
                                                        </tr>
                                                    </thead>
                                                    {officeList[item].map((office, i) => (
                                                        <>
                                                            <tbody key={i}>
                                                                <tr className="odd gradeX">
                                                                    <td>
                                                                        {office.doctor.firstName} {office.doctor.lastName}
                                                                    </td>
                                                                    <td>{office.doctor.gender ? office.doctor.gender : DASH}</td>
                                                                    <td>{office.doctor.dob ? dateFormateMonthDateYear(office.doctor.dob) : DASH}</td>
                                                                    <td>{office.doctor.phoneNo1 ? office.doctor.phoneNo1 : DASH}</td>
                                                                    <td>{office.doctor.npi1 ? office.doctor.npi1 : DASH}</td>
                                                                    <td>{office.doctor.npi2 ? office.doctor.npi2 : DASH}</td>
                                                                    <td>{office.doctor.ssn ? office.doctor.ssn : DASH}</td>
                                                                    <td>{office.doctor.taxId ? office.doctor.taxId : DASH}</td>
                                                                    <td>
                                                                        {office.doctor.speciality
                                                                            ? Object.keys(CHOOSE_DOCTOR_TYPE).find((key) => CHOOSE_DOCTOR_TYPE[key] == office.doctor.speciality)
                                                                            : DASH}
                                                                    </td>
                                                                    <td>{office.doctor.licenseNumber ? office.doctor.licenseNumber : DASH}</td>
                                                                    <td>{office.doctor.ptanNumber ? office.doctor.ptanNumber : DASH}</td>
                                                                </tr>
                                                            </tbody>
                                                        </>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-3">
                            <div className="card">
                                <div className="card-body no-padding height-9">
                                    <div className="profile-usertitle">
                                        <div className="profile-usertitle-name">
                                            Dr.{mainDoctorData.firstName} {mainDoctorData.lastName}
                                        </div>
                                        <div className="profile-usertitle-job">
                                            {mainDoctorData.speciality ? Object.keys(CHOOSE_DOCTOR_TYPE).find((key) => CHOOSE_DOCTOR_TYPE[key] == mainDoctorData.speciality) : DASH}{" "}
                                        </div>
                                    </div>
                                    <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                            <b>{DOCTOR_CODE}</b> <a className="pull-right">{mainDoctorData.code ? mainDoctorData.code : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{EMAIL}</b> <a className="pull-right">{mainDoctorData.email ? mainDoctorData.email : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{FIRST_NAME}</b> <a className="pull-right">{mainDoctorData.firstName ? mainDoctorData.firstName : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{LAST_NAME}</b> <a className="pull-right">{mainDoctorData.lastName ? mainDoctorData.lastName : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{GENDER}</b> <a className="pull-right">{mainDoctorData.gender ? mainDoctorData.gender : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{BIRTH_DATE}</b> <a className="pull-right">{mainDoctorData.dob ? dateFormateMonthDateYear(mainDoctorData.dob) : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PHONE_NO}</b> <a className="pull-right">{mainDoctorData.phoneNo1 ? mainDoctorData.phoneNo1 : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{NPI1}</b> <a className="pull-right">{mainDoctorData.npi1 ? mainDoctorData.npi1 : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{NPI2}</b> <a className="pull-right">{mainDoctorData.npi2 ? mainDoctorData.npi2 : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{SSN}</b> <a className="pull-right">{mainDoctorData.ssn ? mainDoctorData.ssn : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{TAXID}</b> <a className="pull-right">{mainDoctorData.taxId ? mainDoctorData.taxId : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{LICENSE_NUMBER}</b> <a className="pull-right">{mainDoctorData.licenseNumber ? mainDoctorData.licenseNumber : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PTAN_NUMBER}</b> <a className="pull-right">{mainDoctorData.ptanNumber ? mainDoctorData.ptanNumber : DASH}</a>
                                        </li>
                                    </ul>

                                    <div className="profile-userbuttons mt-3">
                                        <button type="button" className="btn btn-circle green btn-sm" id="approve" onClick={handleApprove}>
                                            {APPROVE}
                                        </button>
                                        <button type="button" className="btn btn-circle red btn-sm button" id="reject" onClick={handleReject}>
                                            {REJECT}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingDoctorDetail;
