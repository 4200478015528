module.exports = {
    //////////////////////// LOCAL ////////////////////////////////

    // baseURL: "http://54.176.8.52:5001",
    //frontURL: "http://54.176.8.52:5002",
    baseURL: "https://newapi.billwithus.com",
    frontURL: "https://new.billwithus.com",
    showHomePage: true,
    downLoadDynamicExcel: false,
    imageSize_file: 5000000, //Normal File
    imageSize_video: 200000000, // Video File
    showAllQue: false,

    //////////////////////// LIVE ////////////////////////////////

    // baseURL: "https://bwuapi.billwithus.com",
    // frontURL: "https://billwithus.com",
    // showHomePage: false,
    // downLoadDynamicExcel:false,
    // imageSize_file: 2000000,
    // imageSize_video: 200000000,
    //  showAllQue:false,

    //////////////////////// DEV ////////////////////////////////

    // baseURL: "https://onemedallapi.raininfotech.dev",
    // frontURL: "https://onemedall.raininfotech.dev",
    // showHomePage: true,
    // downLoadDynamicExcel:false,
    // imageSize_file: 2000000,
    // imageSize_video: 200000000,
    //  showAllQue:false,

    //////////////////////// OP-NOTE-TEXT ////////////////////////////////
    // change this value with the exact value which you want to show first in op note diagnoses section
    // you can only enter one value here

    DIAGNOSE_CODE: "M26.4",
};
