import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ADMIN_DASHBOARD_API, OFFICE_EMPLOYEE_LIST_API, PENDING_DOCTOR_LIST_API, WEEKLY_CLAIM_LIST_API, WEEKLY_PRE_AUTHORIZATION_LIST_API } from "../../Helper/ApiPath";
import {
    ACTION,
    CHOOSE_DOCTOR_TYPE,
    CLAIM_COUNT_PER_DOCTOR,
    CREATED_DATE,
    SUBMITTED_DATE,
    CREATE_BY,
    DASH,
    DASHBOARD,
    DATE,
    DATE_OF_SERVICE,
    DOCTOR,
    DOCTOR_SPECIALITY,
    EMAIL,
    FIRST_NAME,
    LAST_1_WEEK_CLAIM_LIST,
    NEW_PRE_AUTHORIZATION_REQUEST,
    LAST_NAME,
    NEW_REGISTRATION_REQUEST,
    PATIENT,
    PENDING_REQUEST,
    PRE_AUTH_STATUS,
    ROLES,
    SEARCH,
    STATUS,
    TOTAL_CLAIM,
    TOTAL_DOCTOR,
    TOTAL_PATIENT,
    VIEW,
    NEW_OFFICE_EMPLOYEE_REQUEST,
    OFFICE_EMPLOYEE_STATUS,
    ACCESSLEVEL,
    OFFICE,
} from "../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import NoDataFound from "../CommonComponent/NoDataFound";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";

export default function AdminDashboard() {
    const token = JSON.parse(localStorage.getItem("logToken"));
    const [claimSearLoader, setClaimSearLoader] = useState(0);

    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    // Date Filter
    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        dateFrom: new Date(date.setMonth(date.getMonth() - 1)),
        dateTo: new Date(),
    });

    const [dashboard, setDashboard] = useState([]);
    const [doctorWithTotalClaim, setDoctorWithTotalClaim] = useState([]);
    let navigate = useNavigate();

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_DASHBOARD_API}`, {
                headers: { authorization: token.token },
                dateFrom:
                    filterValues.dateFrom.getFullYear() +
                    "-" +
                    ("0" + (filterValues.dateFrom.getMonth() + 1).toString()).slice(-2) +
                    "-" +
                    ("0" + filterValues.dateFrom.getDate().toString()).slice(-2),
                dateTo: filterValues.dateTo.getFullYear() + "-" + ("0" + (filterValues.dateTo.getMonth() + 1).toString()).slice(-2) + "-" + ("0" + filterValues.dateTo.getDate().toString()).slice(-2),
            })
            .then((res) => {
                setClaimSearLoader(false);
                setDashboard(res.data.data);
                setDoctorWithTotalClaim(res.data.data.doctorWithTotalClaim);
            })
            .catch((err) => {
                setClaimSearLoader(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleSearch = () => {
        if (!claimSearLoader) {
            setClaimSearLoader(true);
            fetchData();
        }
    };

    const [weeklyClaimList, setWeeklyClaimList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
    };

    const fetchWeeklyClaimData = async () => {
        await axios
            .post(`${baseURL}/${WEEKLY_CLAIM_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: 10,
            })
            .then((res) => {
                setWeeklyClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [weeklyPreAuthorizationList, setWeeklyPreAuthorizationList] = useState([]);
    const [PreAuthorizationOffset, setPreAuthorizationOffset] = useState(1);
    const [PreAuthorizationRowCount, setPreAuthorizationRowCount] = useState(0);
    const [PreAuthorizationPageCount, setPreAuthorizationPageCount] = useState(0);

    const handlePreAuthorizationPageClick = (event) => {
        const selectedPage = event.selected;
        setPreAuthorizationOffset(selectedPage + 1);
    };

    const fetchWeeklyPreAuthorizationNoteData = async () => {
        await axios
            .post(`${baseURL}/${WEEKLY_PRE_AUTHORIZATION_LIST_API}`, {
                headers: { authorization: token.token },
                page: PreAuthorizationOffset,
                rowsPerPage: 10,
            })
            .then((res) => {
                setWeeklyPreAuthorizationList(res.data.data);
                setPreAuthorizationPageCount(res.data.extraData.pageCount);
                setPreAuthorizationRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [pendingDoctorList, setPendingDoctorList] = useState([]);
    const [offsetDoctor, setOffsetDoctor] = useState(1);
    const [rowCountDoctor, setRowCountDoctor] = useState(0);
    const [pageCountDoctor, setPageCountDoctor] = useState(0);

    const handleDoctorPageClick = (event) => {
        const selectedPage = event.selected;
        setOffsetDoctor(selectedPage + 1);
    };
    const fetchPendingDoctorData = async () => {
        await axios
            .post(`${baseURL}/${PENDING_DOCTOR_LIST_API}`, {
                headers: { authorization: token.token },
                page: offsetDoctor,
                rowsPerPage: 5,
            })
            .then((res) => {
                setPendingDoctorList(res.data.data);
                setPageCountDoctor(res.data.extraData.pageCount);
                setRowCountDoctor(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [employeeList, setEmployeeList] = useState([]);
    const [offsetOfficeEmp, setOffsetOfficeEmp] = useState(1);
    const [rowCountOfficeEmp, setRowCountOfficeEmp] = useState(0);
    const [pageCountOfficeEmp, setPageCountOfficeEmp] = useState(0);

    const handleOfficeEmpPageClick = (event) => {
        const selectedPage = event.selected;
        setOffsetOfficeEmp(selectedPage + 1);
    };

    const fetchOfficEemployeeData = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_EMPLOYEE_LIST_API}`, {
                headers: { authorization: token.token },
                page: offsetOfficeEmp,
                rowsPerPage: 5,
                isPagination: true,
                search: "",
                statusId: OFFICE_EMPLOYEE_STATUS.Pending,
                accessLvl: "",
                officeId: "",
            })
            .then((res) => {
                setEmployeeList(res.data.data);
                setPageCountOfficeEmp(res.data.extraData.pageCount);
                setRowCountOfficeEmp(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleView = (id) => {
        localStorage.setItem("pendingDoctorId", id);
        navigate("/viewPendingDoctor");
    };

    const handleOfficeEmpView = (id, officeDoctorId, officeId) => {
        localStorage.setItem("officeEmployeeId", id);
        localStorage.setItem("officeDoctorId", officeDoctorId);
        localStorage.setItem("officeId", officeId);
        navigate("/viewOfficeEmployee");
    };

    const handleClaim = (id) => {
        sessionStorage.setItem("claimDoctorId", id);
        sessionStorage.removeItem("claimPatientId");
    };

    const handlePatientClaim = (id) => {
        sessionStorage.setItem("claimPatientId", id);
        sessionStorage.removeItem("claimDoctorId");
    };

    const handlePreAuthorizationNote = (id) => {
        sessionStorage.setItem("preAuthDoctorId", id);
        sessionStorage.removeItem("preAuthPagination");
    };

    useEffect(() => {
        fetchData();
        fetchWeeklyClaimData();
        fetchWeeklyPreAuthorizationNoteData();
        fetchPendingDoctorData();
        fetchOfficEemployeeData();
    }, [offset, offsetDoctor, PreAuthorizationOffset]);

    const totalClaimList = () => {
        sessionStorage.removeItem("claimDoctorId");
        sessionStorage.removeItem("claimPatientId");
        navigate("/manageClaim");
    };

    const totalDoctorList = () => {
        navigate("/manageDoctor");
    };

    const totalPatientList = () => {
        navigate("/managePatient");
    };

    const totalRequestData = () => {
        navigate("/requestData");
    };

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content" style={{ minHeight: "600px" }}>
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{DASHBOARD}</div>
                            </div>
                        </div>
                    </div>
                    <div className="state-overview">
                        <div className="row">
                            <div className={`${role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN ? "col-xl-3 col-md-6 col-12" : "col-xl-4 col-md-6 col-12"}`}>
                                <div onClick={totalClaimList} style={{ cursor: "pointer" }}>
                                    <div className="info-box bg-blue">
                                        <span className="info-box-icon mt-2">
                                            <i className="fa fa-drivers-license-o"></i>
                                        </span>
                                        <div className="info-box-content">
                                            <span className="info-box-text">{TOTAL_CLAIM}</span>
                                            <span className="info-box-number">{dashboard.totalClaims && dashboard.totalClaims.toLocaleString("en-US")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN ? "col-xl-3 col-md-6 col-12" : "col-xl-4 col-md-6 col-12"}`}>
                                <div className="info-box bg-orange" onClick={totalDoctorList} style={{ cursor: "pointer" }}>
                                    <span className="info-box-icon mt-2">
                                        <i className="fa fa-user-md"></i>
                                    </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">{TOTAL_DOCTOR}</span>
                                        <span className="info-box-number">{dashboard.totalDoctors && dashboard.totalDoctors.toLocaleString("en-US")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN ? "col-xl-3 col-md-6 col-12" : "col-xl-4 col-md-6 col-12"}`}>
                                <div className="info-box bg-success" onClick={totalPatientList} style={{ cursor: "pointer" }}>
                                    <span className="info-box-icon mt-2">
                                        <i className="fa fa-wheelchair"></i>
                                    </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">{TOTAL_PATIENT}</span>
                                        <span className="info-box-number">{dashboard.totalPatients && dashboard.totalPatients.toLocaleString("en-US")}</span>
                                    </div>
                                </div>
                            </div>

                            {role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN ? (
                                <div className="col-xl-3 col-md-6 col-12" onClick={totalRequestData} style={{ cursor: "pointer" }}>
                                    <div className="info-box bg-purple">
                                        <span className="info-box-icon mt-2">
                                            <i className="fa fa-hourglass-end"></i>
                                        </span>
                                        <div className="info-box-content">
                                            <span className="info-box-text">{PENDING_REQUEST}</span>
                                            <span className="info-box-number">{dashboard.totalRequestDatas && dashboard.totalRequestDatas.toLocaleString("en-US")}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="row">
                        {/* ///////////////////////////////////////////////////////////////// CLAIM LIST //////////////////////////////////////////////////// */}

                        <div className="col-xl-5 col-md-12 col-lg-12 col-sm-12">
                            <div className="card">
                                <div className="card-head">
                                    <header>{CLAIM_COUNT_PER_DOCTOR}</header>
                                </div>

                                <div className="row mt-4 mx-2">
                                    <div className="btn-group col-md-5 pr-0">
                                        <DatePicker
                                            className="form-control mr-sm-2"
                                            selected={filterValues.dateFrom}
                                            maxDate={filterValues.dateTo}
                                            yearDropdownItemNumber={100}
                                            scrollableMonthDropdown={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            onChange={(date) => setFilterValues({ ...filterValues, dateFrom: date })}
                                        />
                                        <span className="todate">To</span>
                                    </div>

                                    <div className="btn-group col-md-4">
                                        <DatePicker
                                            className="form-control mr-sm-2"
                                            selected={filterValues.dateTo}
                                            minDate={filterValues.dateFrom}
                                            maxDate={new Date()}
                                            yearDropdownItemNumber={100}
                                            scrollableMonthDropdown={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            onChange={(date) => setFilterValues({ ...filterValues, dateTo: date })}
                                        />
                                    </div>

                                    <div className="btn-group col-3">
                                        <button id="addRow" className="btn btn-info" onClick={() => handleSearch()}>
                                            {claimSearLoader ? <i className="spinner-border"></i> : ""}
                                            {SEARCH}
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body no-padding height-9">
                                    <div className="performance-list">
                                        {!doctorWithTotalClaim.length ? (
                                            <>
                                                <hr style={{ color: "black", height: "2px" }} />
                                                <p style={{ color: "red" }} className="text-center">
                                                    {" "}
                                                    <img alt="" src="assets/img/NoDataFound/no_data_found.png" style={{ height: "200px" }} />
                                                </p>
                                            </>
                                        ) : (
                                            doctorWithTotalClaim &&
                                            doctorWithTotalClaim.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        <Link to="/manageClaim" style={{ textDecoration: "underline", color: "#2a32be" }} onClick={() => handleClaim(item.id)}>
                                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                            {item.firstName} {item.lastName}
                                                            <span className="pull-right">{item.totalClaim}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-md-12 col-lg-12 col-sm-12">
                            {/* ///////////////////////////////////////////////////////////////// RECENT CLAIM LIST //////////////////////////////////////////////////// */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card dashboard-card">
                                        <div className="card-head">
                                            <header>{LAST_1_WEEK_CLAIM_LIST}</header>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="table-scrollable">
                                                <table className="table dashboard-table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{SUBMITTED_DATE}</th>
                                                            <th>{DATE_OF_SERVICE}</th>
                                                            <th>{DOCTOR}</th>
                                                            <th>{PATIENT}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!weeklyClaimList ? (
                                                            <NoDataFound />
                                                        ) : (
                                                            weeklyClaimList &&
                                                            weeklyClaimList.map((item, i) => {
                                                                return (
                                                                    <tr className="odd gradeX" key={i}>
                                                                        <td>{item.isSubmitted == 1 ? dateFormateMonthDateYear(item.submitted_date) : DASH}</td>
                                                                        <td>{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                        <td>
                                                                            <Link
                                                                                to="/manageClaim"
                                                                                style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                onClick={() => handleClaim(item.doctor.uniqueId)}
                                                                            >
                                                                                {item.doctor.firstName} {item.doctor.lastName}
                                                                            </Link>{" "}
                                                                        </td>
                                                                        <td>
                                                                            {" "}
                                                                            <Link
                                                                                to="/manageClaim"
                                                                                style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                onClick={() => handlePatientClaim(item.patient.uniqueId)}
                                                                            >
                                                                                {item.patient.firstName} {item.patient.lastName}{" "}
                                                                            </Link>{" "}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="mt-3">Total {weeklyClaimList == null ? "0" : rowCount} Claim</div>
                                                <div className="pagination-center">
                                                    <ReactPaginate
                                                        initialPage={0}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={0}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        disableInitialCallback={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ///////////////////////////////////////////////////////////////// RECENT PRE AUTHORIZATION LIST //////////////////////////////////////////////////// */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card dashboard-card">
                                        <div className="card-head">
                                            <header>{NEW_PRE_AUTHORIZATION_REQUEST}</header>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="table-scrollable">
                                                <table className="table dashboard-table table-hover table-checkable order-column full-width" id="example4">
                                                    <thead>
                                                        <tr>
                                                            <th>{DOCTOR}</th>
                                                            <th>{PATIENT}</th>
                                                            <th>{DATE_OF_SERVICE}</th>
                                                            <th>{CREATED_DATE}</th>
                                                            <th>{CREATE_BY}</th>
                                                            <th>{STATUS}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!weeklyPreAuthorizationList ? (
                                                            <NoDataFound />
                                                        ) : (
                                                            weeklyPreAuthorizationList &&
                                                            weeklyPreAuthorizationList.map((item, i) => {
                                                                return (
                                                                    <tr className="odd gradeX" key={i}>
                                                                        <td className="left">
                                                                            <Link
                                                                                to="/manage-preAuthorization-notes"
                                                                                style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                onClick={() => handlePreAuthorizationNote(item.doctor.uniqueId)}
                                                                            >
                                                                                {item.doctor.firstName} {item.doctor.lastName}
                                                                            </Link>{" "}
                                                                        </td>
                                                                        <td className="left">
                                                                            {" "}
                                                                            {item.patient.firstName} {item.patient.lastName}
                                                                        </td>
                                                                        <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>

                                                                        <td className="left">{dateFormateMonthDateYear(item.created_at)}</td>
                                                                        <td className="left">
                                                                            <div data-toggle="tooltip" title={item.createBy.email}>
                                                                                {item.createBy && item.createBy.email ? item.createBy.email.length > 10 ? item.createBy.email.slice(0, 10) + "..." : item.createBy.email : DASH}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {" "}
                                                                            <span
                                                                                className={
                                                                                    "badge " +
                                                                                    (item.statusId == 2
                                                                                        ? " btn-dark"
                                                                                        : item.statusId == 1
                                                                                        ? "activeStatus btn-success"
                                                                                        : item.statusId == 5
                                                                                        ? "btn-warning"
                                                                                        : item.statusId == 7
                                                                                        ? "btn-info"
                                                                                        : item.statusId == 8
                                                                                        ? "btn-primary"
                                                                                        : "btn-danger")
                                                                                }
                                                                            >
                                                                                {Object.keys(PRE_AUTH_STATUS).find((key) => PRE_AUTH_STATUS[key] === item.statusId)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="mt-3">Total {weeklyPreAuthorizationList == null ? "0" : PreAuthorizationRowCount} Pre-authorization notes</div>
                                                <div className="pagination-center">
                                                    <ReactPaginate
                                                        initialPage={0}
                                                        pageCount={PreAuthorizationPageCount}
                                                        marginPagesDisplayed={0}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePreAuthorizationPageClick}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        disableInitialCallback={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ///////////////////////////////////////////////////////////////// RECENT DOCTOR LIST //////////////////////////////////////////////////// */}

                            {role == ROLES.EMPLOYEE ? (
                                ""
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card dashboard-card">
                                            <div className="card-head">
                                                <header>{NEW_REGISTRATION_REQUEST}</header>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="table-scrollable">
                                                    <table className="table dashboard-table table-hover table-checkable order-column full-width" id="example4">
                                                        <thead>
                                                            <tr>
                                                                <th>{DATE}</th>
                                                                <th>{FIRST_NAME}</th>
                                                                <th>{LAST_NAME}</th>
                                                                <th>{DOCTOR_SPECIALITY}</th>
                                                                <th>{EMAIL} </th>
                                                                <th>{ACTION} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!pendingDoctorList ? (
                                                                <NoDataFound />
                                                            ) : (
                                                                pendingDoctorList &&
                                                                pendingDoctorList.map((item, i) => {
                                                                    return (
                                                                        <tr className="odd gradeX" key={i}>
                                                                            <td> {item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH} </td>
                                                                            <td>{item.firstName}</td>
                                                                            <td> {item.lastName}</td>
                                                                            <td>
                                                                                {" "}
                                                                                {item.speciality ? Object.keys(CHOOSE_DOCTOR_TYPE).find((key) => CHOOSE_DOCTOR_TYPE[key] == item.speciality) : DASH}
                                                                            </td>
                                                                            <td className="left">
                                                                                <div data-toggle="tooltip" title={item.email}>
                                                                                    {item && item.email ? (item.email.length > 10 ? item.email.slice(0, 10) + "..." : item.email) : DASH}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-circle btn-info"
                                                                                    onClick={() => handleView(item.id)}
                                                                                >
                                                                                    {VIEW}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mt-3">Total {pendingDoctorList == null ? "0" : rowCountDoctor} Doctor</div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={pageCountDoctor}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleDoctorPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel="Previous"
                                                            nextLabel="Next"
                                                            disableInitialCallback={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* ///////////////////////////////////////////////////////////////// RECENT OFFICE EMPLOYEE LIST //////////////////////////////////////////////////// */}

                            {role == ROLES.EMPLOYEE ? (
                                ""
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card dashboard-card">
                                            <div className="card-head">
                                                <header>{NEW_OFFICE_EMPLOYEE_REQUEST}</header>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="table-scrollable">
                                                    <table className="table dashboard-table table-hover table-checkable order-column full-width" id="example4">
                                                        <thead>
                                                            <tr>
                                                                <th>{DATE}</th>
                                                                <th>{OFFICE}</th>
                                                                <th>{FIRST_NAME}</th>
                                                                <th>{ACCESSLEVEL}</th>
                                                                <th>{EMAIL} </th>
                                                                <th>{ACTION} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!employeeList ? (
                                                                <NoDataFound />
                                                            ) : (
                                                                employeeList &&
                                                                employeeList.map((item, i) => {
                                                                    return (
                                                                        <tr className="odd gradeX" key={i}>
                                                                            <td> {item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH} </td>
                                                                            <td> {item.officeName ? item.officeName : DASH}</td>
                                                                            <td>{item.firstName ? item.firstName : DASH}</td>
                                                                            <td>{item.pageAuthorityRoleName ? item.pageAuthorityRoleName : item.roleName ? item.roleName : DASH}</td>
                                                                            <td className="left">
                                                                                <div data-toggle="tooltip" title={item.email}>
                                                                                    {item && item.email ? (item.email.length > 10 ? item.email.slice(0, 10) + "..." : item.email) : DASH}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-circle btn-info"
                                                                                    onClick={() => handleOfficeEmpView(item.id, item.officeDoctorId, item.officeId)}
                                                                                >
                                                                                    {VIEW}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mt-3">Total {employeeList == null ? "0" : rowCountOfficeEmp} Office Employee</div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={pageCountOfficeEmp}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleOfficeEmpPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel="Previous"
                                                            nextLabel="Next"
                                                            disableInitialCallback={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
